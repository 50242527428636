import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useImmer } from "use-immer";
import DialogWrapper from "../../../../../components/DialogWrapper/DialogWrapper";
import { Loader } from "../../../../../components/loader";
import QuestionPopup from "../../../../question-bank/QuestionView/QuestionPopup";
import { getAllSubjects, getExamById, getQuestionsById, getQuizById, updateQuestionsInExam, updateQuestionsInQuiz } from "../../../../../api/services";
import { toast } from "react-toastify";
import EmptyPage from "../../../../../components/EmptyPage";
import { emptyDashboard } from "../../../../../assets";
import { Controller, useForm } from "react-hook-form";
import DifficultyLevelChip from "../../../../../components/DifficultyLevelChip";
import moment from "moment";
import QuestionForm from "../../../../question-bank/QuestionForm/QuestionForm";
import SingleSelect from "../../../../../components/forms/SingleSelect";

const ManageQuizQuestions = ({questionGroup,  close }: any) => {
  const queryClient = useQueryClient();
  const params = useParams();
  const navigate=useNavigate();
  const [search] = useSearchParams();
  const {id}=useParams()
  const partId = search.get("partId");
  const [select, setSelect] = useState<any>({});
  const location=useLocation()

  const [state, setState] = useImmer<any>({});

  const { data: questionsData, isLoading } = useQuery(
    ["questions", location?.state?.data?.questionBankId],
    getQuestionsById
  );

  
  const [isManual,setIsManual]=useState(true);

  const [difficultyLevel, setDifficultyLevel] = useState('');
  const [filteredQuestions, setFilteredQuestions] = useState<any[]>([]);


  const { data:selectedQuestions, isLoading:isSelectedLoading } = useQuery(["Selected-quiz-id-data", id], getQuizById);
  console.log(selectedQuestions);

  useEffect(() => {
    if (questionsData && questionsData?.data && Array.isArray(questionsData?.data?.questions)) {
      setFilteredQuestions(questionsData?.data?.questions);
    } else {
      console.log("Questions data is undefined or not an array.");
    }
  }, [questionsData]);

  const [questionIds, setQuestionIds] = useState<any[]>([]);

useEffect(() => {
    if (!isSelectedLoading && selectedQuestions) {
      const questionBankIdToFind = location?.state?.data?.questionBankId;
      
      const foundQuestionBank = selectedQuestions?.data?.questionBanks.find(
        (bank:any) => bank?.questionBankId === questionBankIdToFind
      );
  
      if (foundQuestionBank) {
        setQuestionIds(foundQuestionBank?.questionIds || []);
      } else {
        console.log("Question bank not found with ID:", questionBankIdToFind);
      }
    }
  }, [isSelectedLoading, selectedQuestions, location?.state?.data?.questionBankId]);
  


  

  useEffect(() => {
    setState((s: any) => {
      s.questionIds = questionGroup?.questionIds;
    });
    setSelect({
      label: questionGroup?.isManual ? "Manual" : "Random",
      value: questionGroup?.isManual,
    });
  }, [questionGroup]);


  const handleClearFilter=()=>{
    setDifficultyLevel('');
    setFilteredQuestions(questionsData?.data?.questions)

  }



  const { mutate: updateQuestionsMutate } = useMutation(updateQuestionsInQuiz, {
    onSuccess: () => {
      queryClient.invalidateQueries("quiz-id");
      toast.success("Question Group Updated");
      navigate(-1)
    },
    onError: (res: any) => {
      toast.error(res?.message);
    },
  });
  const [difficultyValues, setDifficultyValues] = useState({
    "easy": "0",
    "medium": "0",
    "hard": "0"
  });
  

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if(difficultyValues.easy === null && difficultyValues.medium === null && difficultyValues.hard === null ){
      toast.error("please Enter Values in the Fields");

    }
    

      const res = {
        isManual: isManual,
        noOfQuestions: state?.questionIds?.length,
        questionIds: state?.questionIds,
      };
      const res1={
        isManual:isManual,
        difficultyLevel:{
          easy:parseInt(difficultyValues.easy),
          medium:parseInt(difficultyValues.medium),
          hard:parseInt(difficultyValues.hard)
        }

      }
      updateQuestionsMutate({
        id: params?.id,
        questionBankId: questionsData?.data?._id,
        body: isManual? res : res1,
      });
  };



  const handleDifficultyChange = (event: any) => {
    const difficult=event.target.value;
    setDifficultyLevel(difficult);

    if (questionsData && Array.isArray(questionsData?.data?.questions)) {
      const filteredData = questionsData?.data?.questions?.filter(
        (eachData: any) => eachData?.difficultyLevel === difficult
      );
      setFilteredQuestions(filteredData)
    } else {
      console.log("Questions data is undefined or not an array.");
    }
  };


  const selection = [
    {
      label: "Manual",
      value: true,
    },
    {
      label: "Random",
      value: false,
    },
  ];

  if (isLoading) return <Loader />;


  return (
    <Stack gap={1} minHeight={"100vh"}>
      <form onSubmit={onSubmit}>
        <Stack>
          <Paper
            variant="outlined"
            sx={{
              boxShadow: "none",
              p: 2,
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 300px",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Stack gap={0.5}>
              <Typography variant="subtitle2">Question Bank Name</Typography>
              <Typography variant="h4">
                {questionsData?.data?.title}
              </Typography>
            </Stack>
            <Stack gap={0.5}>
              <Typography variant="subtitle2">Selected Questions</Typography>
              <Typography variant="h4">
                {state?.questionIds?.length > 0
                  ? state?.questionIds?.length
                  : questionGroup?.noOfQuestions || 0}              
              </Typography>
            </Stack>
            {/* <FormControl component="fieldset">
              <Typography variant="h5">
                <Autocomplete
                  defaultValue={
                    selection?.find(
                      (item) => item?.value === questionGroup?.isManual
                    )?.label
                  }
                  options={selection?.map((item) => ({
                    label: item?.label,
                    value: item?.value,
                  }))}
                  value={select?.label}
                  onChange={(e, value,label) => {
                    console.log(value,"Value:")
                    setSelect(value);
                    setIsManual(value?.value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Selection" />
                  )}
                />
              </Typography>
            </FormControl> */}
            <Box>
              <Button type="submit" variant="contained">
                Update Question Group
              </Button>
            </Box>
          </Paper>
          {
            isManual?
            <Stack paddingTop={2}  direction={'row'}>
              <FormControl >
                <InputLabel id="demo-simple-select-label">Select Difficulty Level</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  sx={{width:"300px"}}
                  value={difficultyLevel}
                  label="Select Difficulty Level"
                  onChange={(e:any)=>handleDifficultyChange(e)}
                >
                  <MenuItem value={"EASY"}>Easy</MenuItem>
                  <MenuItem value={"MEDIUM"}>Medium</MenuItem>
                  <MenuItem value={"HARD"}>Hard</MenuItem>
                </Select>
              </FormControl>
              <Button
                onClick={handleClearFilter}
                sx={{
                  whiteSpace: "nowrap",
                  fontWeight: "bold",
                  mt: 1,
                  "&:hover": { backgroundColor: "transparent" },
                }}
                disableRipple
                disableElevation
                disableFocusRipple
                disableTouchRipple
              >
                Clear Filter
              </Button>
              

            </Stack>:""
            // :<Stack direction={'row'} paddingTop={2} gap={1} >
            //   <TextField id="outlined-basic" label="Number of Easy Questions" variant="outlined" value={difficultyValues.easy} onChange={(event:any)=>setDifficultyValues({...difficultyValues,easy:event?.target.value})} />
            //   <TextField id="outlined-basic" label="Number of Medium Questions" variant="outlined" value={difficultyValues.medium} onChange={(event:any)=>setDifficultyValues({...difficultyValues,medium:event?.target.value})} />
            //   <TextField id="outlined-basic" label="Number of Hard Questions" variant="outlined" value={difficultyValues.hard} onChange={(event:any)=>setDifficultyValues({...difficultyValues,hard:event?.target.value})} />
            // </Stack>
          }

        </Stack>
      </form>
      {isManual && filteredQuestions.length > 0 ? (
        <Questions
        questionGroup={filteredQuestions}
          state={state}
          setState={setState}
          questionData={questionGroup}
          checkedQuestion={questionIds}
        />
      ) : (
        <EmptyPage image={emptyDashboard} title={"No Questions"} />
      )}

    </Stack>
  );
};

export default ManageQuizQuestions;

const CardsContainer = styled(Box)(() => ({
  marginTop: "1rem",
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: "1rem",
}));

const Questions = ({ questionGroup, state, setState, questionData,checkedQuestion }: any) => {
  
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedId, setSelectedId] = useState<any[]>( []);

  useEffect(()=>{
    setSelectedId(checkedQuestion)
  },[checkedQuestion])
  
  

  const handleChange = (id: any) => {
    console.log('Previous IDs:', selectedId);
    setSelectedId((prevIds) => {
      if (prevIds.includes(id)) {
        const newIds = prevIds.filter((item) => item !== id);
        console.log('New IDs after uncheck:', newIds);
        return newIds;
      } else {
        const newIds = [...prevIds, id];
        console.log('New IDs after check:', newIds);
        return newIds;
      }
    });
  };
  

  useEffect(() => {
    setState((s: any) => {
      s.questionIds = selectedId;
    });
  }, [selectedId]);

  useEffect(() => {
    setSelectedId(questionData?.questionIds || []);
  }, [questionData]);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
  });

  return (
    <Box>
      <Box display="flex" gap={1} alignItems="center">
        <Typography variant="h4">Questions</Typography>
        <Typography variant="subtitle1">
          ( {questionGroup?.length} Questions )
        </Typography>
      </Box>
      <Grid mt={1} container gap={2} >
        {questionGroup?.map((question: any, index: number) => (
          <Grid key={index} md={5.8} xs={12}  >
            <Card variant="outlined">
              <CardHeader
                sx={{ pb: 0,}}
                subheader={
                  <Stack direction="row"  alignItems={"center"} justifyContent={"space-between"} >
                    <DifficultyLevelChip level={question?.difficultyLevel} />
                    <Checkbox
                      onChange={() => handleChange(question?._id)}
                      checked={ 
                        selectedId.includes(question?._id)
                      }
                      value={question?._id}
                    />
                  </Stack>
                }
              />
              <CardContent sx={{ pt: 0 }}>
                <Divider sx={{ my: 1 }}/>
                <Box
                  sx={{
                    
                    fontFamily: "figtree_medium",
                    fontSize: "16px",
                  }}
                  dangerouslySetInnerHTML={{ __html: question?.question }}
                />
              </CardContent>
              <CardActions
                disableSpacing
                sx={{ display: "flex", justifyContent:"space-between" }}
              >
                <Typography variant="subtitle1">
                  {question?.createdAt &&
                    moment(question?.createdAt).format("DD MMM yyyy")}
                </Typography>
                <Button 
                  variant="outlined"
                  onClick={() => {
                    setOpen(true);
                    setSelectedData(question);
                  }}
                >
                    View Details
                </Button>
              </CardActions>
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  p: 1,
                }}
              >
                
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
      <DialogWrapper
        width="md"
        title="Question Details"
        open={open}
        setOpen={setOpen}
      >
        <QuestionPopup
          data={selectedData}
          otherData={questionGroup?.data}
          close={() => setOpen(false)}
          // menuActions={
          //   <>
          //     <IconButton
          //       size="small"
          //       color="primary"
          //       onClick={() => {
          //         setOpenEdit(true);
          //       }}
          //     >
          //       <EditTwoTone />
          //     </IconButton>
          //   </>
          // }
        />
      </DialogWrapper>
      
      
      
    </Box>
  );
};

// const Passages = ({ questionGroup, state, setState }: any) => {
//   const isManual = state.isManual.toString() === 'true';
//   const [open, setOpen] = useState(false);
//   const [selectedData, setSelectedData] = useState(null);

//   const [filters, setFilters] = useState({
//     page: 1,
//     limit: 10,
//   });

//   const { isLoading: loadingAddedPassages } = useQuery(
//     ['added-questions', questionGroup?.questionBank?.id],
//     // () =>
//     //   http
//     //     .get(`/exams/part/question-bank/passages`, {
//     //       params: {
//     //         partQuestionBankId: questionGroup?.id,
//     //       },
//     //     })
//     //     .then((res) => res.data),
//     // {
//     //   enabled: !!questionGroup?.isManual,
//     //   onSuccess: (data) => {
//     //     setState((s) => {
//     //       s.selectedPassages = data?.passages?.map((i) => i.passageId);
//     //     });
//     //   },
//     // },
//   );

//   const { data: questions, isLoading: loadingQuestions } = useQuery(
//     ['passage-questions', questionGroup?.questionBank?.id],
//     // () =>
//     //   fetchPassages({
//     //     questionBankId: questionGroup?.questionBank?.id,
//     //     offset: (filters.page - 1) * filters.limit,
//     //     limit: filters.limit,
//     //   }),
//     // {
//     //   enabled: !!questionGroup,
//     // },
//   );

//   if (loadingQuestions || loadingAddedPassages) return <CircularProgress />;

//   return (
//     <Box>
//       <Box display="flex" gap={1} alignItems="center">
//         <Typography variant="h4">Passages</Typography>
//         <Typography variant="subtitle1">
//           ( {questions?.count} Passages)
//         </Typography>
//       </Box>
//       {loadingQuestions ? (
//         <Loader />
//       ) : (
//         <CardsContainer>
//           {questions?.count ? (
//             <>
//               {questions?.passages?.map((question, index) => (
//                 <Box display="flex" key={question.id}>
//                   {isManual && (
//                     <Box>
//                       <Checkbox
//                         checked={state?.selectedPassages?.includes(
//                           question?.id,
//                         )}
//                         onChange={(e) =>
//                           setState((s) => {
//                             if (e.target.checked) {
//                               s.selectedPassages.push(question?.id);
//                             } else {
//                               s.selectedPassages = s.selectedPassages.filter(
//                                 (id) => id != question?.id,
//                               );
//                             }
//                           })
//                         }
//                       />
//                     </Box>
//                   )}
//                   <Box sx={{ flexGrow: 1 }}>
//                     <PassageCard
//                       key={index}
//                       data={question}
//                       menuActions={<></>}
//                       cardActions={
//                         <Button
//                           size="small"
//                           variant="outlined"
//                           onClick={() => {
//                             setOpen(true);
//                             setSelectedData(question);
//                           }}
//                         >
//                           View Details
//                         </Button>
//                       }
//                     />
//                   </Box>
//                 </Box>
//               ))}
//             </>
//           ) : (
//             <NoDataComponent label="No Passages Found" />
//           )}
//         </CardsContainer>
//       )}
//       <DialogWrapper
//         width="md"
//         title="Passage Details"
//         open={open}
//         setOpen={setOpen}
//       >
//         <QuestionPopup
//           data={selectedData}
//           close={() => setOpen(false)}
//           menuActions={<></>}
//         />
//       </DialogWrapper>
//     </Box>
//   );
// };

const RegularNumberOfQuestions = ({ questionGroup, state, setState }: any) => {
  const isManual = state.isManual.toString() === "true";
  return (
    <Box display="flex" alignItems="center" gap={1}>
      {!isManual ? (
        <TextField
          value={state.numberOfQuestions}
          onChange={(e) =>
            setState((s: any) => {
              s.numberOfQuestions = parseInt(e.target.value);
            })
          }
          name="numberOfQuestions"
          label="Number of Questions"
          type="number"
        />
      ) : (
        <Box>
          <TextField
            size="small"
            value={state.numberOfQuestions}
            onChange={(e) =>
              setState((s: any) => {
                s.numberOfQuestions = parseInt(e.target.value);
              })
            }
            name="numberOfQuestions"
            label="Number of Questions"
            type="number"
          />
          <Typography fontSize={14} textAlign="center" mt={1}>
            <strong>{state.selectedQuestions.length} </strong>
            Questions Selected
          </Typography>
        </Box>
      )}
    </Box>
  );
};

// export const PassageNumberOfQuestions = ({ state, setState }) => {
//   const theme = useTheme();
//   const isManual = state.isManual.toString() === 'true';
//   return (
//     <Box>
//       {isManual ? (
//         <Box>
//           <TextField
//             value={state.numberOfPassages}
//             onChange={(e) =>
//               setState((s) => {
//                 s.numberOfPassages = e.target.value;
//               })
//             }
//             name="numberOfPassages"
//             label="Number of Passages"
//             type="number"
//           />
//           <Typography fontSize={14} mt={1}>
//             <strong>{state.selectedPassages.length} </strong>
//             Passages Selected
//           </Typography>
//         </Box>
//       ) : (
//         <Box>
//           <TextField
//             fullWidth
//             value={state.numberOfPassages}
//             onChange={(e) =>
//               setState((s) => {
//                 s.numberOfPassages = e.target.value;
//                 s.passageSettings = {};
//               })
//             }
//             name="numberOfPassages"
//             label="Number of Passages"
//             type="number"
//           />
//           <Box>
//             {state?.numberOfPassages > 0 ? (
//               <Stack
//                 sx={{ background: theme.colors.primary.lighter, p: 2 }}
//                 gap={1}
//               >
//                 {new Array(+state?.numberOfPassages)
//                   .fill(0)
//                   .map((item, index) => (
//                     <TextField
//                       sx={{ background: 'white', borderRadius: '0.5rem' }}
//                       label={`Number of Questions for Passage #${index + 1}`}
//                       fullWidth
//                       size="small"
//                       value={state.passageSettings[index + 1]}
//                       onChange={(e) =>
//                         setState((s) => {
//                           s.passageSettings = {
//                             ...s.passageSettings,
//                             [index + 1]: +e.target.value,
//                           };
//                         })
//                       }
//                     />
//                   ))}
//               </Stack>
//             ) : null}
//           </Box>
//         </Box>
//       )}
//     </Box>
//   );
// };
