import { Button, Grid, TextField } from "@mui/material";
import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";

import { toast } from "react-toastify";
import { useImmer } from "use-immer";
import { postComponents, updateComponents } from "../../../../../api/services";
import {
  EditFileUpload,
  FileUpload,
} from "../../../../../components/FileUpload";

const FileContentForm = ({ data, close }: any) => {
  const queryClient = useQueryClient();
  const { id: subjectId } = useParams();
  const [search] = useSearchParams();
  const topicId = search.get("topicId");
  const chapterId = search.get("chapterId");
  const sectionId = search.get("sectionId");

  const [state, setState] = useImmer({
    fileKey: data?.fileKey ?? null,
    title: data?.title ?? "",
  });

  useEffect(() => {
    if (!data)
      setState((s) => {
        s.fileKey = null;
        s.title = "";
      });

    setState((s) => {
      s.fileKey = data?.fileKey;
      s.title = data?.title;
    });
  }, [data]);

  const { mutate } = useMutation(postComponents, {
    onSuccess: (res: any) => {
      toast.success(res?.data?.message);
      queryClient.invalidateQueries("topic-id");
      close();
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { mutate: updateMutate } = useMutation(updateComponents, {
    onSuccess: (res: any) => {
      toast.success("Test Updated Successfully");
      queryClient.invalidateQueries("topic-id");
      close();
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const res = {
      ...state,
      subjectId: subjectId,
      chapterId: chapterId,
      topicId: topicId,
      sectionId: sectionId,
      type: "RESOURCE",
      fileKey: state?.fileKey?.key,
    };
    if (data?._id) {
      updateMutate({ id: data?._id, body: res });
    } else {
      mutate(res);
    }
  };

  const handleUpload = (value: any) => {
    if (!value) return;

    setState((s) => {
      s.fileKey = value;
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            label="File Caption/Label"
            value={state?.title}
            onChange={(e) =>
              setState((s) => {
                s.title = e.target.value;
              })
            }
          />
        </Grid>

        <Grid item xs={12}>
          {/* {state?.fileKey ? (
            <EditImage
              imageUrl={state.fileKey?.s3Response?.url}
              onChange={handleUpload}
              uploadUrl={`${process.env.REACT_APP_API_URL}/s3-resources/file-upload`}
              type="COMPONENT"
              subtype="IMAGE"
            />
          ) : (
            <ImageUpload
              url={`${process.env.REACT_APP_API_URL}/s3-resources/file-upload`}
              onChange={handleUpload}
              type="COMPONENT"
              subtype="IMAGE"
            />
          )} */}
          {state?.fileKey ? (
            <EditFileUpload
              mediaObject={state?.fileKey}
              onChange={handleUpload}
              uploadUrl={`${process.env.REACT_APP_API_URL}/s3-resources/file-upload`}
              type="COMPONENT"
              subtype="RESOURCE"
            />
          ) : (
            <FileUpload
              url={`${process.env.REACT_APP_API_URL}/s3-resources/file-upload`}
              onChange={handleUpload}
              type="COMPONENT"
              subtype="RESOURCE"
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default FileContentForm;
