import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

export interface MyTextFieldProps {
  control?: any;
  label?: string;
  name?: any;
  muiProps?: MuiTextFieldProps;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  defaultValue?: any; // Include defaultValue in the interface
}

export type TextFieldProps = MyTextFieldProps;

export function TextField(props: TextFieldProps) {
  const { name, control, label, defaultValue, ...rest } = props;

  return (
    <Controller
      control={props.control}
      name={props.name}
      defaultValue={defaultValue || ""}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <MuiTextField
          variant="outlined"
          fullWidth
          name={props.name}
          label={props.label}
          onChange={(e) => {
            onChange(e);
            if (props.onChange) {
              props.onChange(e);
            }
          }}
          value={value}
          error={error ? true : false}
          helperText={error ? error.message : null}
          {...rest.muiProps}
        />
      )}
    />
  );
}

// export function TextField(props: TextFieldProps) {
//   const { name, control, label, ...rest } = props;

//   return (
//     <Controller
//       control={props.control}
//       name={props.name}
//       defaultValue=""
//       render={({ field: { onChange, value }, fieldState: { error } }) => (
//         <MuiTextField
//           variant="outlined"
//           fullWidth
//           name={props.name}
//           label={props.label}
//           onChange={(e) => {
//             onChange(e);
//             if (props.onChange) {
//               props.onChange(e);
//             }
//           }}
//           value={value}
//           error={error ? true : false}
//           helperText={error ? error.message : null}
//           {...rest.muiProps}
//         />
//       )}
//     />
//   );
// }
