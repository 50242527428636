import { Box } from "@mui/material";
// import { QuestionTypeKey } from "src/types";
import BlanksQuestionForm from "./QuestionFormTypes/BlanksQuestionForm";
import MCQQuestionForm from "./QuestionFormTypes/MCQQuestionForm";
import PassageQuestionForm from "./QuestionFormTypes/PassageQuestionForm";
import SubjectiveQuestionForm from "./QuestionFormTypes/SubjectiveQuestionForm";
import TrueFalseQuestionForm from "./QuestionFormTypes/TrueFalseQuestionForm";
import { QuestionTypeKey } from "./service";

export default function QuestionForm({
  questionType,
  closeModal,
  data,
  passage,
}: any) {
  const renderContentForm = () => {
    switch (questionType) {
      case QuestionTypeKey.MCQ_SINGLE:
      case QuestionTypeKey.MCQ_MULTIPLE:
        return (
          <MCQQuestionForm data={data} close={closeModal} passage={passage} />
        );
      case QuestionTypeKey.BLANKS_SINGLE:
      case QuestionTypeKey.BLANKS_MULTIPLE:
      case "EXAM":
        return (
          <BlanksQuestionForm
            data={data}
            close={closeModal}
            passage={passage}
          />
        );
      case QuestionTypeKey.PASSAGE:
        return <PassageQuestionForm data={data} close={closeModal} />;
      case QuestionTypeKey.SUBJECTIVE:
        return (
          <SubjectiveQuestionForm
            data={data}
            close={closeModal}
            passage={passage}
          />
        );
      case QuestionTypeKey.TRUE_FALSE:
        return (
          <TrueFalseQuestionForm
            data={data}
            close={closeModal}
            passage={passage}
          />
        );
      default:
        return <MCQQuestionForm data={data} close={closeModal} />;
    }
  };
  return <Box>{renderContentForm()}</Box>;
}
