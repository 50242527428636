import React from "react";
import { FormControl, FormLabel, MenuItem, Select, Typography } from "@mui/material";
import { Controller, Control } from "react-hook-form";

interface SingleSelectProps {
  control: Control<any>;
  label: string;
  name: string;
  options: { label: string; value: string }[];
  onChange?: (value: string) => void;
}

const SingleSelect: React.FC<SingleSelectProps> = ({
  control,
  label,
  name,
  options,
  onChange,
}) => {
  return (
    <FormControl fullWidth>
      <FormLabel>{label}</FormLabel>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Select
            {...field}
            onChange={(e) => {
              field.onChange(e);
              onChange && onChange(e.target.value as string);
            }}
            
            fullWidth
            displayEmpty 
            renderValue={(selected) =>
              selected ? (
                (options?.find((option) => option?.value === selected)||{})?.label || ""
              ) : (<Typography sx={{fontSize:"14px",color:"#6a6c64"}}>Select {label}</Typography>)
              
            }
       
          >
           
            {options?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
};

export default SingleSelect;
