import { Add } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  IconButton,
  Pagination,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useImmer } from "use-immer";
import {
  getQuiz,
  postComponents,
  updateComponents,
} from "../../../../../api/services";
import { emptyDashboard } from "../../../../../assets";
import EmptyPage from "../../../../../components/EmptyPage";
import DifficultyLevelFilters from "../../../../../components/Filters/DifficultyLevelFilers";
import LanguagesFilters from "../../../../../components/Filters/LanguagesFilters";
import { Loader } from "../../../../../components/loader";
import ExamCard from "../../../../quiz/components/ExamCard";

const QuizComponent = ({ data: addedQuizzes, close }: any) => {
  const queryClient = useQueryClient();
  const { id: subjectId } = useParams();
  const [search] = useSearchParams();
  const topicId = search.get("topicId");
  const chapterId = search.get("chapterId");
  const sectionId = search.get("sectionId");

  const [state, setState] = useImmer({
    page: 1,
    search: "",
    difficultyLevel: "",
    languageId: "",
  });

  const { data: quizData, isLoading } = useQuery(
    [
      "quiz",
      {
        offset: (state.page - 1) * 1,
        limit: 10,
        difficultyLevel: state?.difficultyLevel,
        languageId: state?.languageId,
        search: state?.search,
      },
    ],
    getQuiz
  );

  const handlePagination = (page: any) => {
    setState((s) => {
      s.page = page;
    });
  };

  const { mutate } = useMutation(postComponents, {
    onSuccess: (res: any) => {
      toast.success(res?.data?.message);
      queryClient.invalidateQueries("topic-id");
      close();
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { mutate: updateMutate } = useMutation(updateComponents, {
    onSuccess: (res: any) => {
      toast.success("Test Updated Successfully");
      queryClient.invalidateQueries("topic-id");
      close();
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const handleAdd = (id: any) => {
    const res = {
      subjectId,
      chapterId,
      topicId,
      sectionId,
      type: "QUIZ",
      quizId: id,
    };
    mutate(res);
  };

  return (
    <>
      <Paper sx={{ p: 1, mt: 2 }}>
        <Stack direction="row" gap={1} alignItems="center">
          <TextField
            label="Search"
            fullWidth
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
            value={state?.search}
            onChange={(e) => setState({ ...state, search: e.target.value })}
          />
        </Stack>

        <DifficultyLevelFilters state={state} setState={setState} />
        <LanguagesFilters state={state} setState={setState} />
      </Paper>
      {!isLoading ? (
        <Box>
          {quizData?.data?.count > 0 ? (
            <Box
              sx={{
                marginTop: "20px",
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "20px",
              }}>
              {quizData?.data?.data?.map((item: any, index: number) => (
                <ExamCard
                  cardAction={
                    <Box>
                      {!addedQuizzes
                        ?.map((item: any) => item?.quizId)
                        ?.includes(item?._id) ? (
                        <Button
                          onClick={() => handleAdd(item?._id)}
                          size="small"
                          endIcon={<Add />}>
                          {"Add"}
                        </Button>
                      ) : (
                        <Typography color="green">Added</Typography>
                      )}
                    </Box>
                  }
                  key={index}
                  data={item}
                />
              ))}
            </Box>
          ) : (
            <EmptyPage image={emptyDashboard} title="No Quiz found" />
          )}
        </Box>
      ) : (
        <Box mt={5}>
          <Loader />
        </Box>
      )}
      <Box display="flex" justifyContent="center" my={2}>
        <Pagination
          count={Math.ceil(quizData?.data?.count / 10)}
          page={state.page}
          onChange={handlePagination}
          color="primary"
        />
      </Box>
    </>
  );
};

export default QuizComponent;
