"use client";
import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { icons, logo } from "../../../assets";
import { StyledDfAcJsb, StyledHeader1 } from "../../../styles";

const Header = () => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickLogout = () => {
    setAnchorEl(null);
    localStorage.removeItem("access_token");
    window.location.href = "/login";
  };

  const handleClickLogo = () => {
    navigate("/curriculums");
  };
  const handleSettingsClick=()=>{
    navigate("/master-data")

  }

  return (
    <AppBar
      sx={{ zIndex: 1100, background: "#FFFFFF", py: 1 }}
      position="fixed"
    >
      <StyledDfAcJsb>
        <Box sx={{ cursor: "pointer" }} onClick={handleClickLogo}>
          <img src={logo} alt="logo" width={150} height={52} />
        </Box>
        <StyledHeader1>
          <IconButton onClick={handleSettingsClick}>
            <img
              src={icons.appBarSettings}
              alt="settings"
              width={20}
              height={20}
            />
          </IconButton>
          <IconButton>
            <img
              src={icons.appBarNotification}
              alt="notification"
              width={20}
              height={20}
            />
          </IconButton>
          <Box>
            <Avatar onClick={handleMenu} alt={"shahid"} src={" "} />
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem onClick={handleClickLogout}>
                <Box padding="5px" display="flex" alignItems="center">
                  <img src={""} alt="" />
                  <Typography ml={1} variant="body1">
                    Logout
                  </Typography>
                </Box>
              </MenuItem>
            </Menu>
          </Box>
        </StyledHeader1>
      </StyledDfAcJsb>
    </AppBar>
  );
};

export default Header;
