import { Box, Button, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";

import { useEffect } from "react";
import { toast } from "react-toastify";
import { useImmer } from "use-immer";
import * as Yup from "yup";
import { postComponents, updateComponents } from "../../../../../api/services";
import Quill from "../../../../../components/Quill";
import { TextField } from "../../../../../components/forms/TextField";

const videoContentSchema = Yup.object().shape({
  component: Yup.object({
    link: Yup.string().max(255).required("Video Link is required"),
  }),
});

const LinkContentForm = ({ data, close }: any) => {
  const queryClient = useQueryClient();
  const { id: subjectId } = useParams();
  const [search] = useSearchParams();
  const topicId = search.get("topicId");
  const chapterId = search.get("chapterId");
  const sectionId = search.get("sectionId");

  const [state, setState] = useImmer<{
    description: string;
  }>({
    description: "",
  });

  useEffect(() => {
    setState({
      description: data?.description || "",
    });
  }, [data]);

  const { mutate } = useMutation(postComponents, {
    onSuccess: (res: any) => {
      toast.success(res?.data?.message);
      queryClient.invalidateQueries("topic-id");
      close();
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { mutate: updateMutate } = useMutation(updateComponents, {
    onSuccess: (res: any) => {
      toast.success("Test Updated Successfully");
      queryClient.invalidateQueries("topic-id");
      close();
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { handleSubmit, control } = useForm({
    defaultValues: {
      link: data?.link ?? "",
      title: data?.title ?? "",
    },
  });

  const onSubmit = (formData: any) => {
    const res = {
      subjectId,
      chapterId,
      topicId,
      sectionId,
      type: "LINK",
      description: state?.description,
      ...formData,
    };
    if (data?._id) {
      updateMutate({ id: data?._id, body: res });
    } else {
      mutate(res);
    }
  };

  const handleChange = (value: any) => {
    setState((s: any) => {
      s.description = value;
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2}>
        <TextField name="title" label="Title/Label" control={control} />
        <Quill
          data={state?.description}
          handleChange={(value) => handleChange(value)}
        />
        <TextField
          name="link"
          label="Reference Link"
          control={control}
          muiProps={{
            multiline: true,
            minRows: 5,
          }}
        />
        <Box>
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </Box>
      </Stack>
    </form>
  );
};

export default LinkContentForm;
