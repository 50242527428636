import CloudUploadTwoTone from "@mui/icons-material/CloudUploadTwoTone";
import { Box, CircularProgress, IconButton, styled } from "@mui/material";
import axios from "axios";
import { useState } from "react";

const Input = styled("input")({
  display: "none",
});
const AvatarWrapper = styled(Box)(
  ({ theme }) => `

    position: relative;

    .MuiAvatar-root {
      width: ${theme.spacing(16)};
      height: ${theme.spacing(16)};
    }
`
);

const ImageWrapper = styled(Box)<{
  height?: number | string;
  width?: number | string;
}>(({ theme, height, width }) => ({
  height,
  width,
}));

const Image = styled("img")(({ theme }) => ({
  height: "100%",
  width: "100%",
  objectFit: "contain",
}));

const ButtonUploadWrapper = styled(Box)(
  ({ theme }) => `
    position: absolute;
    width: ${theme.spacing(6)};
    height: ${theme.spacing(6)};
    bottom: -${theme.spacing(2)};
    right: -${theme.spacing(2)};

    .MuiIconButton-root {
      border-radius: 100%;
      background: ${theme.palette.primary.main};
      color: ${theme.palette.primary.contrastText};
      width: ${theme.spacing(6)};
      height: ${theme.spacing(6)};
      padding: 0;
  
      &:hover {
        background: ${theme.palette.primary.dark};
      }
    }
`
);

const EditImage = ({
  imageUrl,
  uploadUrl,
  type,
  subtype,
  onChange,
  height = 280,
  width = "100%",
}: any) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleUpload = (e: any) => {
    if (e.target.files[0]) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("type", type);
      formData.append("subType", subtype);
      setLoading(true);
      axios
        .post(uploadUrl, formData)
        .then((res) => {
          onChange(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <Box display="inline-flex" flexDirection="column" pb={2}>
      <AvatarWrapper>
        <ImageWrapper height={height} width={width}>
          <Image alt="Name" src={imageUrl} />
        </ImageWrapper>
        <ButtonUploadWrapper>
          <Input
            onChange={handleUpload}
            accept="image/*"
            id="icon-button-file"
            name="icon-button-file"
            type="file"
          />
          {loading ? (
            <CircularProgress />
          ) : (
            <label htmlFor="icon-button-file">
              <IconButton component="span" color="primary">
                <CloudUploadTwoTone />
              </IconButton>
            </label>
          )}
        </ButtonUploadWrapper>
      </AvatarWrapper>
    </Box>
  );
};

export default EditImage;
