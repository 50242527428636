import {
  Box,
  Button,
  FormControl,
  FormLabel,
  TextField as MuiTextField,
  Stack,
} from "@mui/material";
import Player from "@vimeo/player";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useImmer } from "use-immer";
import * as Yup from "yup";
import { postComponents, updateComponents } from "../../../../../api/services";
import Quill from "../../../../../components/Quill";
import { TextField } from "../../../../../components/forms/TextField";

const videoContentSchema = Yup.object().shape({
  component: Yup.object({
    link: Yup.string().max(255).required("Video Link is required"),
  }),
});

const VideoContentForm = ({ data, close }: any) => {
  const queryClient = useQueryClient();
  const [videoDuration, setVideoDuration] = useImmer<number>(0);
  const { id: subjectId } = useParams();
  const [search] = useSearchParams();
  const topicId = search.get("topicId");
  const chapterId = search.get("chapterId");
  const sectionId = search.get("sectionId");

  const [state, setState] = useImmer<{
    description: string;
  }>({
    description: "",
  });

  const { mutate } = useMutation(postComponents, {
    onSuccess: (res: any) => {
      toast.success("Video Added Successfully");
      queryClient.invalidateQueries("topic-id");
      close();
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { mutate: updateMutate } = useMutation(updateComponents, {
    onSuccess: (res: any) => {
      toast.success("Video Updated Successfully");
      queryClient.invalidateQueries("topic-id");
      close();
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { handleSubmit, control, watch } = useForm({
    defaultValues: {
      link: data?.link ?? "",
      title: data?.title ?? "",
      videoDuration: data?.videoDuration ?? "",
    },
  });

  useEffect(() => {
    if (!watch("link")) return;
    try {
      var match = watch("link").match(/player\.vimeo\.com\/video\/([0-9]*)/);
      const handstickPlayer = new Player("player", {
        id: +match[1],
        responsive: true,
      });

      handstickPlayer.getDuration().then((duration: any) => {
        setVideoDuration(duration);
      });
    } catch (error) {
      console.log(error);
    }
  }, [watch("link")]);

  useEffect(() => {
    setState({
      description: data?.description || "",
    });
  }, [data]);

  const onSubmit = (formData: any) => {
    const res = {
      ...formData,
      type: "VIDEO",
      description: state?.description,
      subjectId: subjectId,
      chapterId: chapterId,
      topicId: topicId,
      sectionId: sectionId,
    };
    console.log(res);
    if (data?._id) {
      updateMutate({ id: data?._id, body: res });
    } else {
      mutate(res);
    }
  };

  const handleChange = (value: any) => {
    setState((s: any) => {
      s.description = value;
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2}>
        <TextField name="title" label="Video Title" control={control} />
        <FormControl>
          <FormLabel sx={{ mb: 0.5 }}>Video Description</FormLabel>
          <Quill
            data={state?.description}
            handleChange={(value) => handleChange(value)}
          />
        </FormControl>
        <TextField
          name="link"
          label="Video link"
          control={control}
          muiProps={{
            multiline: true,
            minRows: 5,
            helperText: "Please add only Vimeo embed (HTML Tag)",
          }}
        />
        <TextField
          name="videoDuration"
          control={control}
          // value={videoDuration / 60}
          label="Duration of Video (in minutes)"
        />
        {/* <div id="player"></div> */}
        <Box>
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </Box>
      </Stack>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </form>
  );
};

export default VideoContentForm;
