import React from "react";
import { useQuery } from "react-query";
import { getEbooksById } from "../../../../../api/services";
import { Box, Typography } from "@mui/material";
import { flatten } from "lodash";

export default function EbookCardComponent(params: any) {
  const {
    data: EbooksData,
    isLoading,
    error,
  } = useQuery(["ebook-Id", params.data.ebookId], getEbooksById, {
    enabled: !!params.data.ebookId,
  });
  console.log(EbooksData?.data?.fileUrl);
  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}>
        <img width={"30%"} src={EbooksData?.data?.fileUrl} alt="" />
        <Typography>{EbooksData?.data?.title}</Typography>
      </Box>
    </>
  );
}
