import AddTwoTone from "@mui/icons-material/AddTwoTone";
import {
  alpha,
  Avatar,
  Card,
  CircularProgress,
  styled,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";

const CardAddAction = styled(Card)(
  ({ theme }) => `
        border: ${theme.palette.common.black} dashed 1px;
        height: 100%;
        color: ${theme.palette.primary.main};
        transition: ${theme.transitions.create(["all"])};
        box-shadow: none; 
        padding: ${theme.spacing(2)};
          height: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
          cursor: pointer;
          
          .MuiTouchRipple-root {
            opacity: .2;
          }
          
          &:hover {
            border-color: ${theme.palette.primary.main};
            background-opacity: .2;
            background: ${alpha(theme.palette.primary.main, 0.1)};
          }

          & > * {
          cursor: pointer;
          }
`
);

const AvatarAddWrapper = styled(Avatar)(
  ({ theme }) => `
        background: ${theme.palette.common.black};
        color: ${theme.palette.primary.main};
        width: ${theme.spacing(8)};
        height: ${theme.spacing(8)};

`
);

const ImageUpload = ({ url, onChange, type, subtype, multiple=false }: any) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleUpload = (e: any) => {
    if (e.target.files[0]) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("type", type);
      formData.append("subType", subtype);
      setLoading(true);
      axios
        .post(url, formData)
        .then((res) => {
          onChange(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <label htmlFor="file-upload">
        <CardAddAction>
          <input
            accept="image/*"
            onChange={handleUpload}
            type="file"
            name="file-upload"
            id="file-upload"
            multiple={multiple}
            style={{ display: "none" }}
          />
          <AvatarAddWrapper>
            {!loading ? (
              <Tooltip arrow title={"Click to add image"} placement="right">
                <AddTwoTone fontSize="large" />
              </Tooltip>
            ) : (
              <CircularProgress />
            )}
          </AvatarAddWrapper>
        </CardAddAction>
      </label>
    </>
  );
};

export default ImageUpload;
