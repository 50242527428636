import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Box,
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { icons } from "../../../assets";
import { StyledSideNav } from "../../../styles";

const NewLayout = () => {
  const theme = useTheme();
  const location = useLocation();
  const [open, setOpen] = useState<boolean>(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [openQuiz, setOpenQuiz] = useState(false);
  const [openconfig, setopenconfig] = useState(true);
  const [masterMenu, SetmasterMenu] = useState(false);
  const [Academics, SetAcademics] = useState(false);
  const [Userroles, setUserroles] = useState(false);
  const Configuration = [
    {
      title: "Districts",
      path: "/master-data/districts",
      icon: icons.District,
    },
    {
      title: "Years",
      path: "/master-data/year",
      icon: icons.masterdata,
    },
    {
      title: "ITI Centre Type",
      path: "/master-data/iti-centre-type",
      icon: icons.iticentertype,
    },
    {
      title: "ITI Centres",
      path: "/master-data/iti-centres",
      icon: icons.ItiCenter,
    },
    {
      title: "Trade",
      path: "/master-data/trade",
      icon: icons.Trade,
    },
    {
      title: "Bundle-Types",
      path: "/master-data/bundle-types",
      icon: icons.bundletypes,
    },
    {
      title: "Roles",
      path: "/master-data/roles",
      icon: icons.roles,
    },
    {
      title: "Users",
      path: "/master-data/users",
      icon: icons.user,
    },
    {
      title: "Exam Timetable",
      path: "/master-data/exam-timetable",
      icon: icons.user,
    },
    {
      title: "Notice Board",
      path: "/master-data/notice-board",
      icon: icons.user,
    },
  ];

  useEffect(() => {
    if (location.pathname.startsWith("/master-data")) {
      setOpen(true);
      setOpen2(false);
      setOpen3(false);
      setOpenQuiz(false);
    } else if (location.pathname.startsWith("/courses")) {
      setOpen2(true);
      setOpen(false);
      setOpen3(false);
      setOpenQuiz(false);
    } else if (location.pathname.startsWith("/app")) {
      setOpenQuiz(true);
      setOpen(false);
      setOpen2(false);
      setOpen3(false);
    } else if (location.pathname.startsWith("/students-corner")) {
      setOpen3(true);
      setOpen(false);
      setOpen2(false);
      setOpenQuiz(false);
    } else {
      setOpen(false);
      setOpen2(false);
      setOpenQuiz(false);
      setOpen3(false);
    }
  }, [location.pathname]);

  return (
    <StyledSideNav>
      <List sx={{ overflow: "auto", maxHeight: "90vh" }}>
        <Collapse in={openconfig} timeout="auto" unmountOnExit sx={{}}>
          {Configuration?.slice(3, 4).map((item: any, index: any) => (
            <Link key={index} to={item.path} style={{ textDecoration: "none" }}>
              <ListItem disablePadding>
                <ListItemButton
                  selected={location.pathname.startsWith(item.path)}
                  sx={{
                    "&.Mui-selected": {
                      opacity: "1",
                    },
                    "&.Mui-selected:hover": {},

                    paddingLeft: "15px",
                  }}
                >
                  <ListItemIcon>
                    <Box
                      sx={{
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      <img src={item.icon} alt="" width={20} height={20} />
                    </Box>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        color={
                          location.pathname.startsWith(item.path)
                            ? "#F4347F"
                            : "#000"
                        }
                        variant="h5"
                      >
                        {item?.title}
                      </Typography>
                    }
                  />
                </ListItemButton>
                <Box
                  sx={{
                    height: "45px",
                    width: "5px",
                    borderRadius: "10px 0 0 10px",
                    bgcolor: location.pathname.startsWith(item?.path)
                      ? theme.palette.primary.main
                      : "#fff",
                  }}
                />
              </ListItem>
            </Link>
          ))}

          <List component="div" disablePadding>
            <ListItemButton
              selected={
                location.pathname.startsWith("/master-data/districts") ||
                location.pathname.startsWith("/master-data/year") ||
                location.pathname.startsWith("/master-data/iti-centre-type")
              }
              onClick={() => {
                SetmasterMenu(!masterMenu);
              }}
            >
              <ListItemIcon>
                <Box
                  sx={{
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <img src={icons.masterdata} alt="" width={20} height={20} />
                </Box>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    color={
                      location.pathname.startsWith("/master-data/districts") ||
                      location.pathname.startsWith("/master-data/year") ||
                      location.pathname.startsWith(
                        "/master-data/iti-centre-type"
                      )
                        ? "#F4347F"
                        : "#000"
                    }
                    variant="h5"
                  >
                    Master - data
                  </Typography>
                }
              />
              {masterMenu ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={masterMenu} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {Configuration?.slice(0, 3).map((item: any, index: any) => (
                  <Link
                    key={index}
                    to={item.path}
                    style={{ textDecoration: "none" }}
                  >
                    <ListItem disablePadding>
                      <ListItemButton
                        selected={location.pathname.startsWith(item.path)}
                        sx={{
                          "&.Mui-selected": {
                            opacity: "1",
                          },
                          "&.Mui-selected:hover": {},

                          paddingLeft: "30px",
                        }}
                      >
                        <ListItemIcon>
                          <Box
                            sx={{
                              width: "100%",
                              textAlign: "center",
                            }}
                          >
                            <img
                              src={item.icon}
                              alt=""
                              width={20}
                              height={20}
                            />
                          </Box>
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              color={
                                location.pathname.startsWith(item.path)
                                  ? "#F4347F"
                                  : "#000"
                              }
                              variant="h5"
                            >
                              {item?.title}
                            </Typography>
                          }
                        />
                      </ListItemButton>
                      <Box
                        sx={{
                          height: "45px",
                          width: "5px",
                          borderRadius: "10px 0 0 10px",
                          bgcolor: location.pathname.startsWith(item?.path)
                            ? theme.palette.primary.main
                            : "#fff",
                        }}
                      />
                    </ListItem>
                  </Link>
                ))}
              </List>
            </Collapse>

            <ListItemButton
              selected={
                location.pathname.startsWith("/master-data/trade") ||
                location.pathname.startsWith("/master-data/bundle-types")
              }
              onClick={() => {
                SetAcademics(!Academics);
              }}
            >
              <ListItemIcon>
                <Box
                  sx={{
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <img src={icons.commonIcon} alt="" width={20} height={20} />
                </Box>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    color={
                      location.pathname.startsWith("/master-data/trade") ||
                      location.pathname.startsWith("/master-data/bundle-types")
                        ? "#F4347F"
                        : "#000"
                    }
                    variant="h5"
                  >
                    Academics
                  </Typography>
                }
              />
              {Academics ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={Academics} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {Configuration?.slice(4, 6).map((item: any, index: any) => (
                  <Link
                    key={index}
                    to={item.path}
                    style={{ textDecoration: "none" }}
                  >
                    <ListItem disablePadding>
                      <ListItemButton
                        selected={location.pathname.startsWith(item.path)}
                        sx={{
                          "&.Mui-selected": {
                            opacity: "1",
                          },
                          "&.Mui-selected:hover": {},

                          paddingLeft: "30px",
                        }}
                      >
                        <ListItemIcon>
                          <Box
                            sx={{
                              width: "100%",
                              textAlign: "center",
                            }}
                          >
                            <img
                              src={item.icon}
                              alt=""
                              width={20}
                              height={20}
                            />
                          </Box>
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              color={
                                location.pathname.startsWith(item.path)
                                  ? "#F4347F"
                                  : "#000"
                              }
                              variant="h5"
                            >
                              {item?.title}
                            </Typography>
                          }
                        />
                      </ListItemButton>
                      <Box
                        sx={{
                          height: "45px",
                          width: "5px",
                          borderRadius: "10px 0 0 10px",
                          bgcolor: location.pathname.startsWith(item?.path)
                            ? theme.palette.primary.main
                            : "#fff",
                        }}
                      />
                    </ListItem>
                  </Link>
                ))}
              </List>
            </Collapse>

            <ListItemButton
              selected={
                location.pathname.startsWith("/master-data/roles") ||
                location.pathname.startsWith("/master-data/users")
              }
              onClick={() => {
                setUserroles(!Userroles);
              }}
            >
              <ListItemIcon>
                <Box
                  sx={{
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <img src={icons.commonIcon} alt="" width={20} height={20} />
                </Box>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    color={
                      location.pathname.startsWith("/master-data/roles") ||
                      location.pathname.startsWith("/master-data/users")
                        ? "#F4347F"
                        : "#000"
                    }
                    variant="h5"
                  >
                    User-Roles
                  </Typography>
                }
              />
              {Userroles ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={Userroles} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {Configuration?.slice(6, 8).map((item: any, index: any) => (
                  <Link
                    key={index}
                    to={item.path}
                    style={{ textDecoration: "none" }}
                  >
                    <ListItem disablePadding>
                      <ListItemButton
                        selected={location.pathname.startsWith(item.path)}
                        sx={{
                          "&.Mui-selected": {
                            opacity: "1",
                          },
                          "&.Mui-selected:hover": {},

                          paddingLeft: "30px",
                        }}
                      >
                        <ListItemIcon>
                          <Box
                            sx={{
                              width: "100%",
                              textAlign: "center",
                            }}
                          >
                            <img
                              src={item.icon}
                              alt=""
                              width={20}
                              height={20}
                            />
                          </Box>
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              color={
                                location.pathname.startsWith(item.path)
                                  ? "#F4347F"
                                  : "#000"
                              }
                              variant="h5"
                            >
                              {item?.title}
                            </Typography>
                          }
                        />
                      </ListItemButton>
                      <Box
                        sx={{
                          height: "45px",
                          width: "5px",
                          borderRadius: "10px 0 0 10px",
                          bgcolor: location.pathname.startsWith(item?.path)
                            ? theme.palette.primary.main
                            : "#fff",
                        }}
                      />
                    </ListItem>
                  </Link>
                ))}
              </List>
            </Collapse>
            <Link
              to={"/master-data/exam-timetable"}
              style={{ textDecoration: "none" }}
            >
              <ListItem disablePadding>
                <ListItemButton
                  selected={location.pathname.startsWith(
                    "/master-data/exam-timetable"
                  )}
                  sx={{
                    "&.Mui-selected": {
                      opacity: "1",
                    },
                    "&.Mui-selected:hover": {},
                  }}
                >
                  <ListItemIcon>
                    <Box
                      sx={{
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      <img src={icons.user} alt="" width={20} height={20} />
                    </Box>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        color={
                          location.pathname.startsWith(
                            "/master-data/exam-timetable"
                          )
                            ? "#F4347F"
                            : "#000"
                        }
                        variant="h5"
                      >
                        {"Exam Timetable"}
                      </Typography>
                    }
                  />
                </ListItemButton>
                <Box
                  sx={{
                    height: "45px",
                    width: "5px",
                    borderRadius: "10px 0 0 10px",
                    bgcolor: location.pathname.startsWith(
                      "/master-data/exam-timetable"
                    )
                      ? theme.palette.primary.main
                      : "#fff",
                  }}
                />
              </ListItem>
            </Link>
            <Link
              to={"/master-data/notice-board"}
              style={{ textDecoration: "none" }}
            >
              <ListItem disablePadding>
                <ListItemButton
                  selected={location.pathname.startsWith(
                    "/master-data/notice-board"
                  )}
                  sx={{
                    "&.Mui-selected": {
                      opacity: "1",
                    },
                    "&.Mui-selected:hover": {},
                  }}
                >
                  <ListItemIcon>
                    <Box
                      sx={{
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      <img src={icons.user} alt="" width={20} height={20} />
                    </Box>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        color={
                          location.pathname.startsWith(
                            "/master-data/notice-board"
                          )
                            ? "#F4347F"
                            : "#000"
                        }
                        variant="h5"
                      >
                        {"Notice Board"}
                      </Typography>
                    }
                  />
                </ListItemButton>
                <Box
                  sx={{
                    height: "45px",
                    width: "5px",
                    borderRadius: "10px 0 0 10px",
                    bgcolor: location.pathname.startsWith(
                      "/master-data/notice-board"
                    )
                      ? theme.palette.primary.main
                      : "#fff",
                  }}
                />
              </ListItem>
            </Link>
            <Link
              to={"/master-data/doubts"}
              style={{ textDecoration: "none" }}
            >
              <ListItem disablePadding>
                <ListItemButton
                  selected={location.pathname.startsWith(
                    "/master-data/doubts"
                  )}
                  sx={{
                    "&.Mui-selected": {
                      opacity: "1",
                    },
                    "&.Mui-selected:hover": {},
                  }}
                >
                  <ListItemIcon>
                    <Box
                      sx={{
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      <img src={icons.user} alt="" width={20} height={20} />
                    </Box>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        color={
                          location.pathname.startsWith(
                            "/master-data/doubts"
                          )
                            ? "#F4347F"
                            : "#000"
                        }
                        variant="h5"
                      >
                        {"Doubts"}
                      </Typography>
                    }
                  />
                </ListItemButton>
                <Box
                  sx={{
                    height: "45px",
                    width: "5px",
                    borderRadius: "10px 0 0 10px",
                    bgcolor: location.pathname.startsWith(
                      "/master-data/doubts"
                    )
                      ? theme.palette.primary.main
                      : "#fff",
                  }}
                />
              </ListItem>
            </Link>
          </List>
        </Collapse>
      </List>
    </StyledSideNav>
  );
};

export default NewLayout;
