import { useMutation, useQueryClient } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";

import { Add, ChevronRightTwoTone, EditTwoTone } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Pagination,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { useImmer } from "use-immer";
import moment from "moment";
import DialogWrapper from "../../../../../components/DialogWrapper/DialogWrapper";
import EBookForm from "../../../../e-books/EBookForm";
import { getEbooks, postComponents } from "../../../../../api/services";
import { emptyDashboard } from "../../../../../assets";
import EmptyPage from "../../../../../components/EmptyPage";
import { ViewButton } from "../../../../../components/IconButtons";
import useDebounce from "../../../../../components/Searchdebouncehook";
import EBookCard from "../../../../e-books/EBookCard";
import Table from "../../../../../components/TableComponent";
import { toast } from "react-toastify";

export const EBookComponent = ({ data: addedEbooks, close }: any) => {
  const queryClient = useQueryClient();
  const { id: subjectId } = useParams();
  const [search] = useSearchParams();
  const topicId = search.get("topicId");
  const chapterId = search.get("chapterId");
  const sectionId = search.get("sectionId");

  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [booksearch, setSearch] = useState("");
  const debouncedSearchValue = useDebounce(booksearch, 1000);
  const [limit, setlimit] = useState(10);
  const [offset, setoffset] = useState(0);
  const [page, setPage] = useState(1);

  const { data, isLoading } = useQuery(
    [
      "e-books",
      {
        search: debouncedSearchValue ? debouncedSearchValue : "",
        offset: offset,
        limit: limit,
      },
    ],
    getEbooks
  );

  const { mutate } = useMutation(postComponents, {
    onSuccess: (res: any) => {
      toast.success(res?.data?.message);
      queryClient.invalidateQueries("topic-id");
      close();
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const handleAdd = (id: any) => {
    const res = {
      subjectId,
      chapterId,
      topicId,
      sectionId,
      type: "EBOOK",
      ebookId: id,
    };
    mutate(res);
  };

  const allEbooks = data?.data?.data;

  const handleSearch = (e: any) => {
    setSearch(e.target.value);
    setPage(1); // Reset page number when searching
  };

  const handlePagination = (e: any, newPage: number) => {
    setoffset(newPage * limit);
  };

  const handlePaginationLimit = (e: any) => {
    setoffset(0);
    setlimit(e.target.value);
  };

  const column = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "created at",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (colData: any, row: any) => {
        return (
          <Typography variant="subtitle1" fontSize={16}>
            {moment(row?.createdAt).format("DD-MM-YYYY")}
          </Typography>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "_id",
      key: "_id",
      render: (colData: any, row: any) => {
        return (
          <Box>
            {!addedEbooks
              ?.map((item: any) => item?.ebookId)
              ?.includes(row?._id) ? (
              <Button
                onClick={() => handleAdd(row?._id)}
                size="small"
                endIcon={<Add />}>
                {"Add"}
              </Button>
            ) : (
              <Typography color="green">Added</Typography>
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h2" gutterBottom>
          Add-E-Books
        </Typography>
      </Box>

      <Paper sx={{ p: 1, mt: 2 }}>
        <Stack direction="row" gap={1} alignItems="center">
          <TextField
            sx={{
              width: "500px",
            }}
            label="Search"
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
            value={booksearch}
            onChange={handleSearch}
          />
        </Stack>
      </Paper>
      {data?.data?.count > 0 ? (
        <Box sx={{ marginTop: "20px" }}>
          <Table
            columns={column}
            headAlign={"center"}
            tableAlign={"center"}
            dataSource={allEbooks || []}
            loading={isLoading}
            pagination={{
              page: offset / limit,
              count: data?.data.count,
              onPageChange: handlePagination,
              rowsPerPage: limit,
              onRowsPerPageChange: handlePaginationLimit,
            }}
          />
        </Box>
      ) : (
        <Box sx={{ mt: 2 }}>
          <EmptyPage image={emptyDashboard} title="No E-Books" />
        </Box>
      )}
      <Box display="flex" justifyContent="center" my={2}>
        {/* <Pagination
          count={Math.ceil(data?.data?.count / 10)}
          page={state.page}
          onChange={handlePagination}
          color="primary"
        /> */}
      </Box>
      <DialogWrapper width="md" title="" open={open} setOpen={setOpen}>
        <EBookForm data={null} close={() => setOpen(false)} />
      </DialogWrapper>
    </>
  );
};
