import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Layout from "../layout/mainLayout";
import CourseView from "../pages/courses/CourseView";
import CurriculumBuilder from "../pages/curriculums/curriculumBuilder";
import {
  AboutCourse,
  AboutCourseBundle,
  BundleQuiz,
  CourseBundleCourses,
  CourseBundleExams,
  CourseBundleView,
  CourseConfig,
  CourseEbooks,
  CourseExams,
  Courses,
  CoursesBundle,
  Curriculums,
  DiscussionForum,
  Districts,
  EBooks,
  EBooksDetails,
  Exam,
  ExamDetails,
  ExaminationGuideline,
  ExaminationGuidelineTabs,
  ItiCenter,
  ItiCenterType,
  LiveSession,
  Login,
  Questionbank,
  Questions,
  Quiz,
  QuizDetails,
  Roles,
  ExamTimetable,
  TimeTableTabs,
  Trade,
  TradeTimeTable,
  Users,
  YearPage,
  Noticeboard,
  AddNoticeboard,
  TradeView,
  Doubts,
} from "./routeImports";
import Students from "../pages/masterData/Students";
import { ManageQuestions } from "../pages/exams/ExamConfiguration/ExamPartDetails/ManageQuestions";
import ManageQuizQuestions from "../pages/quiz/Configuration/QuizDetails/ManageQuestions/ManageQuizQuestions";
import StudentView from "../pages/masterData/StudentView";
import SettingsLayout from "../layout/mainLayout/SettingsLayout";

export const RoutesContainer = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate to="login" />} />
          <Route path="curriculums" element={<Curriculums />} />
          <Route path="curriculums/:id" element={<CurriculumBuilder />} />
          <Route path="question-bank" element={<Questionbank />} />
          <Route path="question-bank/:id" element={<Questions />} />
          <Route path="e-books" element={<EBooks />} />
          <Route path="e-books/:id" element={<EBooksDetails />} />
          <Route path="Students" element={<Students />} />
          <Route path="Students/:id" element={<StudentView />} />
          {/* <Route path="master-data/districts" element={<Districts />} />
          <Route
            path="master-data/iti-centre-type"
            element={<ItiCenterType />}
          />
          <Route path="master-data/bundle-types" element={<BundleQuiz />} />
          <Route path="master-data/trade" element={<Trade />} />
          <Route path="master-data/trade/:Tradeid" element={<Tradefeetime1 />} />
          <Route path="master-data/iti-centres" element={<ItiCenter />} />
          <Route path="master-data/users" element={<Users />} />
          <Route path="master-data/roles" element={<Roles />} />
          <Route path="master-data/year" element={<YearPage />} /> */}
          <Route
            path="/students-corner/trade-time-table"
            element={<TradeTimeTable />}
          />
          <Route
            path="/students-corner/trade-time-table/:id"
            element={<TimeTableTabs />}
          />
          <Route
            path="/students-corner/examination-guideline"
            element={<ExaminationGuideline />}
          />
          <Route
            path="/students-corner/practical-slot"
            element={<h1>This is practical-slot</h1>}
          />
          <Route
            path="/students-corner/live-session"
            element={<LiveSession />}
          />
          <Route
            path="/students-corner/discussion-forum"
            element={<DiscussionForum />}
          />

          <Route
            path="/students-corner/trade-time-table"
            element={<TradeTimeTable />}
          />

          <Route
            path="/students-corner/examination-guideline"
            element={<ExaminationGuideline />}
          />
          <Route
            path="/students-corner/examination-guideline/:id"
            element={<ExaminationGuidelineTabs />}
          />
          <Route
            path="/students-corner/practical-slot"
            element={<h1>This is practical-slot</h1>}
          />
          <Route
            path="/students-corner/live-session"
            element={<LiveSession />}
          />
          <Route
            path="/students-corner/discussion-forum"
            element={<DiscussionForum />}
          />
          <Route path="app/quiz" element={<Quiz />} />
          <Route path="app/quiz/:id" element={<QuizDetails />} />
          <Route
            path="app/quiz/:id/manage-questions"
            element={<ManageQuizQuestions />}
          />

          <Route path="app/exams" element={<Exam />} />
          <Route path="app/exams/:id" element={<ExamDetails />} />
          <Route
            path="app/exams/:id/manage-questions"
            element={<ManageQuestions />}
          />
          <Route path="courses/all-courses" element={<Courses />} />

          <Route path="courses/all-courses/:id" element={<CourseView />}>
            <Route index element={<Navigate to="about-course" />} />
            <Route path="about-course" element={<AboutCourse />} />
            <Route path="configuration" element={<CourseConfig />} />
            <Route path="exams" element={<CourseExams />} />
            <Route path="e-books" element={<CourseEbooks />} />
          </Route>
          <Route path="courses/course-bundles" element={<CoursesBundle />} />
          <Route
            path="courses/course-bundles/:id"
            element={<CourseBundleView />}
          >
            <Route index element={<Navigate to="about-course-bundle" />} />
            <Route path="about-course-bundle" element={<AboutCourseBundle />} />
            <Route path="courses" element={<CourseBundleCourses />} />
            <Route path="exams" element={<CourseBundleExams />} />
            <Route
              path="e-books"
              element={<h1>E books component need to be written </h1>}
            />
          </Route>
        </Route>
        <Route path="/master-data" element={<SettingsLayout />}>
          <Route index element={<Navigate to="iti-centres" />} />
          <Route path="districts" element={<Districts />} />
          <Route path="iti-centre-type" element={<ItiCenterType />} />
          <Route path="bundle-types" element={<BundleQuiz />} />
          <Route path="trade" element={<Trade />} />
          <Route path="trade/:Tradeid" element={<TradeView />} />
          <Route path="iti-centres" element={<ItiCenter />} />
          <Route path="users" element={<Users />} />
          <Route path="roles" element={<Roles />} />
          <Route path="year" element={<YearPage />} />
          <Route path="exam-timetable" element={<ExamTimetable />} />
          <Route path="notice-board" element={<Noticeboard />} />
          <Route path="doubts" element={<Doubts />} />
          <Route path="notice-board/add" element={<AddNoticeboard />} />
          <Route
            path="notice-board/view/:noticeId"
            element={<AddNoticeboard />}
          />
          <Route
            path="notice-board/edit/:noticeId"
            element={<AddNoticeboard />}
          />
        </Route>
        <Route path="login" element={<Login />} />
      </Routes>
    </Router>
  );
};
