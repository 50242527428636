import { Box, Chip, Stack, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { getAllLanguages } from "../../api/services";

export default function LanguagesFilters({ state, setState }: any) {
  const { data: languageData } = useQuery("languages", getAllLanguages);

  return (
    <Box
      sx={{
        marginLeft: "50px",
      }}>
      <Typography gutterBottom variant="subtitle1">
        Languages
      </Typography>
      <Stack gap={1} direction="row">
        {languageData?.data?.data?.map((item: any) => (
          <Chip
            size="small"
            key={item?._id}
            color={"primary"}
            label={item?.name}
            clickable
            variant={
              state?.languageId?.includes(item?._id) ? "filled" : "outlined"
            }
            onClick={() => {
              setState({ ...state, languageId: item?._id });
            }}
          />
        ))}
      </Stack>
    </Box>
  );
}
