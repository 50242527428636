import {
  DeleteTwoTone,
  Download,
  DragIndicator,
  EditTwoTone,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  DeleteComponents,
  getEbooksById,
  getQuizById,
} from "../../../../api/services";
import DialogWrapper from "../../../../components/DialogWrapper/DialogWrapper";
import { StyledFlexBox } from "../../../../styles";
import { ContentType } from "../../../../types";
import { LabelContainer } from "../../../quiz/components/styles";
import fileImage from "./download.png";
import FileContentForm from "./forms/FileComponent";
import ImageContentForm from "./forms/ImageComponent";
import LinkContentForm from "./forms/LinkComponent";
import QuizComponent from "./forms/QuizComponent";
import VideoContentForm from "./forms/VideoComponent";
import { ExperimentForm } from "./forms/experimentForm";
import TextContentForm from "./forms/textContentForm";
import EBookCard from "../../../e-books/EBookCard";
import { ViewButton } from "../../../../components/IconButtons";
import EbookCardComponent from "./forms/EbookCardComponent";
import Visualcomponent from "./forms/Visualcomponent";
import Visualform from "./forms/Visualform";

const ContentViewCard = ({ data }: any) => {
  return (
    <>
      <ActionsWrapper data={data}>{renderChild(data)}</ActionsWrapper>
    </>
  );
};

export default ContentViewCard;

const ActionsWrapper = ({ children, data }: any) => {
  const location = useLocation();
  // const { isConfirmed } = useConfirm();
  const isCurriculumsPage = location.pathname.includes("curriculums");

  const queryClient = useQueryClient();
  const [search] = useSearchParams();
  const topicId = search.get("topicId");

  const { mutate } = useMutation(DeleteComponents, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("topic-id");
      toast.success("Component deleted successfully");
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleEdit = () => {
    setOpenModal(true);
  };

  const handleDelete = () => {
    mutate(data?._id);
  };

  const renderContentForm = () => {
    switch (data?.type) {
      case ContentType.text:
        return (
          <TextContentForm data={data} close={() => setOpenModal(false)} />
        );
      case ContentType.image:
        return (
          <ImageContentForm data={data} close={() => setOpenModal(false)} />
        );
      case ContentType.video:
        return (
          <VideoContentForm data={data} close={() => setOpenModal(false)} />
        );
      case ContentType.quiz:
        return <QuizComponent data={data} close={() => setOpenModal(false)} />;
      case ContentType.link:
        return (
          <LinkContentForm data={data} close={() => setOpenModal(false)} />
        );
      case ContentType.resource:
        return (
          <FileContentForm data={data} close={() => setOpenModal(false)} />
        );
      case ContentType.visual:
        return (
          <Visualform
            data={data}
            close={() => setOpenModal(false)}></Visualform>
        );
      case ContentType.experiment:
        return <ExperimentForm data={data} close={() => setOpenModal(false)} />;
      // case ContentType.visual:
      //   return (
      //     <VisualizationComponent
      //       data={data}
      //       close={() => setOpenModal(false)}
      //     />
      //   );
      default:
        return <div>No Match</div>;
    }
  };

  const isQuizOrEBook =
    data?.type === ContentType.quiz || data?.type === ContentType.ebook;

  return (
    <Paper sx={{ m: 1 }}>
      <Box p={1} px={2}>
        {isCurriculumsPage && (
          <>
            <Box display="flex" justifyContent="space-between">
              <Stack direction="row" gap={1} alignItems="center">
                <IconButton disableRipple>
                  <DragIndicator fontSize="small" />
                </IconButton>
                <Typography>{data?.type}</Typography>
              </Stack>
              <Box>
                {!isQuizOrEBook && (
                  <IconButton color="primary" onClick={handleEdit}>
                    <EditTwoTone fontSize="small" />
                  </IconButton>
                )}
                <IconButton color="error" onClick={handleDelete}>
                  <DeleteTwoTone fontSize="small" />
                </IconButton>
              </Box>
            </Box>
            <Divider />
          </>
        )}
        <div>{children}</div>
      </Box>

      {isCurriculumsPage && (
        <DialogWrapper
          width="md"
          title="Component"
          open={openModal}
          setOpen={setOpenModal}>
          {renderContentForm()}
        </DialogWrapper>
      )}
    </Paper>
  );
};

const renderChild = (data: any) => {
  switch (data?.type) {
    case ContentType.text:
      return (
        <div className="">
          <Box
            sx={{ fontFamily: "figtree_medium", fontSize: "16px" }}
            dangerouslySetInnerHTML={{ __html: data?.description }}
          />
        </div>
      );

    case ContentType.visual:
      const VisualUrl = data.link;

      return (
        <Stack
          gap={1}
          position="relative"
          alignItems="center"
          justifyContent="center"
          maxHeight={500}>
          <Box sx={{ padding: 0, paddingTop: "45%" }}>
            <iframe
              src={VisualUrl}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen></iframe>
          </Box>

          {/* <Box pt={14} bottom={0}>
            <Typography variant="subtitle2" textAlign="center">
              {data?.title}
            </Typography>
            <Box
              sx={{
                fontFamily: "figtree_medium",
                fontSize: "16px",
                m: 0,
                p: 0,
              }}
              dangerouslySetInnerHTML={{ __html: data?.description }}
            />
          </Box> */}
        </Stack>
      );

    case ContentType.video:
      var doc = new DOMParser().parseFromString(data?.link, "text/html");

      const videoUrl =
        doc.documentElement.getElementsByTagName("iframe")[0]?.src ?? "";

      return (
        <Stack
          gap={1}
          position="relative"
          alignItems="center"
          justifyContent="center"
          maxHeight={500}>
          <Box sx={{ padding: 0, paddingTop: "45%" }}>
            <iframe
              src={videoUrl}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen></iframe>
          </Box>
          {/* <p>
              <a href="https://vimeo.com/7466309">KIRboard Cat</a> from{' '}
              <a href="https://vimeo.com/kirstenlepore">Kirsten Lepore</a> on{' '}
              <a href="https://vimeo.com">Vimeo</a>.
            </p> */}
          <Box pt={14} bottom={0}>
            <Typography variant="subtitle2" textAlign="center">
              {data?.title}
            </Typography>
            <Box
              sx={{
                fontFamily: "figtree_medium",
                fontSize: "16px",
                m: 0,
                p: 0,
              }}
              dangerouslySetInnerHTML={{ __html: data?.description }}
            />
          </Box>
        </Stack>
      );

    case ContentType.link:
      return (
        <Box m={1}>
          <a target="_blank" rel="noreferrer" href={data?.link}>
            {data?.title}
          </a>
        </Box>
      );

    case ContentType.ebook:
      return (
        <Box>
          <EbookCardComponent data={data} />
        </Box>
      );

    case ContentType.image:
      return (
        <>
          <Box height={400} mb={1}>
            <img
              src={data?.fileUrl}
              alt=""
              height="100%"
              width="100%"
              style={{ objectFit: "contain" }}
            />
          </Box>
          <Typography variant="subtitle2" textAlign="center">
            {data?.title}
          </Typography>
        </>
      );
    case ContentType.quiz:
      return <QuizViewCard quizId={data?.quizId} />;
    case ContentType.resource:
      return <ResourceCard data={data} />;
    case ContentType.experiment:
      return (
        <Box>
          <Typography variant="subtitle2">{data?.title}</Typography>
          <StyledFlexBox gap={1}>
            <Box
              sx={{ fontFamily: "figtree_medium", fontSize: "16px" }}
              dangerouslySetInnerHTML={{ __html: data?.description }}
            />
          </StyledFlexBox>
        </Box>
      );
    default:
      break;
  }
};

const QuizViewCard = ({ quizId }: any) => {
  const [open, setOpen] = useState<boolean>(false);

  const { data: quizData, isLoading } = useQuery(
    ["quiz-id", quizId],
    getQuizById,
    {
      enabled: !!quizId,
    }
  );

  return (
    <Box>
      <LabelContainer
        data={[
          {
            label: "Quiz Title",
            value: quizData?.data?.name,
          },
          {
            label: "Difficulty Level",
            value: quizData?.data?.difficultyLevel,
          },
          {
            label: "Max Questions",
            value: quizData?.data?.maxQuestions,
          },
        ]}
      />
      <Box sx={{ my: 1 }}>
        <Typography variant="subtitle1">Short Description</Typography>
        <Box
          sx={{
            fontFamily: "figtree_medium",
            "& *": { margin: 0, padding: 0 },
          }}
          dangerouslySetInnerHTML={{
            __html: quizData?.data?.description,
          }}></Box>
      </Box>
    </Box>
  );
};

const DisplayCard = ({ label, value }: any) => {
  return (
    <Box>
      <Typography variant="subtitle1" gutterBottom>
        {label}
      </Typography>
      <Typography variant="h4">{value}</Typography>
    </Box>
  );
};

function ResourceCard({ data }: any) {
  console.log("data", data);
  return (
    <Card sx={{ maxWidth: 320, my: 1 }}>
      <CardMedia
        sx={{ objectFit: "contain" }}
        component="img"
        height="140"
        image={fileImage}
        alt="File"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {data?.title}
        </Typography>
      </CardContent>
      <CardActions>
        <a
          href={data?.fileUrl}
          style={{ textDecoration: "none" }}
          download
          target="_blank"
          rel="noreferrer">
          <Button variant="outlined" startIcon={<Download />} size="small">
            Download
          </Button>
        </a>
      </CardActions>
    </Card>
  );
}
