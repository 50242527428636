import { Button, Typography } from "@mui/material";
import { StyledDfAcJc } from "../styles";

type Props = {
  image: any;
  height?: string;
  title: string | number;
  subTitle?: string | number;
  onClick?: () => void;
  buttonTitle?: string;
};

const EmptyPage = ({
  image,
  height = "50vh",
  title,
  subTitle,
  onClick,
  buttonTitle,
}: Props) => {
  return (
    <StyledDfAcJc flexDirection="column" height={height}>
      <img src={image} alt="logo" height={"300px"} />
      <Typography mt={2} variant="h3">
        {title}
      </Typography>
      <Typography variant="subtitle2">{subTitle}</Typography>
      {onClick && (
        <>
          <Button sx={{ mt: 2 }} onClick={onClick} variant="contained">
            + Add {buttonTitle}
          </Button>
        </>
      )}
    </StyledDfAcJc>
  );
};

export default EmptyPage;
