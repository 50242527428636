import { Box, styled } from "@mui/material";

export const StyledLabelContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gap: "1rem",
  rowGap: "0.5",
  justifyContent: "center",
  justifyItems: "center",
  padding: "0.5rem",
  borderRadius: "0.5rem",
  background: "#fafafa",
}));
