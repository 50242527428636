import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ListItemIcon, ListItemText } from "@mui/material";
import { EditTwoTone, DeleteTwoTone } from "@mui/icons-material";
import { ViewButton } from "../IconButtons";

const options = [
  "None",
  "Atria",
  "Callisto",
  "Dione",
  "Ganymede",
  "Hangouts Call",
  "Luna",
  "Oberon",
  "Phobos",
  "Pyxis",
  "Sedna",
  "Titania",
  "Triton",
  "Umbriel",
];
const ITEM_HEIGHT = 48;

export default function Maxheightmenu_C({
  onDelete,
  onEdit,
  onView,
  isView = true,
  isEdit = true,
  isDelete = true,
}: any) {
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={isOpen ? "long-menu" : undefined}
        aria-expanded={isOpen ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}>
        {isView ? (
          <MenuItem
            onClick={() => {
              handleClose();
              onView();
            }}>
            <ListItemIcon>
              <ViewButton color="primary" />
            </ListItemIcon>
            <ListItemText>View</ListItemText>
          </MenuItem>
        ) : (
          <></>
        )}
        {isEdit ? (
          <MenuItem
            onClick={() => {
              handleClose();
              onEdit();
            }}>
            <ListItemIcon>
              <EditTwoTone color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
        ) : (
          <></>
        )}

        {isDelete ? (
          <MenuItem
            onClick={() => {
              handleClose();
              onDelete();
            }}>
            <ListItemIcon>
              <DeleteTwoTone color="primary" />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        ) : (
          <></>
        )}
      </Menu>
    </div>
  );
}
