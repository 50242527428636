import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useImmer } from "use-immer";
import * as Yup from "yup";
import { createChapter, updateChapter } from "../../../../api/services";
import { TextField } from "../../../../components/forms/TextField";
import Quill from "../../../../components/Quill";

const validationSchema = Yup.object().shape({
  name: Yup.string().max(255).required("The title field is required"),
});

export default function CreateChapter({ data = null, close }: any) {
  const queryClient = useQueryClient();
  const [_, setSearch] = useSearchParams();
  const params: any = useParams();
  const subjectId = params;

  const [state, setState] = useImmer<{
    description: string;
  }>({
    description: data?.description ?? "",
  });

  const { mutate } = useMutation(createChapter, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("subjectById");
      toast.success(res?.data?.message);
      close();
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { mutate: updateChapterMutate } = useMutation(updateChapter, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("subjectById");
      toast.success(res?.data?.message);
      close();
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {
      title: data?.title ?? "",
      isPrerequisite: data?.isPrerequisite ?? false,
    },
  });

  const onSubmit = (formData: any) => {
    const res = {
      ...formData,
      subjectId: subjectId?.id,
      description: state?.description,
    };
    if (data !== null) {
      updateChapterMutate({ id: data?._id, body: res });
    } else {
      mutate(res);
    }
  };

  useEffect(() => {
    setState({
      description: data?.description || "",
    });
  }, [data]);

  const handleChange = (value: any) => {
    setState((s: any) => {
      s.description = value;
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2}>
        <TextField name="title" label="Week Title" control={control} />
        <FormControl>
          <FormLabel sx={{ mb: 0.5 }}>Description</FormLabel>
          <Quill
            data={state?.description}
            handleChange={(value) => handleChange(value)}
          />
        </FormControl>
        <Controller
          name="isPrerequisite"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Box display={"flex"} gap={1} alignItems="center">
              <Checkbox
                checked={value}
                onChange={(e) => {
                  setValue("isPrerequisite", e.target.checked);
                }}
              />
              <Typography>Prerequisite</Typography>
            </Box>
          )}
        />
        <Box>
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </Box>
      </Stack>
    </form>
  );
}
