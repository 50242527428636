import { Box, Button, Checkbox, Stack, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { createTopic, updateTopic } from "../../../../api/services";
import { TextField } from "../../../../components/forms/TextField";

const validationSchema = Yup.object().shape({
  name: Yup.string().max(255).required("The title field is required"),
});

const TopicForm = ({ section, close, chapterId }: any) => {
  const queryClient = useQueryClient();
  const [search, setSearch] = useSearchParams();
  const chapterIdParam = search.get("chapterId");
  const sectionIdParam = search.get("sectionId");
  const params = useParams();
  const subjectId = params;

  const { mutate } = useMutation(createTopic, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("subjectById");
      toast.success(res?.data?.message);
      close();
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { mutate: updateTopicMutate } = useMutation(updateTopic, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("subjectById");
      toast.success(res?.data?.message);
      close();
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {
      title: section?.title ?? "",
      isPrerequisite: section?.isPrerequisite ?? false,
    },
  });

  const onSubmit = (data: any) => {
    const res = {
      ...data,
      chapterId: chapterIdParam,
      subjectId: subjectId?.id,
      sectionId: sectionIdParam,
    };
    if (section !== null) {
      updateTopicMutate({ id: section?._id, body: res });
    } else {
      mutate(res);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2}>
        <TextField name="title" label="Topic Name" control={control} />
        <Controller
          name="isPrerequisite"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Box display={"flex"} gap={1} alignItems="center">
              <Checkbox checked={value} onChange={onChange} />
              <Typography>Prerequisite</Typography>
            </Box>
          )}
        />
        <Box>
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </Box>
      </Stack>
    </form>
  );
};
export default TopicForm;
