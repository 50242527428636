import { Box, Card, Stack, styled, Typography } from "@mui/material";

export const StyledSidePanelWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  paddingRight: "0.5rem",
  height: "100%",
  paddingBottom: "0.5rem",
}));

export const StyledSidePanelHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0.5rem",
  borderBottom: "1px solid #e0e0e0",
  background: "white",
  borderTopRightRadius: "0.5rem",
  borderTopLeftRadius: "0.5rem",
  color: theme.palette.text.secondary,
}));

export const StyledDurationCard = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  padding: "0.3rem 0.6rem",
  borderRadius: "5px",
  color: theme.palette.primary.main,
  display: "flex",
  gap: "0.5rem",
}));

const StyledLabelContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gap: "1rem",
  rowGap: "0.5",
  justifyContent: "center",
  justifyItems: "center",
  padding: "0.5rem",
  borderRadius: "0.5rem",
  background: theme.palette.grey[50],
}));

export const LabelContainer = ({
  data,
}: {
  data: { value: any; label: string }[];
}) => {
  return (
    <StyledLabelContainer>
      {data?.map((item, index) => (
        <LabelCard key={index} value={item.value} label={item.label} />
      ))}
    </StyledLabelContainer>
  );
};

export const LabelCard = ({ value, label }: any) => {
  if (!value) return null;
  return (
    <Stack gap={1} alignItems={"center"} textAlign={"center"}>
      <Typography fontSize={12} variant="subtitle2">
        {label}
      </Typography>
      <Typography variant="h5">{value || ""}</Typography>
    </Stack>
  );
};

export const StyledExamCard = styled(Card)(({ theme }) => ({
  padding: "0.5rem",
  display: "flex",
  width: "100%",
  "& .MuiCardHeader-root": {
    padding: 0,
    display: "flex",

    justifyContent: "space-between",
    width: "40%",
    "& .MuiCardHeader-title": {
      fontSize: "18px",
      fontWeight: 500,
    },
  },
  "& .MuiCardHeader-action": {
    alignSelf: "center",
    marginRight: 0,
  },
  "& .MuiCardContent-root": {
    flexGrow: 1,
    padding: 0,
    paddingBottom: "0.5rem",
  },
}));

export const StyledPartCard = styled(Card)<{ isActive: boolean }>(
  ({ theme, isActive }) => ({
    boxShadow: "none",
    cursor: "pointer",
    padding: "0.5rem",
    ...(isActive && {
      outline: `2px solid ${theme.palette.primary.light}`,
    }),
    "& .MuiCardHeader-root": {
      padding: 0,
      "& .MuiCardHeader-title": {
        fontSize: "18px",
        fontWeight: 500,
      },
    },
    "& .MuiCardHeader-action": {
      alignSelf: "center",
      marginRight: 0,
    },
    "& .MuiCardContent-root": {
      padding: 0,
      paddingTop: "0.5rem",
      paddingBottom: "0.5rem",
    },
  })
);
