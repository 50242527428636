import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { TextField } from "../../../../../components/forms/TextField";
import useDebounce from "../../../../../components/Searchdebouncehook";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "react-query";
import { postComponents } from "../../../../../api/services";
import Quill from "../../../../../components/Quill/Quill";

export default function Visualform(params: any) {
  const queryClient = useQueryClient();
  const { id: subjectId } = useParams();
  const [search] = useSearchParams();
  const topicId = search.get("topicId");
  const chapterId = search.get("chapterId");
  const sectionId = search.get("sectionId");
  console.log(params.data);
  const { control, watch, reset, handleSubmit } = useForm({
    defaultValues: {
      visulalink: params.data?.link ?? "",
      title: params.data?.title ?? "",
    },
  });
  const [quill, setquilldata] = useState<any>();

  const { mutate } = useMutation(postComponents, {
    onSuccess: (res: any) => {
      toast.success(res?.data?.message);
      queryClient.invalidateQueries("topic-id");
      params.close();
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const onsubmit = (data: any) => {
    const res = {
      subjectId,
      chapterId,
      topicId,
      sectionId,
      type: "3D",
      link: data.visulalink,
      title: data.title,
      description: quill,
    };
    console.log(res);
    mutate(res);
  };

  const handlequilldata = (e: any) => {
    setquilldata(e);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onsubmit)}>
        <Stack gap={2}>
          <TextField name="title" label="Video Title" control={control} />
          <FormControl>
            <FormLabel sx={{ mb: 0.5 }}>Video Description</FormLabel>
            <Quill data={quill} handleChange={handlequilldata} />
          </FormControl>
          <TextField
            name="visulalink"
            label="3D Visual link"
            control={control}
            muiProps={{
              multiline: true,
              minRows: 5,
              helperText: "Please add 3D Visual link only",
            }}
          />
          {/* <div id="player"></div> */}
          <Box>
            <Button
              sx={{
                my: "20px",
              }}
              variant="contained"
              type="submit">
              Submit
            </Button>
          </Box>
        </Stack>
      </form>
      {watch().visulalink ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            my: "10px",
          }}>
          <iframe
            title="Corgi Animated"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            width={"50%"}
            height={"300px"}
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            src={watch().visulalink}></iframe>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
}
