import { Add, Close } from "@mui/icons-material";
import { Box, Button, FormLabel, IconButton, Stack } from "@mui/material";
import { useFieldArray, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
// import Quill from "src/components/Quill";
// import TagsField from "src/components/TagsField";
// import { TextField } from "src/components/forms/TextField";
// import ComponentGroup from "src/content/question-bank/components/ComponentGroup";
// import DifficultyLevelField from "src/content/question-bank/components/DifficultyLevelField";
// import LanguagesField from "src/content/question-bank/components/LanguagesField";
// import MediaFields from "src/content/question-bank/components/MediaFields";
// import { QuestionTypeKey } from "src/types";
// import http from "src/utils/http";
import { v4 as uuidv4 } from "uuid";

import * as Yup from "yup";
import { http } from "../../../../api/http";
import Quill from "../../../../components/Quill";
import { TextField } from "../../../../components/forms/TextField";
import ComponentGroup from "../../components/ComponentGroup";
import DifficultyLevelField from "../../components/DifficultyLevelField";
import LanguagesField from "../../components/LanguagesField";
import MediaFields from "../../components/MediaFields";
import { QuestionTypeKey } from "../service";
import { passageStore } from "./PassageQuestionForm";

const validationSchema = Yup.object().shape({
  question: Yup.string().max(255).required("The title field is required"),
  marks: Yup.number()
    .moreThan(0, "Marks should be greater than 0")
    .required("This field is required")
    .nullable(),
  negativeMarks: Yup.number().required("This field is required").nullable(),
});

const BlanksQuestionForm = ({ data, close, passage }: any) => {
  const [search] = useSearchParams();
  const isMultiple =
    search.get("qnType") === QuestionTypeKey.BLANKS_MULTIPLE ||
    data?.questionType === QuestionTypeKey.BLANKS_MULTIPLE;
  const passageState = passageStore.useState((s) => s);

  const queryClient = useQueryClient();
  const params = useParams();

  const initialState = {
    question: data ? data.question : "",
    correctAnswers: data
      ? data?.correctAnswers?.map((item: any) => ({ value: item }))
      : [{ value: "" }],
    marks: data ? data.marks : null,
    language: data ? data?.language : "",
    negativeMarks: data ? data.negativeMarks : null,
    difficultyLevel: data ? data.difficultyLevel : "",
    explanation: data ? data.explanation : "",
    tags: data ? data.tags : [],
    videoLink: data?.videoLink ?? "",
    audioFile:
      data?.files?.find((item: any) => item?.mimeType.startsWith("audio")) ??
      "",
    imageFile: data?.files?.find((item: any) => item.subType === "image") ?? "",
  };

  const { handleSubmit, control, watch, setValue } = useForm({
    defaultValues: initialState,
    // resolver: yupResolver(validationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "correctAnswers",
  });

  const onSubmit = async (formData: any, e: any) => {
    const postBody = {
      ...formData,
      questionType: isMultiple
        ? QuestionTypeKey.BLANKS_MULTIPLE
        : QuestionTypeKey.BLANKS_SINGLE,
      questionBankId: params?.questionBankId,
      correctAnswers: formData.correctAnswers.map((item: any) => item.value),
      fileIds: [formData?.audioFile?.id, formData?.imageFile?.id].filter(
        (item) => !!item
      ),
      tags: formData?.tags.map((item: any) => item.name),
    };

    if (passage && data) {
      try {
        const res = await http.put(`/questions/${data?.id}`, postBody);
        passageStore.update((s: any) => {
          s.questions.filter((item: any) => item.id !== data?.id);
          s.questions.push(res);
        });
        close();
        queryClient.invalidateQueries([
          "passage-questions",
          params?.questionBankId,
        ]);
        return;
      } catch (err: any) {
        console.log(err);
        toast.error(err?.response?.data?.message ?? "red");
        return;
      }
    }

    if (passage) {
      passageStore.update((s: any) => {
        s.questions.push({ questionId: uuidv4(), ...postBody });
      });
      close();
      return;
    }

    try {
      if (data) {
        await http.put(`/questions/${data?.id}`, postBody);
      } else {
        await http.post("/questions", postBody);
      }
      queryClient.invalidateQueries("questions");
      close();
    } catch (err) {
      console.log(err);
    }
  };

  const onError = (errors: any, e: any) => {
    // setErrors(Object.values(errors).map((item: any) => item.message));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <Stack gap={2}>
        <ComponentGroup title="Question">
          <Quill
            placeholder="Question"
            data={watch("question")}
            handleChange={(value: any) => setValue("question", value)}
          />

          <MediaFields watch={watch} setValue={setValue} control={control} />
        </ComponentGroup>

        <ComponentGroup title="Blanks">
          <Stack gap={2}>
            {fields.map((item, idx) => (
              <Box
                key={item.id}
                sx={{
                  position: "relative",
                  "&:hover .close-btn": {
                    display: "block",
                  },
                }}
              >
                <FormLabel sx={{ mb: 1 }}>{`Blank ${idx + 1}`}</FormLabel>
                <TextField
                  name={`correctAnswers.${idx}.value`}
                  control={control}
                />
                {isMultiple && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 18,
                      right: 6,
                      transform: "translateY(25%)",
                      display: "none",
                    }}
                    className="close-btn"
                  >
                    <IconButton
                      color="error"
                      size="small"
                      onClick={() => remove(idx)}
                    >
                      <Close />
                    </IconButton>
                  </Box>
                )}
              </Box>
            ))}
            {isMultiple && (
              <Box>
                <Button
                  startIcon={<Add />}
                  size="small"
                  onClick={() => append({ value: "" })}
                >
                  Add Blank
                </Button>
              </Box>
            )}
          </Stack>
        </ComponentGroup>

        <ComponentGroup title="Tags">
          {/* <TagsField
            initialValues={watch("tags")}
            onChange={(value: any) => setValue("tags", value)}
          /> */}
          <LanguagesField name="language" control={control} />
          <DifficultyLevelField name="difficultyLevel" control={control} />
        </ComponentGroup>

        <ComponentGroup title="Marks">
          <TextField
            muiProps={{
              type: "number",
            }}
            label="Marks"
            control={control}
            name="marks"
          />
          <TextField
            muiProps={{
              type: "number",
            }}
            label="Negative marks"
            control={control}
            name="negativeMarks"
          />
        </ComponentGroup>

        <ComponentGroup title="Explanation">
          <Quill
            placeholder="Explanation"
            data={watch("explanation")}
            handleChange={(value: any) => setValue("explanation", value)}
          />
        </ComponentGroup>
        {/* {errors.length ? (
            <Box>
              {errors.map((item, idx) => (
                <Alert sx={{ p: 0, px: 1 }} severity="error" key={idx}>
                  {item}
                </Alert>
              ))}
            </Box>
          ) : null} */}

        <Box>
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </Box>
      </Stack>
    </form>
  );
};

export default BlanksQuestionForm;
