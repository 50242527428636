import { Box, Chip, Stack, Typography } from "@mui/material";

export default function DifficultyLevelFilters({ state, setState }: any) {
  const getColor = (level: string): any => {
    switch (level) {
      case "EASY":
      case "Easy":
        return "success";
      case "MEDIUM":
      case "Medium":
        return "warning";
      case "HARD":
      case "Hard":
        return "error";
      default:
        return "primary";
    }
  };

  return (
    <Box sx={{ marginLeft: "50px" }}>
      <Typography gutterBottom variant="subtitle1">
        Difficulty Level
      </Typography>
      <Stack gap={1} direction="row">
        {["EASY", "MEDIUM", "HARD"].map((item) => (
          <Chip
            size="small"
            key={item}
            color={getColor(item)}
            label={item}
            clickable
            variant={
              state?.difficultyLevel === item ? "filled" : "outlined"
            }
            onClick={() => {
              setState((prevState: any) => ({
                ...prevState,
                difficultyLevel: item,
              }));
            }}
          />
        ))}
      </Stack>
    </Box>
  );
}
