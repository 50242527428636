import { Box } from '@mui/material'
import React from 'react'
import Header from './appBar/Header'
import NewLayout from './SettingsSidebar/NewLayout'
import { Outlet } from 'react-router-dom'

const SettingsLayout = () => {
  return (
    <Box>
      <Header />
      <NewLayout />
      <Box p={2} mt={10} marginLeft="285px" marginRight="8px">
        <Outlet />
      </Box>
    </Box>
  )
}

export default SettingsLayout
