import {
  Audiotrack,
  ImageOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  OndemandVideo,
} from "@mui/icons-material";
import { Box, Button, FormControl, FormLabel, Stack } from "@mui/material";
import { useState } from "react";
import { EditImage, ImageUpload } from "../../../components/ImageUpload";
import { TextField } from "../../../components/forms/TextField";
import FileUpload from "./FileUpload";
// import { FileUpload } from "src/components/FileUpload";
// import { TextField } from "src/components/forms/TextField";
// import { EditImage, ImageUpload } from "src/components/ImageUpload";

const MediaFields = ({ control, watch, setValue }: any) => {
  const [show, setShow] = useState(false);

  const handleFileUpload = (value: any) => {
    if (value?.mimeType.startsWith("audio")) {
      setValue("audioKey", value?.key);
    }
    if (value?.mimeType.startsWith("image")) {
      setValue("imageKey", value?.key);
    }
  };

  return (
    <>
      <Box>
        <Button
          onClick={() => setShow((prev) => !prev)}
          size="small"
          endIcon={!show ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        >
          Media Files
        </Button>
      </Box>
      {show && (
        <Stack gap={2}>
          <FormControl>
            <FormLabel sx={{ mb: 1 }}>Audio File</FormLabel>
            {watch("audioKey") ? (
              <Box>
                <audio controls>
                  <source
                    src={`${process.env.REACT_APP_FILE_URL}/${watch(
                      "audioKey"
                    )}`}
                    type="audio/mp3"
                  />
                </audio>
              </Box>
            ) : (
              <FileUpload
                accept="audio/*"
                url={`${process.env.REACT_APP_API_URL}/s3-resources/file-upload`}
                onChange={handleFileUpload}
                type="QUESTION_BANK"
                subtype="DOCS"
              />
            )}
          </FormControl>
          <FormControl>
            <FormLabel sx={{ mb: 1 }}>Image File</FormLabel>
            {watch("imageKey") ? (
              <EditImage
                height="180px"
                imageUrl={`${process.env.REACT_APP_FILE_URL}/${watch(
                  "imageKey"
                )}`}
                onChange={handleFileUpload}
                uploadUrl={`${process.env.REACT_APP_API_URL}/s3-resources/file-upload`}
                type="QUESTION_BANK"
                subtype="DOCS"
              />
            ) : (
              <ImageUpload
                url={`${process.env.REACT_APP_API_URL}/s3-resources/file-upload`}
                onChange={handleFileUpload}
                type="QUESTION_BANK"
                subtype={"DOCS"}
              />
            )}
          </FormControl>
          <FormControl>
            <FormLabel sx={{ mb: 1 }}>Video</FormLabel>
            {/* {watch("videoLink") ? (
              <Box
                sx={{
                  "& iframe": {
                    height: "180px !important",
                    width: "auto !important",
                  },
                }}
                dangerouslySetInnerHTML={{
                  __html: watch("videoLink"),
                }}
              ></Box>
            ) : ( */}
            <TextField
              muiProps={{
                multiline: true,
                minRows: 5,
              }}
              label="Paste Vimeo video iframe tag"
              name="videoLink"
              control={control}
            />
            {/* )} */}
          </FormControl>
        </Stack>
      )}
    </>
  );
};
export default MediaFields;

const menu = [
  {
    key: "audio",
    title: "Audio File",
    icon: <Audiotrack fontSize="small" />,
  },
  {
    key: "video",
    title: "Video File",
    icon: <OndemandVideo fontSize="small" />,
  },
  {
    key: "image",
    title: "Image File",
    icon: <ImageOutlined fontSize="small" />,
  },
];
