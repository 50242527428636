import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
// import Quill from "src/components/Quill";
// import TagsField from "src/components/TagsField";
// import { TextField } from "src/components/forms/TextField";
// import ComponentGroup from "src/content/question-bank/components/ComponentGroup";
// import DifficultyLevelField from "src/content/question-bank/components/DifficultyLevelField";
// import LanguagesField from "src/content/question-bank/components/LanguagesField";
// import MediaFields from "src/content/question-bank/components/MediaFields";
// import questionBankStore from "src/store/questionBank";
// import { QuestionTypeKey } from "src/types";
// import http from "src/utils/http";

import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { http } from "../../../../api/http";
import Quill from "../../../../components/Quill";
import { TextField } from "../../../../components/forms/TextField";
import ComponentGroup from "../../components/ComponentGroup";
import DifficultyLevelField from "../../components/DifficultyLevelField";
import LanguagesField from "../../components/LanguagesField";
import MediaFields from "../../components/MediaFields";
import questionBankStore from "../../store/questionBank";
import { QuestionTypeKey } from "../service";
import { passageStore } from "./PassageQuestionForm";

const validationSchema = Yup.object().shape({
  question: Yup.string().max(255).required("The title field is required"),
  marks: Yup.number()
    .moreThan(0, "Marks should be greater than 0")
    .required("This field is required")
    .nullable(),
  negativeMarks: Yup.number().required("This field is required").nullable(),
});

const TrueFalseQuestionForm = ({ data, close, passage }: any) => {
  const params = useParams();
  const queryClient = useQueryClient();
  const initialState = {
    question: data ? data.question : "",
    correctAnswers: data ? data.correctAnswers : [],
    marks: data ? data.marks : null,
    negativeMarks: data ? data.negativeMarks : null,
    difficultyLevel: data ? data.difficultyLevel : "",
    explanation: data ? data.explanation : "",
    language: data?.language ?? "",
    hint: data ? data.hint : "",
    tags: data ? data.tags : [],
    videoLink: data?.videoLink ?? "",
    audioFile:
      data?.files?.find((item: any) => item?.mimeType.startsWith("audio")) ??
      "",
    imageFile: data?.files?.find((item: any) => item.subType === "image") ?? "",
  };

  const { handleSubmit, control, watch, setValue } = useForm({
    defaultValues: initialState,
    // resolver: yupResolver(validationSchema),
  });

  const store = questionBankStore.useState((s: any) => s);
  const passageState = passageStore.useState((s: any) => s);

  const onSubmit = async (formData: any, e: any) => {
    const postBody = {
      ...formData,
      questionBankId: params?.questionBankId,
      questionType: QuestionTypeKey.TRUE_FALSE,
      fileIds: [formData?.audioFile?.id, formData?.imageFile?.id].filter(
        (item) => !!item
      ),
      tags: formData?.tags.map((item: any) => item.name),
    };

    if (data?.passageId) {
      try {
        const res = await http.put(`/questions/${data?.id}`, {
          ...postBody,
        });
        passageStore.update((s: any) => {
          s.questions.filter((item: any) => item.id !== data?.id);
          s.questions.push(res);
        });
        close();
        queryClient.invalidateQueries([
          "passage-questions",
          params?.questionBankId,
        ]);
        return;
      } catch (err: any) {
        console.log(err);
        toast.error(err?.data?.response?.message ?? "Error Occurred");
        return;
      }
    }

    if (passage) {
      passageStore.update((s: any) => {
        s.questions.push({
          questionId: uuidv4(),
          passageId: passageState?.passageDetails?.id,
          ...postBody,
        });
      });
      close();
      return;
    }

    try {
      if (data) {
        await http.put(`/questions/${data?.id}`, postBody);
      } else {
        await http.post("/questions", postBody);
      }
      queryClient.invalidateQueries("questions");
      close();
    } catch (err) {
      console.log(err);
    }
  };
  const onError = (errors: any, e: any) => {
    // setErrors(Object.values(errors).map((item: any) => item.message));
  };

  const correctAnswers = watch("correctAnswers");

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Stack gap={2}>
          <ComponentGroup title="Question">
            <Quill
              placeholder="Question"
              data={watch("question")}
              handleChange={(value: any) => setValue("question", value)}
            />
            <MediaFields watch={watch} setValue={setValue} control={control} />
          </ComponentGroup>

          <ComponentGroup title="Options">
            <FormControl component="fieldset">
              <FormLabel component="legend">Answer</FormLabel>
              <RadioGroup row>
                <FormControlLabel
                  control={
                    <Radio
                      name="correctAnswers"
                      value="true"
                      onChange={(e) =>
                        setValue("correctAnswers", [e.target.value])
                      }
                      checked={correctAnswers.includes("true")}
                      required
                    />
                  }
                  label="True"
                />
                <FormControlLabel
                  control={
                    <Radio
                      name="correctAnswers"
                      value="false"
                      onChange={(e) =>
                        setValue("correctAnswers", [e.target.value])
                      }
                      checked={correctAnswers.includes("false")}
                      required
                    />
                  }
                  label="False"
                />
              </RadioGroup>
            </FormControl>
          </ComponentGroup>

          <ComponentGroup title="Tags">
            {/* <TagsField
              initialValues={watch("tags")}
              onChange={(value) => setValue("tags", value)}
            /> */}
            <LanguagesField name="language" control={control} />
            <DifficultyLevelField name="difficultyLevel" control={control} />
          </ComponentGroup>

          <ComponentGroup title="Marks">
            <TextField
              muiProps={{
                type: "number",
              }}
              label="Marks"
              control={control}
              name="marks"
            />
            <TextField
              muiProps={{
                type: "number",
              }}
              label="Negative marks"
              control={control}
              name="negativeMarks"
            />
          </ComponentGroup>
          <ComponentGroup title="Explanation">
            <Quill
              placeholder="Explanation"
              data={watch("explanation")}
              handleChange={(value: any) => setValue("explanation", value)}
            />
          </ComponentGroup>
          {/* {errors.length ? (
            <Box>
              {errors.map((item, idx) => (
                <Alert sx={{ p: 0, px: 1 }} severity="error" key={idx}>
                  {item}
                </Alert>
              ))}
            </Box>
          ) : null} */}

          <Box>
            <Button variant="contained" type="submit">
              Submit
            </Button>
          </Box>
        </Stack>
      </form>
    </Box>
  );
};

export default TrueFalseQuestionForm;
