export enum ContentType {
  text = "html",
  video = "video",
  image = "image",
  resource = "resource",
  quiz = "quiz",
  ebook = "eBook",
  link = "link",
  visual = "visual",
}

export enum QuestionTypeKey {
  MCQ_SINGLE = "MCQSingleCorrect",
  MCQ_MULTIPLE = "MCQMultipleCorrect",
  TRUE_FALSE = "TrueOrFalse",
  BLANKS_SINGLE = "Blanks",
  BLANKS_MULTIPLE = "MultipleBlanks",
  PASSAGE = "Passage",
  SUBJECTIVE = "DescriptiveType",
}

export enum QuestionType {
  MCQSingleCorrect = "Multiple Choice (Single Correct)",
  MCQMultipleCorrect = "Multiple Choice (Multiple Correct)",
  Blanks = "Blanks (Single Blank)",
  MultipleBlanks = "Blanks (Multiple Blanks)",
  TrueOrFalse = "True or False",
  Passage = "Passage",
  DescriptiveType = "Subjective",
}

export enum Languages {
  en = "English",
  tel = "Telugu",
  urd = "Urdu",
  hin = "Hindi",
}
