import { Download } from "@mui/icons-material";
import CloudUploadTwoTone from "@mui/icons-material/CloudUploadTwoTone";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  IconButton,
  styled,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { Loader } from "../loader";

const Input = styled("input")({
  display: "none",
});
const AvatarWrapper = styled(Box)(
  ({ theme }) => `

    position: relative;

    .MuiAvatar-root {
      width: ${theme.spacing(16)};
      height: ${theme.spacing(16)};
    }
`
);

const ButtonUploadWrapper = styled(Box)(
  ({ theme }) => `
    position: absolute;
    width: ${theme.spacing(6)};
    height: ${theme.spacing(6)};
    bottom: -${theme.spacing(2)};
    right: -${theme.spacing(2)};

    .MuiIconButton-root {
      border-radius: 100%;
      background: ${theme.palette.primary.main};
      color: ${theme.palette.primary.contrastText};
      width: ${theme.spacing(6)};
      height: ${theme.spacing(6)};
      padding: 0;
  
      &:hover {
        background: ${theme.palette.primary.dark};
      }
    }
`
);

const EditFileUpload = ({
  mediaObject,
  uploadUrl,
  onChange,
  type,
  subtype,
  accept,
}: any) => {
  const [loading, setLoading] = useState<boolean>(false);


  const handleUpload = (e: any) => {
    if (e.target.files[0]) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("type", type);
      formData.append("subType", subtype);
      setLoading(true);
      axios
        .post(uploadUrl, formData)
        .then((res) => {
          onChange(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };
  return (
    <Box display="inline-flex" flexDirection="column" pb={2}>
      <AvatarWrapper>
        <Card
          variant="outlined"
          sx={{ boxShadow: "none", display: "flex", mt: 1 }}
        >
          <CardHeader
            title={mediaObject?.originalFileName}
            sx={{ flexGrow: 1 }}
          />
          <CardActions>
            <a
              href={mediaObject?.fileUrl}
              style={{ textDecoration: "none" }}
              download
              target="_blank"
              rel="noreferrer"
            >
              <Button variant="outlined" startIcon={<Download />} size="small">
                Download
              </Button>
            </a>
          </CardActions>
        </Card>
        <ButtonUploadWrapper>
          <Input
            onChange={handleUpload}
            id="icon-button-file"
            name="icon-button-file"
            type="file"
            accept={accept||"*"}
          />
          {loading ? (
            <Loader />
          ) : (
            <label htmlFor="icon-button-file">
              <IconButton component="span" color="primary">
                <CloudUploadTwoTone />
              </IconButton>
            </label>
          )}
        </ButtonUploadWrapper>
      </AvatarWrapper>
    </Box>
  );
};

export default EditFileUpload;
