import exp from "constants";
import { http } from "../http";

export const logIn = (body: any) => {
  return http.post("/auth/login", body);
};

export const getAllSubjects = ({ queryKey }: any) => {
  return http.get("/subjects", {
    params: {
      ...queryKey[1],
    },
  });
};

export const createSubject = (body: any) => {
  return http.post("/subjects", body);
};

export const updateSubject = ({ id, body }: any) => {
  return http.patch(`/subjects/${id}`, body);
};

export const getOneSubject = ({ queryKey }: any) => {
  return http.get(`/subjects/${queryKey[1]}`);
};

export const deleteSubject = (id: any) => {
  return http.delete(`/subjects/${id}`);
};

export const createChapter = (body: any) => {
  return http.post("/subjects/chapters", body);
};

export const createYear = (body: any) => {
  return http.post("/years", body);
};

export const updateYear = ({ id, body }: any) => {
  return http.patch(`/years/${id}`, body);
};

export const deleteYear = (id: any) => {
  return http.delete(`/years/${id}`);
};

export const updateChapter = ({ id, body }: any) => {
  return http.patch(`/subjects/chapters/${id}`, body);
};

export const createSections = (body: any) => {
  return http.post("/subjects/sections", body);
};

export const updateSections = ({ id, body }: any) => {
  return http.patch(`/subjects/sections/${id}`, body);
};

export const createTopic = (body: any) => {
  return http.post("/subjects/topics", body);
};

export const updateTopic = ({ id, body }: any) => {
  return http.patch(`/subjects/topics/${id}`, body);
};

export const getAllDistrics = ({ queryKey }: any) => {
  return http.get(`/districts`, {
    params: {
      ...queryKey[1],
    },
  });
};

export const createDistrict = (body: any) => {
  return http.post(`/districts`, body);
};

export const getYears = ({ queryKey }: any) => {
  return http
    .get("/years", {
      params: {
        ...queryKey[1],
      },
    })
    .then((res) => res.data);
};

//exam timetable
export const createExamTimeTable = (body: any) => {
  return http.post("/exam-timetable", body);
};

export const updateExamTimetable = ({ id, body }: any) => {
  return http.patch(`/exam-timetable/${id}`, body);
};

export const getAllExamTimetable = ({ queryKey }: any) => {
  return http.get("/exam-timetable", {
    params: {
      ...queryKey[1],
    },
  });
};

export const deleteExamTimeTable = (id: any) => {
  return http.delete(`/exam-timetable/${id}`);
};

// Noticeboard
export const createNoticeboard = (body: any) => {
  return http.post("/notice-board", body);
};

export const deleteNoticeboard = (id: any) => {
  return http.delete(`/notice-board/${id}`);
};

export const updateNoticeboard = ({ id, body }: any) => {
  return http.patch(`/notice-board/${id}`, body);
};

export const getOneNoticeboard = ({ queryKey }: any) => {
  return http.get(`/notice-board/${queryKey[1]}`);
};

export const getNoticeboard = ({ queryKey }: any) => {
  return http.get("/notice-board", {
    params: {
      ...queryKey[1],
    },
  });
};

//Doubts

export const getDoubts = ({ queryKey }: any) => {
  return http.get("/doubts", {
    params: {
      ...queryKey[1],
    },
  });
};

export const updateDoubt = ({ id, body }: any) => {
  return http.patch(`/doubts/${id}`, body);
};

// trade time table
export const createTradesTimeTable = (body: any) => {
  return http.post("/time-table", body);
};

export const deleteTradesTimeTable = (id: any) => {
  return http.delete(`/time-table/${id}`);
};

export const createExamination = (body: any) => {
  return http.post("/examination-guidelines", body);
};

//Live Session

export const createLiveSessions = (body: any) => {
  return http.post("/live-session", body);
};
export const updateLiveSessions = ({ id, body }: any) => {
  return http.patch(`/live-session/${id}`, body);
};
export const deleteLiveSession = (id: any) => {
  return http.delete(`/live-session/${id}`);
};
export const getLiveSessions = ({ queryKey }: any) => {
  return http.get("/live-session", {
    params: { ...queryKey[1] },
  });
};

//discussion forum
export const createDiscussionForum = (body: any) => {
  return http.post("/discussion-forum", body);
};

export const updateDiscussionForum = ({ id, body }: any) => {
  return http.patch(`/discussion-forum/${id}`, body);
};

export const deleteDiscussionForum = (id: any) => {
  return http.delete(`/discussion-forum/${id}`);
};

export const getDiscussionForum = ({ queryKey }: any) => {
  return http.get("/discussion-forum", {
    params: { ...queryKey[1] },
  });
};

export const getTimeTables = (body: any) => {
  return http.get("/time-table").then((res) => res.data);
};

export const getExaminationGuidelines = (queryKey: any) => {
  return http
    .get("/examination-guidelines", {
      params: {
        ...queryKey,
      },
    })
    .then((res) => res.data);
};

export const updateDistrict = ({ id, body }: any) => {
  return http.patch(`/districts/${id}`, body);
};

export const deleteDistrict = (id: any) => {
  return http.delete(`/districts/${id}`);
};

export const importDistrict = (data: any) => {
  return http.post(`/districts/import`, data);
};

export const getAllCenterType = ({ queryKey }: any) => {
  return http.get(`/centre-type`, {
    params: {
      ...queryKey[1],
    },
  });
};
export const getTimeTable = (id: any) => {
  return http.get(`/time-table/${id}`).then((res) => res.data);
};

export const getExams = () => {
  return http.get(`/exams`).then((res) => res.data);
};

export const getAllExams = ({ queryKey }: any) => {
  return http.get(`/exams`, {
    params: {
      ...queryKey[1],
    },
  });
};

export const getExamById = ({ queryKey }: any) => {
  return http.get(`/exams/${queryKey[1]}`);
};

export const createExam = (body: any) => {
  return http.post(`/exams`, body);
};

export const updateExam = ({ id, body }: any) => {
  return http.patch(`/exams/${id}`, body);
};

export const publishExam = ({ id, body }: any) => {
  console.log("Body", body);
  return http.put(`/exams/${id}/publish`, body);
};

export const createCenterType = (body: any) => {
  return http.post(`/centre-type`, body);
};
export const updateLessonse = async ({ id, postbody }: any) => {
  return await http.patch(`/time-table/${id}`, postbody);
};
export const updateExamGuidLines = async ({ id, postBody }: any) => {
  return await http.patch(`/examination-guidelines/${id}`, postBody);
};

export const updateCenterType = ({ id, body }: any) => {
  return http.patch(`/centre-type/${id}`, body);
};

export const getAllItiCenters = ({ queryKey }: any) => {
  return http.get(`/iti-centres`, {
    params: {
      ...queryKey[1],
    },
  });
};

export const createItiCenters = (body: any) => {
  return http.post(`/iti-centres`, body);
};

export const updateItiCenters = ({ id, body }: any) => {
  return http.patch(`/iti-centres/${id}`, body);
};

export const DeleteItiCenters = (id: any) => {
  return http.delete(`/iti-centres/${id}`);
};

export const DeleteItiCenterstype = (id: any) => {
  return http.delete(`/centre-type/${id}`);
};

export const getTrades = ({ queryKey }: any) => {
  return http.get(`/trades`, {
    params: {
      ...queryKey[1],
    },
  });
};

// Trade apis

export const getAllTrades = () => {
  return http.get("/trades").then((res) => res.data);
};

export const getOneTrade = ({ queryKey }: any) => {
  return http.get(`/trades/${queryKey[1]}`).then((res) => res.data);
};

export const createTrades = (body: any) => {
  return http.post(`/trades`, body);
};

export const DeleteTrade = (id: any) => {
  return http.delete(`/trades/${id}`);
};

export const updateTradesfee = ({ tradeId, id, body }: any) => {
  return http.patch(`/trades/${tradeId}/fee/${id}`, body);
};

export const updateTradeshours = ({ tradeId, id, body }: any) => {
  return http.patch(`/trades/${tradeId}/hours/${id}`, body);
};

export const updateTrades = ({ id, body }: any) => {
  return http.patch(`/trades/${id}`, body);
};

// Roles
export const createRoles = (body: any) => {
  return http.post(`/roles`, body);
};

export const getRoles = ({ queryKey }: any) => {
  return http.get(`/roles`, {
    params: {
      ...queryKey[1],
    },
  });
};

export const updateRoles = ({ id, body }: any) => {
  return http.patch(`/roles/${id}`, body);
};

export const DeleteRoles = (id: any) => {
  return http.delete(`/roles/${id}`);
};

export const getUsers = ({ queryKey }: any) => {
  return http.get(`/users`, {
    params: {
      ...queryKey[1],
    },
  });
};

export const createUser = (body: any) => {
  return http.post(`/users`, body);
};

export const updateUser = ({ id, body }: any) => {
  return http.patch(`/users/${id}`, body);
};

export const deleteUser = (id: any) => {
  return http.delete(`/users/${id}`);
};

export const getAllCourses = ({ queryKey }: any) => {
  return http.get(`/courses`, {
    params: {
      ...queryKey[1],
    },
  });
};

export const createCourse = (body: any) => {
  return http.post(`/courses`, body);
};

export const updateCourse = ({ id, body }: any) => {
  return http.patch(`/courses/${id}`, body);
};

export const updateCourseExams = ({ id, body }: any) => {
  return http.patch(`/courses/${id}/exams`, body);
};

export const DeleteCourseExams = ({ id, examId }: any) => {
  return http.delete(`/courses/${id}/exams/${examId}`);
};

export const updateCourseEbooks = ({ id, body }: any) => {
  return http.patch(`/courses/${id}/e-books`, body);
};

export const DeleteCourseEbooks = ({ id, eBookId }: any) => {
  return http.delete(`/courses/${id}/e-books/${eBookId}`);
};

export const getCourseById = ({ queryKey }: any) => {
  return http.get(`/courses/${queryKey[1]}`);
};

export const getAllLanguages = ({ queryKey }: any) => {
  return http.get(`/languages`, {
    params: {
      ...queryKey[1],
    },
  });
};

export const getTopicById = ({ queryKey }: any) => {
  return http.get(`/subjects/chapters/topics/${queryKey[1]}`);
};

export const postComponents = (body: any) => {
  return http.post(`/components`, body);
};

export const updateComponents = ({ id, body }: any) => {
  return http.patch(`/components/${id}`, body);
};

export const sortchapters = ({ id, body }: any) => {
  return http.patch(`subjects/sort-chapters/${id}`, body);
};

export const sortComponents = ({ body }: any) => {
  return http.patch(`components/sort-components`, body);
};

export const DeleteComponents = (id: any) => {
  return http.delete(`/components/${id}`);
};

export const DeleteTopic = (id: any) => {
  return http.delete(`/subjects/topic/${id}`);
};

export const postQuiz = (body: any) => {
  return http.post(`/quiz`, body);
};

export const updateQuiz = ({ id, body }: any) => {
  return http.patch(`/quiz/${id}`, body);
};

export const deleteQuiz = (id: any) => {
  return http.delete(`/quiz/${id}`);
};

export const deleteExam = (id: any) => {
  return http.delete(`/exams/${id}`);
};

export const deleteQuestionBanks = (id: any) => {
  return http.delete(`/question-bank/${id}`);
};

export const getQuizById = ({ queryKey }: any) => {
  return http.get(`/quiz/${queryKey[1]}`);
};

export const getQuiz = ({ queryKey }: any) => {
  console.log("Query key", queryKey[1]);
  return http.get(`/quiz`, {
    params: {
      ...queryKey[1],
    },
  });
};

export const updateQuestionBankInQuiz = ({ id, body }: any) => {
  return http.patch(`/quiz/${id}/question-bank`, body);
};

export const updateCurriculum = ({ id, body }: any) => {
  return http.patch(`/courses/${id}/subjects`, body);
};

export const deleteCourses = (id: any) => {
  return http.delete(`/courses/${id}`);
};

export const createCourseBundles = (body: any) => {
  return http.post(`/course-bundles`, body);
};

export const getCourseBundles = ({ queryKey }: any) => {
  return http.get(`/course-bundles`, {
    params: {
      ...queryKey[1],
    },
  });
};

export const updateCourseBundles = ({ id, body }: any) => {
  return http.patch(`/course-bundles/${id}`, body);
};

export const DeleteCourseBundlesexam = ({ id, examId }: any) => {
  return http.delete(`course-bundles/${id}/exams/${examId}`);
};

export const updateCourseBundlesexam = ({ id, body }: any) => {
  return http.patch(`course-bundles/${id}/exams`, body);
};

export const deleteCourseBundles = (id: any) => {
  return http.delete(`/course-bundles/${id}`);
};

export const getCourseBundlesById = ({ queryKey }: any) => {
  return http.get(`/course-bundles/${queryKey[1]}`);
};

export const updateCourseBundlesCourses = ({ id, body }: any) => {
  return http.patch(`/course-bundles/${id}/courses`, body);
};

export const removeCourseBundleCourses = ({ id, courseId }: any) => {
  return http.delete(`/course-bundles/${id}/courses/${courseId}`);
};

export const postQuestions = (body: any) => {
  return http.post(`/question-bank/questions`, body);
};

export const updateQuestions = ({ id, body }: any) => {
  return http.patch(`/question-bank/questions/${id}`, body);
};

export const getQuestionsById = ({ queryKey }: any) => {
  return http.get(`/question-bank/${queryKey[1]}`);
};

export const getAllBundletypes = ({ queryKey }: any) => {
  return http.get(`/bundle-types`, {
    params: {
      ...queryKey[1],
    },
  });
};

export const getBundlesByTrade = ({ queryKey }: any) => {
  return http.get(`/trades/bundle-types/${queryKey[1]}`);
};

export const getAllYears = ({ queryKey }: any) => {
  return http.get(`/years`, {
    params: {
      ...queryKey[1],
    },
  });
};

export const getBundleTypes = ({ queryKey }: any) => {
  return http.get(`/bundle-types`, {
    params: {
      ...queryKey[1],
    },
  });
};

export const createBundleTypes = (body: any) => {
  return http.post(`/bundle-types`, body);
};

export const updateBundleTypes = ({ id, body }: any) => {
  return http.patch(`/bundle-types/${id}`, body);
};

export const sortBundleTypes = (body: any) => {
  return http.patch(`/bundle-types/sort-bundleTypes`, body);
};

export const DeleteBundleTypes = (id: any) => {
  return http.delete(`/bundle-types/${id}`);
};

export const updateQuestionsInQuiz = ({ id, questionBankId, body }: any) => {
  return http.patch(`/quiz/${id}/question-bank/${questionBankId}`, body);
};

export const deleteQuestionsInQuiz = ({ id, questionBankId }: any) => {
  return http.delete(`/quiz/${id}/question-bank/${questionBankId}`);
};

export const createExamParts = (body: any) => {
  return http.post(`/exams/parts`, body);
};

export const updateExamParts = ({ id, body }: any) => {
  return http.patch(`/exams/parts/${id}`, body);
};

export const deleteExamParts = ({ id, partId }: any) => {
  return http.delete(`/exams/${id}/parts/${partId}`);
};

export const updateQuestionInExams = ({ id, partId, body }: any) => {
  return http.patch(`/exams/${id}/parts/${partId}`, body);
};

export const deleteQuestionInExams = ({ id, partId, questionBankId }: any) => {
  return http.delete(
    `/exams/${id}/parts/${partId}/question-banks/${questionBankId}`
  );
};

export const updateQuestionsInExam = ({
  id,
  partId,
  questionBankId,
  body,
}: any) => {
  return http.patch(
    `/exams/${id}/parts/${partId}/question-banks/${questionBankId}`,
    body
  );
};

export const getEbooks = ({ queryKey }: any) => {
  return http.get(`/e-books`, {
    params: {
      ...queryKey[1],
    },
  });
};

export const getEbooksById = ({ queryKey }: any) => {
  return http.get(`/e-books/${queryKey[1]}`);
};

export const createEbooks = (body: any) => {
  return http.post(`/e-books`, body);
};

export const updateEbooks = ({ id, body }: any) => {
  return http.patch(`/e-books/${id}`, body);
};

export const deleteEbooks = (id: any) => {
  return http.delete(`/e-books/${id}`);
};

//students
export const getAllStudents = ({ queryKey }: any) => {
  console.log("Query function", queryKey[1]);
  return http.get("/students", {
    params: {
      ...queryKey[1],
    },
  });
};

export const createStudent = (body: any) => {
  return http.post(`/students`, body);
};

export const getStudent = ({ queryKey }: any) => {
  return http.get(`/students/${queryKey[1]}`);
};

export const updateStudentsStatus = (body: any) => {
  return http.patch(`/students/status`, body);
};
