import { Close } from "@mui/icons-material";
import {
  Box,
  Breakpoint,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  Typography,
} from "@mui/material";
import { ReactNode } from "react";

interface IDialogProps {
  open: boolean;
  setOpen: any;
  children: ReactNode;
  title?: string;
  width?: Breakpoint;
}

const DialogWrapper = ({
  open,
  setOpen,
  children,
  title = "",
  width = "sm",
}: IDialogProps) => {
  return (
    <DialogContainer
      open={open}
      maxWidth={width}
      fullWidth
      onClose={() => setOpen(false)}>
      <DialogTitle sx={{ p: 1, px: 2 }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
          <IconButton disableRipple onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ py: 1 }}>{children}</Box>
      </DialogContent>    
    </DialogContainer>
  );
};

export default DialogWrapper;

const DialogContainer = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);
