import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import Header from "./appBar/Header";
import SideBar from "./sidebar";

const Layout: any = () => {
  return (
    <Box>
      <Header />
      <SideBar />
      <Box p={2} mt={10} marginLeft="285px" marginRight="8px">
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
