import { Chip } from "@mui/material";

export default function DifficultyLevelChip({
  level,
}: {
  level: "EASY" | "MEDIUM" | "HARD" | "easy" | "hard" | "medium";
}) {
  const color: any = {
    EASY: "success",
    MEDIUM: "warning",
    HARD: "error",
    easy: "success",
    medium: "warning",
    hard: "error",
  };

  if (!level) return null;

  return (
    <Chip variant="filled" size="small" label={level} color={color[level]} />
  );
}
