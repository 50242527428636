import { Store } from "pullstate";
import { http } from "../../../api/http";

type QuestionBankStore = {
  questions: any;
  page?: number;
  size?: number;
  text?: string;
  selectedTags?: any[];
  loading: boolean;
  fetchQuestions: (params: IFetchQuestions) => any;
};

type IFetchQuestions = {
  tags?: string;
  page?: number;
  size?: number;
  text?: string;
  type?: string;
};

const questionBankStore = new Store<QuestionBankStore>({
  loading: false,
  questions: [],
  fetchQuestions: async ({
    page = 0,
    size = 10,
    text,
    tags,
    type,
  }: IFetchQuestions) => {
    questionBankStore.update((s) => {
      s.loading = true;
    });
    try {
      const questions = await http.get("/question-banks", {
        params: { page, size, text, tags, type },
      });

      questionBankStore.update((s) => {
        s.questions = questions.data;
        s.loading = false;
      });
    } catch (error) {
      console.log(error);
      questionBankStore.update((s) => {
        s.loading = false;
      });
    }
  },
});

export default questionBankStore;
