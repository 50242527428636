import { Box } from "@mui/material";
import { SidePanel } from "./sidePanel";
import Topic from "./topic";

const CurriculumBuilder = () => {
  return (
    <Box sx={{ display: "flex", gap: "1rem" }}>
      <Box sx={{ width: "400px" }}>
        <SidePanel />
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Topic />
      </Box>
    </Box>
  );
};

export default CurriculumBuilder;
