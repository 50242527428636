import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { toast } from "react-toastify";
import * as Yup from "yup";
import { getAllLanguages, postQuiz, updateQuiz } from "../../api/services";
import Quill from "../../components/Quill";
import SingleSelect from "../../components/forms/SingleSelect";
import { TextField } from "../../components/forms/TextField";

// function formatDate(date: any) {
//   const d = new Date(date);
//   return format(
//     addMinutes(d, d.getTimezoneOffset()),
//     'yyyy-MM-dd HH:mm:ss',
//   ).replace(' ', 'T');
// }

const validationSchema = Yup.object().shape({
  title: Yup.string().required("The title field is required"),
});

export default function QuizForm({ closeModal, data }: any) {
  const queryClient = useQueryClient();

  const { data: languageData } = useQuery("languages", getAllLanguages);

  const languages = languageData?.data?.data?.map((item: any) => ({
    value: item?._id,
    label: item?.name,
  }));

  const { mutate: createMutate } = useMutation(postQuiz, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("quiz-details");
      toast.success("Quiz created successfully");
      closeModal();
    },
    onError: (err: any) => {
      toast.error(err.response.data.message);
    },
  });

  const { mutate: updateMutate } = useMutation(updateQuiz, {
    onSuccess: (res: any) => {
      toast.success("Quiz updated successfully");
      queryClient.invalidateQueries("quiz");
      closeModal();
    },
    onError: (err: any) => {
      toast.error(err.response.data.message);
    },
  });

  const { handleSubmit, control, setValue, watch } = useForm({
    defaultValues: {
      name: data?.name ?? "",
      difficultyLevel: data?.difficultyLevel ?? "",
      maxQuestions: data?.maxQuestions ?? "",
      maxMarks: data?.maxMarks ?? "",
      passPercentage: data?.passPercentage ?? "",
      maxAttempts: data?.maxAttempts ?? "",
      isGraded: data?.isGraded ?? false,
      isTimeRestricted: data?.isTimeRestricted ?? false,
      duration: data?.duration ?? "",
      instructions: data?.instructions ?? "",
      description: data?.description ?? "",
      languageId: data?.languageId ?? "",
      isPreRequisite: data?.isPreRequisite ?? false,
    },
  });

  const onSubmit = (formData: any) => {
    if (data?._id) {
      updateMutate({ id: data?._id, body: formData });
    } else {
      createMutate(formData);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2}>
        <TextField name="name" label="Title" control={control} />
        <Box>
          <FormControl component="fieldset">
            <FormLabel component="legend">Difficulty level</FormLabel>
            <Controller
              control={control}
              name="difficultyLevel"
              render={({ field }) => (
                <RadioGroup row {...field}>
                  <FormControlLabel
                    value="EASY"
                    control={<Radio />}
                    label="Easy"
                  />
                  <FormControlLabel
                    value="MEDIUM"
                    control={<Radio />}
                    label="Medium"
                  />
                  <FormControlLabel
                    value="HARD"
                    control={<Radio />}
                    label="Hard"
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
        </Box>
        <SingleSelect
          name="languageId"
          control={control}
          label="Language"
          options={languages}
        />

        <TextField
          name="maxQuestions"
          muiProps={{ type: "number" }}
          label="Number of Questions"
          control={control}
        />
        <TextField
          name="maxMarks"
          muiProps={{ type: "number" }}
          label="Maximum Marks"
          control={control}
        />
        <TextField
          muiProps={{ type: "number" }}
          name="maxAttempts"
          label="Maximum Attempts"
          control={control}
        />
        <TextField
          muiProps={{ type: "number" }}
          name="passPercentage"
          label="Pass Percentage"
          control={control}
        />
        <FormControlLabel
          control={
            <Controller
              name="isGraded"
              control={control}
              render={({ field }) => (
                <Checkbox {...field} checked={watch("isGraded")} />
              )}
            />
          }
          label="Is Graded"
        />
        <FormControlLabel
          control={
            <Controller
              name="isTimeRestricted"
              control={control}
              render={({ field }) => (
                <Checkbox {...field} checked={watch("isTimeRestricted")} />
              )}
            />
          }
          label="Is Time Restricted"
        />
        {watch("isTimeRestricted") && (
          <TextField
            muiProps={{ type: "number" }}
            name="duration"
            label="Duration (in minutes)"
            control={control}
          />
        )}
        <FormControlLabel
          control={
            <Controller
              name="isPreRequisite"
              control={control}
              render={({ field }) => (
                <Checkbox {...field} checked={watch("isPreRequisite")} />
              )}
            />
          }
          label="Prerequisite"
        />
        {/* <FormControlLabel
          control={
            <Controller
              name="timedTest"
              control={control}
              render={({ field }) => (
                <Checkbox {...field} checked={watch('timedTest')} />
              )}
            />
          }
          label="Is Scheduled Test"
        />
        {watch('timedTest') && (
          <Stack direction="row" gap={2}>
            <DateTimePicker
              label="Start Date Time"
              value={watch('startsAt')}
              onChange={(value) => setValue('startsAt', value)}
              renderInput={(params) => (
                <MuiTextField
                  fullWidth
                  required={watch('timedTest')}
                  {...params}
                />
              )}
            />
            <DateTimePicker
              label="End Date Time"
              value={watch('endsAt')}
              onChange={(value) => setValue('endsAt', value)}
              renderInput={(params) => (
                <MuiTextField
                  fullWidth
                  required={watch('timedTest')}
                  {...params}
                />
              )}
            />
          </Stack>
        )} */}

        <FormControl>
          <FormLabel>Instructions</FormLabel>
          <Quill
            placeholder="Instructions"
            data={watch("instructions")}
            handleChange={(value) => setValue("instructions", value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Description</FormLabel>
          <Quill
            placeholder="Description"
            data={watch("description")}
            handleChange={(value) => setValue("description", value)}
          />
        </FormControl>

        <Box>
          <Button type="submit" variant="contained">
            Submit
          </Button>
        </Box>
      </Stack>
    </form>
  );
}
