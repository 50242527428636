import { Box } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useImmer } from "use-immer";
import { getTopicById, sortComponents } from "../../../../api/services";
import { emptyDashboard } from "../../../../assets";
import EmptyPage from "../../../../components/EmptyPage";
import Scrollbar from "../../../../components/Scrollbar";
import AddContentButton from "../content/AddContent";
import ContentViewCard from "../content/ContentViewCard";
import { useEffect, useState } from "react";
import { Reorder } from "framer-motion";
import { toast } from "react-toastify";

const Topic = () => {
  const queryClient = useQueryClient();
  const [search] = useSearchParams();
  const navigate = useNavigate(); // Use navigate for programmatic navigation

  const sParams = {
    chapterId: search.get("chapterId"),
    topicId: search.get("topicId"),
  };

  const [state, setState] = useImmer({
    components: [],
  });

  const { data, isLoading } = useQuery(
    ["topic-id", sParams?.topicId],
    getTopicById,
    {
      enabled: !!sParams?.topicId,
    }
  );

  const { mutate: mutateSortComponents } = useMutation(sortComponents, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("topic-id");
      toast.success(res?.data?.message);
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const [items, setItems] = useState([]);

  useEffect(() => {
    if (data && data.data) {
      setItems(data.data);
    }
  }, [data]);

  // Call sortComponents only when items are reordered (empty dependency array)
  useEffect(() => {
    // Implement debounce or throttle here (optional)
    mutateSortComponents({ body: [] }); // Pass empty body initially
  }, []);

  const handleReorder = (newItems: any) => {
    if (JSON.stringify(newItems) !== JSON.stringify(items)) {
      // Check for actual change
      setItems(newItems);
      const sortcomponentarray = newItems.map((item: any) => item?._id);
      const body = { ids: sortcomponentarray };
      mutateSortComponents({ body: body });
    }
  };

  const location = useLocation();
  const isCurriculumsPage = location.pathname.includes("curriculums");

  return (
    <Box sx={{ p: 0, height: "calc(100vh - 120px)", overflowY: "auto" }}>
      <Scrollbar>
        {items && items.length > 0 ? (
          <Reorder.Group axis="y" values={items} onReorder={handleReorder}>
            {items.map((item: any, index: number) => (
              <Reorder.Item
                style={{
                  listStyle: "none",
                }}
                key={item._id}
                value={item}>
                <ContentViewCard data={item} />
              </Reorder.Item>
            ))}
          </Reorder.Group>
        ) : (
          <EmptyPage image={emptyDashboard} title="No content" />
        )}
        {isCurriculumsPage && <AddContentButton components={data?.data} />}
      </Scrollbar>
    </Box>
  );
};

export default Topic;
