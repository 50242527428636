import { Box } from "@mui/material";
import "../styles/loader.css";

export const Loader = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box className="loader" />
    </Box>
  );
};
