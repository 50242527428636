// import 'katex/dist/katex.min.css';
import { useMemo, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.snow.css";

import katex from "katex";

// import 'katex/dist/katex.min.css';
window.katex = katex;

type QuillProps = {
  data: string;
  handleChange: (value: any) => void;
  placeholder?: string;
  minHeight?: string;
};

const Quill = ({
  data,
  handleChange,
  placeholder,
  minHeight = "200px",
}: QuillProps) => {
  const ref: any = useRef();

  const modules = useMemo(
    () => ({
      toolbar: {
        clipboard: {
          matchVisual: false,
        },
        container: [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ align: [] }],
          [{ color: [] }],
          ["clean"],
          ["formula", "code"],
        ],
      },
    }),
    []
  );

  return (
    <ReactQuill
      placeholder={placeholder}
      ref={ref}
      theme={"snow"}
      style={{ zIndex: 9999, minHeight }}
      value={data}
      modules={modules}
      onChange={(content: any) => {
        handleChange(content);
      }}
    />
  );
};

export default Quill;
