import {
  AccessTimeTwoTone,
  DeleteTwoTone,
  EditTwoTone,
} from "@mui/icons-material";
import MoreVert from "@mui/icons-material/MoreVert";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import moment from "moment";
import DialogWrapper from "../../../components/DialogWrapper/DialogWrapper";
import DifficultyLevelChip from "../../../components/DifficultyLevelChip";
import LanguageChip from "../../../components/LanguageChip";
import { LabelContainer } from "../../question-bank/QuestionBankCard";
import QuizForm from "../QuizForm";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "react-query";
import { deleteQuiz } from "../../../api/services";

const ExamCard = ({
  data,
  cardAction,
  showOptions = true,
  isTable = false,
}: any) => {
  const queryClient = useQueryClient();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [openModal, setOpenModal] = useState<boolean>(false);


  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`${data.id}`);
  };

  const { mutate: mutatedeleteQuiz } = useMutation(deleteQuiz, {
    onSuccess: (res: any) => {
      toast.success(res?.data?.message);
      queryClient.invalidateQueries("quiz");
    },
    onError: (err: any) => {
      toast.error(err.response.data.message);
    },
  });
  const handleToggle = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  return (
    <>
      {isTable ? (
        <>
          <IconButton sx={{ marginLeft: 1 }} onClick={handleToggle}>
            <MoreVert />
          </IconButton>

          <DialogWrapper
            width="lg"
            title="Edit Quiz"
            open={openModal}
            setOpen={setOpenModal}>
            <QuizForm data={data} closeModal={() => setOpenModal(false)} />
          </DialogWrapper>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}>
            {cardAction}
            <MenuItem onClick={handleOpenModal}>
              <ListItemIcon>
                <EditTwoTone color="primary" fontSize="small" />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
            {/* <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteTwoTone color="error" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem> */}
            <MenuItem
              onClick={() => {
                mutatedeleteQuiz(data?._id);
              }}>
              <ListItemIcon>
                <DeleteTwoTone color="primary" />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </Menu>
        </>
      ) : (
        <Card>
          <Stack height={"100%"}>
            <MyCardHeader
              title={data?.name}
              action={
                <Box>
                  {data?.difficultyLevel && (
                    <DifficultyLevelChip level={data?.difficultyLevel} />
                  )}
                  {showOptions && (
                    <IconButton sx={{ marginLeft: 1 }} onClick={handleToggle}>
                      <MoreVert />
                    </IconButton>
                  )}
                </Box>
              }
            />
            <CardContent
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}>
              <Stack direction="row" gap={2} alignItems={"center"}>
                {data?.duration && (
                  <Box
                    display={"inline-flex"}
                    gap={1}
                    sx={{
                      backgroundColor: theme.palette.primary.light,
                      px: 1,
                      py: 0.5,
                      borderRadius: "5px",
                    }}>
                    <AccessTimeTwoTone fontSize="small" color="primary" />
                    <Typography
                      variant="body2"
                      sx={{ color: theme.palette.primary.main }}>
                      {data?.duration}
                    </Typography>
                  </Box>
                )}
                {data?.language?.name && (
                  <LanguageChip language={data?.language?.name} />
                )}
              </Stack>
              <LabelContainer
                data={[
                  {
                    label: "Questions",
                    value: data?.maxQuestions,
                  },
                  {
                    label: "Attempts",
                    value: data?.maxAttempts,
                  },
                  {
                    label: "Marks",
                    value: data?.maxMarks,
                  },
                  {
                    label: "Pass %",
                    value: data?.passPercentage,
                  },
                ]}
              />
            </CardContent>
            <CardActions
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}>
              <Typography variant="subtitle1" fontSize={13}>
                {moment(data?.createdAt).format("MMM DD yyyy")}
              </Typography>
              {cardAction}
            </CardActions>
          </Stack>

          <DialogWrapper
            width="lg"
            title="Edit Quiz"
            open={openModal}
            setOpen={setOpenModal}>
            <QuizForm data={data} closeModal={() => setOpenModal(false)} />
          </DialogWrapper>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}>
            <MenuItem onClick={handleOpenModal}>
              <ListItemIcon>
                <EditTwoTone color="primary" fontSize="small" />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
            {/* <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteTwoTone color="error" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem> */}
            <MenuItem
              onClick={() => {
                mutatedeleteQuiz(data?._id);
              }}>
              <ListItemIcon>
                <DeleteTwoTone color="primary" />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </Menu>
        </Card>
      )}
    </>
  );
};

export default ExamCard;

const LabelCard = ({ value, label }: any) => {
  return (
    <Stack gap={1} alignItems={"center"} textAlign={"center"}>
      <Typography fontSize={12} variant="subtitle2">
        {label}
      </Typography>
      <Typography variant="h5">{value || ""}</Typography>
    </Stack>
  );
};

const MyCardHeader = styled(CardHeader)(({ theme }) => ({
  "& .MuiCardHeader-title": {
    fontSize: "18px",
  },
}));
