import { Box, styled, Tab, Tabs } from "@mui/material";
import { ChangeEvent, useState } from "react";
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

const TabsContainerWrapper = styled(Box)(
  ({ theme }) => `
    padding-bottom: 0;
  `
);

const CourseView = () => {
  const [currentTab, setCurrentTab] = useState("about-course");
  const [search] = useSearchParams();
  const { id } = useParams();

  const navigate = useNavigate();
  const handleTabsChange = (_event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
    navigate(`/courses/all-courses/${id}/${value}`);
  };
  return (
    <>
      <TabsContainerWrapper>
        <Tabs
          onChange={handleTabsChange}
          value={currentTab}
          variant="scrollable"
          scrollButtons="auto"
          textColor="primary"
          indicatorColor="primary"
        >
          {tabs.map((tab) => (
            <Tab key={tab.to} label={tab.label} value={tab.to} />
          ))}
        </Tabs>
      </TabsContainerWrapper>
      <Box
        sx={{
          mt: 1,
          overflowY: "auto",
          height: "80vh",
        }}
      >
        <Outlet />
      </Box>
    </>
  );
};

export default CourseView;

const tabs = [
  { to: "about-course", label: "About Course" },
  { to: "configuration", label: "Configuration" },
  { to: "exams", label: "Exams" },
  { to: "e-books", label: "E Books" },
];
