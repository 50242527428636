import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, Button, Divider, Menu, MenuItem, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

// import QuestionForm from ".";

import DialogWrapper from "../../../components/DialogWrapper/DialogWrapper";
import QuestionForm from "./QuestionForm";
import { QuestionType, QuestionTypeKey } from "./service";
interface YourDataType {
  questionType?: QuestionType;
  // Other properties...
}

export default function AddQuestionButton({ data, passage }: any) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useSearchParams();
  const open = Boolean(anchorEl);

  const [questionType, setQuestionType] = useState({
    type: QuestionTypeKey.MCQ_SINGLE,
    label: QuestionType.MCQSingleCorrect,
  });

  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    // setQuestionType({
    //   type: data?.questionType,
    //   label: QuestionType[data?.questionType],
    // });
    if (data?.questionType) {
      const label =
        QuestionType[data.questionType as keyof typeof QuestionType];
      setQuestionType({
        type: data.questionType,
        label: label || "Unknown Question Type", // Provide a default if necessary
      });
    }
  }, [data]);

  const handleToggle = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelectMenuItem = (item: any) => {
    setQuestionType({
      type: item.type,
      label: item.label,
    });
    setOpenModal(true);
  };

  return (
    <Box>
      <Button
        size={passage ? "small" : "medium"}
        endIcon={<KeyboardArrowDown />}
        variant={"contained"}
        onClick={handleToggle}
      >
        Add Question
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        onClick={handleClose}
      >
        <RenderMenuItems
          handleSelectMenuItem={handleSelectMenuItem}
          passage={passage}
        />
      </Menu>

      <DialogWrapper
        width="lg"
        title={`Add ${questionType.label} Question`}
        open={openModal}
        setOpen={setOpenModal}
      >
        <QuestionForm
          questionType={questionType.type}
          closeModal={() => setOpenModal(false)}
          data={null}
          passage={passage}
        />
      </DialogWrapper>
    </Box>
  );
}

const RenderMenuItems = ({ passage, handleSelectMenuItem }: any) => {
  const [search] = useSearchParams();
  if (passage) {
    return (
      <Stack divider={<Divider />}>
        {Object.entries(QuestionType).map(([key, label], index) => (
          <MenuItem
            key={index}
            style={{ minWidth: "200px" }}
            onClick={() =>
              handleSelectMenuItem({
                type: key as QuestionTypeKey,
                label: label,
              })
            }
          >
            {label}
          </MenuItem>
        ))}
      </Stack>
    );
  } else {
    const qnType = search.get("qnType");

    return (
      <Stack divider={<Divider />}>
        {[
          "MCQSingleCorrect",
          // "MCQMultipleCorrect",
          // "TrueOrFalse",
          // "Blanks",
          // "MultipleBlanks",
          // "Passage",
          // "DescriptiveType",
        ].map((item, index) => (
          <MenuItem
            key={index}
            style={{ minWidth: "200px" }}
            onClick={() =>
              handleSelectMenuItem({
                type: item,
                label: item,
              })
            }
          >
            {item}
          </MenuItem>
        ))}
      </Stack>
    );
  }
};
