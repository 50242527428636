import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Controller } from "react-hook-form";

export default function DifficultyLevelField({ control, name }: any) {
  return (
    <Box>
      <FormControl component="fieldset">
        <FormLabel component="legend">Difficulty Level</FormLabel>
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <RadioGroup row {...field}>
              <FormControlLabel value="EASY" control={<Radio />} label="Easy" />
              <FormControlLabel
                value="MEDIUM"
                control={<Radio />}
                label="Medium"
              />
              <FormControlLabel value="HARD" control={<Radio />} label="Hard" />
            </RadioGroup>
          )}
        />
      </FormControl>
    </Box>
  );
}
