import { Box, Button, FormControl, FormLabel, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";

import * as Yup from "yup";
import { TextField } from "../../components/forms/TextField";
import { EditImage, ImageUpload } from "../../components/ImageUpload";
import Quill from "../../components/Quill";
import { useImmer } from "use-immer";
import { createEbooks, updateEbooks } from "../../api/services";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  title: Yup.string().max(255).required("The title field is required"),
  link: Yup.string().required("The code field is required"),
});

export default function EBookForm({ data, close }: any) {
  const queryClient = useQueryClient();

  const [state, setState] = useImmer({
    mediaObject: data?.fileKey ?? null,
    description: data?.description ?? "",
  });

  const { mutate } = useMutation(createEbooks, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("e-books");
      toast.success(res?.data?.message);
      close();
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { mutate: updateMutate } = useMutation(updateEbooks, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("e-books");
      toast.success(res?.data?.message);
      close();
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { handleSubmit, control } = useForm({
    defaultValues: {
      title: data?.title ?? "",
      link: data?.link ?? "",
    },
  });

  const onSubmit = (formData: any) => {
    console.log(formData);
    const postBody = {
      ...formData,
      file: state?.mediaObject?.key || data?.fileKey,
      description: state?.description,
    };
    if (data?._id) {
      updateMutate({ id: data?._id, body: postBody });
    } else {
      mutate(postBody);
    }
    console.log(postBody);
  };

  const handleImageUpload = (value: any) => {
    if (!value) return;

    setState((s) => {
      s.mediaObject = value;
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2}>
        <TextField name="title" label="E-Book Title" control={control} />

        <Box>
          {state?.mediaObject ? (
            <EditImage
              imageUrl={state.mediaObject?.s3Response?.url || data?.fileUrl}
              onChange={handleImageUpload}
              uploadUrl={`${process.env.REACT_APP_API_URL}/s3-resources/file-upload`}
              type="TRADE"
              subtype="DOCS"
            />
          ) : (
            <ImageUpload
              url={`${process.env.REACT_APP_API_URL}/s3-resources/file-upload`}
              onChange={handleImageUpload}
              type="TRADE"
              subtype="DOCS"
            />
          )}
        </Box>

        <TextField name="link" label="E-Book Link" control={control} />

        <FormControl>
          <FormLabel>Description</FormLabel>

          <Quill
            placeholder="Description"
            data={state?.description}
            handleChange={(value) => {
              setState((s) => {
                s.description = value;
              });
            }}
          />
        </FormControl>

        <Box>
          <Button type="submit" variant="contained">
            Submit
          </Button>
        </Box>
      </Stack>
    </form>
  );
}
