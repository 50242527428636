import { Box, Stack, Typography, useTheme } from "@mui/material";

export default function ComponentGroup({ children, title }: any) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.primary.light}`,
        borderRadius: "6px",
      }}
    >
      <Box
        sx={{
          p: 1,
          background: theme.palette.primary.light,
          color: theme.palette.primary.dark,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography>{title}</Typography>
      </Box>
      <Stack sx={{ px: 2, py: 1, pb: 2 }} gap={2}>
        {children}
      </Stack>
    </Box>
  );
}
