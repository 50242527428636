//images
import emptyDashboard from "./images/EmptyDashboard.png";
import loginCover from "./images/loginCover.png";
import logo from "./images/logo.png";

//icons
import bundletypes from "./icons/Bundle Types.png";
import Coursebundles from "./icons/Course Bundles.png";
import Courses from "./icons/Courses.png";
import Discussionfomr from "./icons/Discussion Forum.png";
import District from "./icons/Districts.png";
import ebooks from "./icons/E-Books.png";
import Examinationguidelines from "./icons/Examination Guidelines.png";
import Exams from "./icons/Exams.png";
import iticentertype from "./icons/ITI Centre Types.png";
import ItiCenter from "./icons/ITI Centres.png";
import {
  default as PracticalCenters,
  default as livesession,
} from "./icons/Live Sessions.png";
import masterdata from "./icons/Master Data.png";
import Questionbank from "./icons/Question Bank.png";
import Quiz from "./icons/Quiz.png";
import roles from "./icons/Roles.png";
import studentscorner from "./icons/Students Corner.png";
import Tradetimetable from "./icons/Trade Time Table.png";
import Trade from "./icons/Trades.png";
import years from "./icons/Years.png";
import appBarNotification from "./icons/appBarNotification.png";
import appBarSettings from "./icons/appBarSettings.png";
import book from "./icons/book.png";
import curriculums from "./icons/curriculums.svg";
import commonIcon from "./icons/sideNavIcon.png";
import user from "./icons/users.png";
import importIcon from "./icons/import.png";
import exportIcon from "./icons/export.png";
import Trash from "./icons/trash.png";

export { emptyDashboard, loginCover, logo };

export const icons = {
  commonIcon,
  appBarNotification,
  appBarSettings,
  book,
  Courses,
  Coursebundles,
  Questionbank,
  Exams,
  Quiz,
  ebooks,
  Tradetimetable,
  Examinationguidelines,
  PracticalCenters,
  Discussionfomr,
  masterdata,
  bundletypes,
  District,
  iticentertype,
  Trade,
  ItiCenter,
  roles,
  user,
  years,
  studentscorner,
  livesession,
  curriculums,
  importIcon,
  exportIcon,
  Trash,
};
