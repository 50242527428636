import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Box,
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { icons } from "../../../assets";
import { StyledSideNav } from "../../../styles";

const SideBar = () => {
  const theme = useTheme();
  const location = useLocation();
  const [open, setOpen] = useState<boolean>(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [openQuiz, setOpenQuiz] = useState(false);
  const [openconfig, setopenconfig] = useState(false);
  const [masterMenu, SetmasterMenu] = useState(false);
  const [Academics, SetAcademics] = useState(false);
  const [Userroles, setUserroles] = useState(false);
  const Configuration = [
    {
      title: "Districts",
      path: "/master-data/districts",
      icon: icons.District,
    },
    {
      title: "Years",
      path: "/master-data/year",
      icon: icons.masterdata,
    },
    {
      title: "ITI Centre Type",
      path: "/master-data/iti-centre-type",
      icon: icons.iticentertype,
    },
    {
      title: "ITI Centres",
      path: "/master-data/iti-centres",
      icon: icons.ItiCenter,
    },
    {
      title: "Trade",
      path: "/master-data/trade",
      icon: icons.Trade,
    },
    {
      title: "Bundle-Types",
      path: "/master-data/bundle-types",
      icon: icons.bundletypes,
    },
    {
      title: "Roles",
      path: "/master-data/roles",
      icon: icons.roles,
    },
    {
      title: "Users",
      path: "/master-data/users",
      icon: icons.user,
    },
  ];

  const sideNavList = [
    {
      title: "Curriculums",
      path: "/curriculums",
      icon: icons.curriculums,
    },

    {
      title: "Question Bank",
      path: "/question-bank",
      icon: icons.Questionbank,
    },
    {
      title: "E-Books",
      path: "/e-books",
      icon: icons.ebooks,
    },
    {
      title: "Students",
      path: "/Students",
      icon: icons.user,
    },
    {
      title: "E-Book Bundle",
      path: "/configuration",
      icon: icons.commonIcon,
    },
    {
      title: "Settings",
      path: "/configuration",
      icon: icons.commonIcon,
    },
  ];

  const toggleCourses = [
    {
      title: "All Courses",
      path: "/courses/all-courses",
      icon: icons.Courses,
    },
    {
      title: "Courses Bundles",
      path: "/courses/course-bundles",
      icon: icons.Coursebundles,
    },
  ];

  const toggleQuiz = [
    {
      title: "Exams",
      path: "/app/exams",
      icon: icons.Exams,
    },
    {
      title: "Quiz",
      path: "/app/quiz",
      icon: icons.Quiz,
    },
  ];

  useEffect(() => {
    if (location.pathname.startsWith("/master-data")) {
      setOpen(true);
      setOpen2(false);
      setOpen3(false);
      setOpenQuiz(false);
    } else if (location.pathname.startsWith("/courses")) {
      setOpen2(true);
      setOpen(false);
      setOpen3(false);
      setOpenQuiz(false);
    } else if (location.pathname.startsWith("/app")) {
      setOpenQuiz(true);
      setOpen(false);
      setOpen2(false);
      setOpen3(false);
    } else if (location.pathname.startsWith("/students-corner")) {
      setOpen3(true);
      setOpen(false);
      setOpen2(false);
      setOpenQuiz(false);
    } else {
      setOpen(false);
      setOpen2(false);
      setOpenQuiz(false);
      setOpen3(false);
    }
  }, [location.pathname]);

  const StudentsCornerList = [
    {
      title: "Trade Time Table",
      path: "/students-corner/trade-time-table",
      icon: icons.Tradetimetable,
    },
    {
      title: "Examination Guideline",
      path: "/students-corner/examination-guideline",
      icon: icons.Examinationguidelines,
    },
    {
      title: "Live Session",
      path: "/students-corner/live-session",
      icon: icons.livesession,
    },
    {
      title: "Discussion Forum ",
      path: "/students-corner/discussion-forum",
      icon: icons.Discussionfomr,
    },
  ];

  return (
    <StyledSideNav>
      <List sx={{ overflow: "auto", maxHeight: "90vh" }}>

        {sideNavList?.slice(3, 4).map((item: any, index: any) => (
            <Link key={index} to={item.path} style={{ textDecoration: "none" }}>
              <ListItem disablePadding>
                <ListItemButton
                  selected={location.pathname.startsWith(item.path)}
                  sx={{
                    "&.Mui-selected": {
                      opacity: "1",
                    },
                    "&.Mui-selected:hover": {},
                  }}>
                  <ListItemIcon>
                    <Box
                      sx={{
                        width: "100%",
                        textAlign: "center",
                      }}>
                      <img src={item.icon} alt="" width={20} height={20} />
                    </Box>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        color={
                          location.pathname.startsWith(item.path)
                            ? "#F4347F"
                            : "#000"
                        }
                        variant="h5">
                        {item?.title}
                      </Typography>
                    }
                  />
                </ListItemButton>
                <Box
                  sx={{
                    height: "45px",
                    width: "5px",
                    borderRadius: "10px 0 0 10px",
                    bgcolor: location.pathname.startsWith(item?.path)
                      ? theme.palette.primary.main
                      : "#fff",
                  }}
                />
              </ListItem>
            </Link>
          ))}

        {sideNavList?.slice(0, 1).map((item: any, index: any) => (
          <Link key={index} to={item.path} style={{ textDecoration: "none" }}>
            <ListItem disablePadding>
              <ListItemButton
                selected={location.pathname.startsWith(item.path)}
                sx={{
                  "&.Mui-selected": {
                    opacity: "1",
                  },
                  "&.Mui-selected:hover": {},
                }}>
                <ListItemIcon>
                  <Box
                    sx={{
                      width: "100%",
                      textAlign: "center",
                    }}>
                    <img src={item.icon} alt="" width={20} height={20} />
                  </Box>
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      color={
                        location.pathname.startsWith(item.path)
                          ? "#F4347F"
                          : "#000"
                      }
                      variant="h5">
                      {item?.title}
                    </Typography>
                  }
                />
              </ListItemButton>
              <Box
                sx={{
                  height: "45px",
                  width: "5px",
                  borderRadius: "10px 0 0 10px",
                  bgcolor: location.pathname.startsWith(item?.path)
                    ? theme.palette.primary.main
                    : "#fff",
                }}
              />
            </ListItem>
          </Link>
        ))}

        <ListItemButton
          selected={location.pathname.startsWith("/courses")}
          onClick={() => setOpen2(!open2)}>
          <ListItemIcon>
            <Box
              sx={{
                width: "100%",
                textAlign: "center",
              }}>
              <img src={icons.Courses} alt="" width={20} height={20} />
            </Box>
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                color={
                  location.pathname.startsWith("/courses") ? "#F4347F" : "#000"
                }
                variant="h5">
                Courses
              </Typography>
            }
          />
          {open2 ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open2} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {toggleCourses?.map((item: any, index: any) => (
              <Link
                key={index}
                to={item.path}
                style={{ textDecoration: "none" }}>
                <ListItem disablePadding>
                  <ListItemButton
                    selected={location.pathname.startsWith(item.path)}
                    sx={{
                      "&.Mui-selected": {
                        opacity: "1",
                      },
                      "&.Mui-selected:hover": {},

                      paddingLeft: "30px",
                    }}>
                    <ListItemIcon>
                      <Box
                        sx={{
                          width: "100%",
                          textAlign: "center",
                        }}>
                        <img src={item.icon} alt="" width={20} height={20} />
                      </Box>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          color={
                            location.pathname.startsWith(item.path)
                              ? "#F4347F"
                              : "#000"
                          }
                          variant="h5">
                          {item?.title}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                  <Box
                    sx={{
                      height: "45px",
                      width: "5px",
                      borderRadius: "10px 0 0 10px",
                      bgcolor: location.pathname.startsWith(item?.path)
                        ? theme.palette.primary.main
                        : "#fff",
                    }}
                  />
                </ListItem>
              </Link>
            ))}
          </List>
        </Collapse>

        {sideNavList?.slice(1, 2).map((item: any, index: any) => (
          <Link key={index} to={item.path} style={{ textDecoration: "none" }}>
            <ListItem disablePadding>
              <ListItemButton
                selected={location.pathname.startsWith(item.path)}
                sx={{
                  "&.Mui-selected": {
                    opacity: "1",
                  },
                  "&.Mui-selected:hover": {},
                }}>
                <ListItemIcon>
                  <Box
                    sx={{
                      width: "100%",
                      textAlign: "center",
                    }}>
                    <img src={item.icon} alt="" width={20} height={20} />
                  </Box>
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      color={
                        location.pathname.startsWith(item.path)
                          ? "#F4347F"
                          : "#000"
                      }
                      variant="h5">
                      {item?.title}
                    </Typography>
                  }
                />
              </ListItemButton>
              <Box
                sx={{
                  height: "45px",
                  width: "5px",
                  borderRadius: "10px 0 0 10px",
                  bgcolor: location.pathname.startsWith(item?.path)
                    ? theme.palette.primary.main
                    : "#fff",
                }}
              />
            </ListItem>
          </Link>
        ))}

        <ListItemButton
          selected={location.pathname.startsWith("/quiz")}
          onClick={() => setOpenQuiz(!openQuiz)}>
          <ListItemIcon>
            <Box
              sx={{
                width: "100%",
                textAlign: "center",
              }}>
              <img src={icons.Exams} alt="" width={20} height={20} />
            </Box>
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                color={
                  location.pathname.startsWith("/app") ? "#F4347F" : "#000"
                }
                variant="h5">
                Exams/Quiz
              </Typography>
            }
          />
          {openQuiz ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openQuiz} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {toggleQuiz?.map((item: any, index: any) => (
              <Link
                key={index}
                to={item.path}
                style={{ textDecoration: "none" }}>
                <ListItem disablePadding>
                  <ListItemButton
                    selected={location.pathname.startsWith(item.path)}
                    sx={{
                      "&.Mui-selected": {
                        opacity: "1",
                      },
                      "&.Mui-selected:hover": {},

                      paddingLeft: "30px",
                    }}>
                    <ListItemIcon>
                      <Box
                        sx={{
                          width: "100%",
                          textAlign: "center",
                        }}>
                        <img src={item.icon} alt="" width={20} height={20} />
                      </Box>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          color={
                            location.pathname.startsWith(item.path)
                              ? "#F4347F"
                              : "#000"
                          }
                          variant="h5">
                          {item?.title}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                  <Box
                    sx={{
                      height: "45px",
                      width: "5px",
                      borderRadius: "10px 0 0 10px",
                      bgcolor: location.pathname.startsWith(item?.path)
                        ? theme.palette.primary.main
                        : "#fff",
                    }}
                  />
                </ListItem>
              </Link>
            ))}
          </List>
        </Collapse>
        {sideNavList?.slice(2, 3).map((item: any, index: any) => (
          <Link key={index} to={item.path} style={{ textDecoration: "none" }}>
            <ListItem disablePadding>
              <ListItemButton
                selected={location.pathname.startsWith(item.path)}
                sx={{
                  "&.Mui-selected": {
                    opacity: "1",
                  },
                  "&.Mui-selected:hover": {},
                }}>
                <ListItemIcon>
                  <Box
                    sx={{
                      width: "100%",
                      textAlign: "center",
                    }}>
                    <img src={item.icon} alt="" width={20} height={20} />
                  </Box>
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      color={
                        location.pathname.startsWith(item.path)
                          ? "#F4347F"
                          : "#000"
                      }
                      variant="h5">
                      {item?.title}
                    </Typography>
                  }
                />
              </ListItemButton>
              <Box
                sx={{
                  height: "45px",
                  width: "5px",
                  borderRadius: "10px 0 0 10px",
                  bgcolor: location.pathname.startsWith(item?.path)
                    ? theme.palette.primary.main
                    : "#fff",
                }}
              />
            </ListItem>
          </Link>
        ))}

        

        <ListItemButton
          selected={location.pathname.startsWith("/students-corner")}
          onClick={() => setOpen3(!open3)}>
          <ListItemIcon>
            <Box
              sx={{
                width: "100%",
                textAlign: "center",
              }}>
              <img src={icons.studentscorner} alt="" width={20} height={20} />
            </Box>
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                color={
                  location.pathname.startsWith("/students-corner")
                    ? "#F4347F"
                    : "#000"
                }
                variant="h5">
                Students Corner
              </Typography>
            }
          />
          {open3 ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open3} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {StudentsCornerList?.map((item: any, index: any) => (
              <Link
                key={index}
                to={item.path}
                style={{ textDecoration: "none" }}>
                <ListItem disablePadding>
                  <ListItemButton
                    selected={location.pathname.startsWith(item.path)}
                    sx={{
                      "&.Mui-selected": {
                        opacity: "1",
                      },
                      "&.Mui-selected:hover": {},

                      paddingLeft: "30px",
                    }}>
                    <ListItemIcon>
                      <img src={item.icon} alt="" width={20} height={20} />
                      <Box
                        sx={{
                          width: "100%",
                          textAlign: "center",
                        }}></Box>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          color={
                            location.pathname.startsWith(item.path)
                              ? "#F4347F"
                              : "#000"
                          }
                          variant="h5">
                          {item?.title}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                  <Box
                    sx={{
                      height: "45px",
                      width: "5px",
                      borderRadius: "10px 0 0 10px",
                      bgcolor: location.pathname.startsWith(item?.path)
                        ? theme.palette.primary.main
                        : "#fff",
                    }}
                  />
                </ListItem>
              </Link>
            ))}
          </List>
        </Collapse>

      </List>  
    </StyledSideNav>
  );
};

export default SideBar;
