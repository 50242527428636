import AddTwoTone from "@mui/icons-material/AddTwoTone";
import Close from "@mui/icons-material/Close";
import {
  Avatar,
  Box,
  Button,
  Card,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  alpha,
  styled,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { Loader } from "../loader";

const CardAddAction = styled(Card)(
  ({ theme }) => `
        border: ${theme.palette.common.black[70]} dashed 1px;
        height: 100%;
        color: ${theme.palette.primary.main};
        transition: ${theme.transitions.create(["all"])};
        box-shadow: none; 
        padding: ${theme.spacing(2)};
          height: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
          cursor: pointer;
          
          .MuiTouchRipple-root {
            opacity: .2;
          }
          
          &:hover {
            border-color: ${theme.palette.primary.main};
            background-opacity: .2;
            background: ${alpha(theme.palette.primary.main, 0.1)};
          }

          & > * {
          cursor: pointer;
          }
`
);

const AvatarAddWrapper = styled(Avatar)(
  ({ theme }) => `
        background: ${theme.palette.common.black[70]};
        color: ${theme.palette.primary.main};
        width: ${theme.spacing(8)};
        height: ${theme.spacing(8)};

`
);

const FileUpload = ({
  url,
  onChange,
  accept = "*",
  onClick,
  type,
  subtype,
  onSuccess = () => {},
}: {
  url: string;
  onChange: (file: File) => void;
  accept?: string;
  onClick?: boolean;
  onSuccess?: () => void;
  type: string;
  subtype: string;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [state, setState] = useState<any>(null);
  const [fileName, setFileName] = useState("");
  const theme = useTheme();

  const handleUpload = (e: any) => {
    if (e.target.files[0]) {
      setFileName(e.target.files[0]?.name);
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("type", type);
      formData.append("subType", subtype);
      setState(formData);

      if (!onClick) {
        setLoading(true);
        axios
          .post(url, formData)
          .then((res) => {
            onChange(res.data);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            toast.error(err.response.data.message);
            console.log(err);
          });
      }
    }
  };

  const submit = async () => {
    setLoading(true);
    try {
      const res = await axios.post(url, state);
      onChange(res.data);
      setState(null);
      setFileName("");
      onSuccess();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <>
      {!fileName ? (
        <label htmlFor="file-upload">
          <CardAddAction>
            <input
              onChange={handleUpload}
              type="file"
              accept={accept}
              name="file-upload"
              id="file-upload"
              hidden
            />
            <AvatarAddWrapper>
              {!loading ? (
                <Tooltip arrow title={"Click to add image"} placement="right">
                  <AddTwoTone fontSize="large" />
                </Tooltip>
              ) : (
                <Loader />
              )}
            </AvatarAddWrapper>
          </CardAddAction>
        </label>
      ) : (
        <Box>
          {onClick && (
            <Stack gap={2}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  border: `1px solid ${theme.palette.primary.light}`,
                  borderRadius: "0.5rem",
                  p: 1,
                  px: 2,
                }}
              >
                <Typography variant="h5">{fileName}</Typography>
                <IconButton
                  color="error"
                  size="small"
                  onClick={() => {
                    setState(null);
                    setFileName("");
                  }}
                >
                  <Close />
                </IconButton>
              </Stack>
              <Box>
                <Button
                  disabled={loading}
                  endIcon={loading && <Loader />}
                  variant="contained"
                  onClick={submit}
                >
                  Submit
                </Button>
              </Box>
            </Stack>
          )}
        </Box>
      )}
    </>
  );
};

export default FileUpload;
