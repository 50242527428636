import { createTheme, ThemeOptions } from "@mui/material/styles";

export const theme: ThemeOptions = createTheme({
  palette: {
    primary: {
      main: "#F4347F",
      light: "rgb(244,52,127, 0.1)",
      dark: "rgb(244,52,127, 0.8)",
    },
    secondary: {
      main: "#000929",
      light: "rgb(247, 50, 30, 0.1)",
      dark: "rgb(247, 50, 30, 1)",
    },
    warning: {
      main: "#FF4F3D",
      light: "rgb(247, 50, 30, 0.1)",
      dark: "rgb(247, 50, 30, 1)",
    },
    info: {
      main: "#F2F2F2",
      light: "rgb(242, 242, 242, 0.1)",
      dark: "rgb(242, 242, 242, 1)",
    },
    background: {
      default: "#ffffff",
    },
    common: {
      black: "#000000",
      white: "#ffffff",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          fontFamily: "figtree_semibold",
          fontSize: "16px",
          borderRadius: "10px",
          height: "38px",
          padding: "0 20px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: "figtree_medium",
          [`& fieldset`]: {
            borderRadius: "10px",
          },
          ["& input::placeholder"]: {
            fontSize: "14px",
          },
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
            {
              display: "none",
            },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          fontFamily: "figtree_medium",
          color: "#2B2D22",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: "figtree_medium",
          color: "#2B2D22",
          opacity: "70%",
          fontSize: "14px",
        },
        shrink: ({ ownerState }) => ({
          ...(ownerState.shrink && {
            fontSize: "0.9rem !important",
            // top: "-1 !important",
          }),
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        formControl: {
          fontFamily: "figtree_medium",
          color: "#2B2D22",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: "figtree_medium",
          color: "#2B2D22",
          fontSize: "14px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
        elevation1: {
          boxShadow: "0px 2px 10px #0000001A",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "0px 5px 20px #0000001A",
          borderRadius: "16px",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: "0px 5px 20px #0000001A",
          borderRadius: "8px",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: "figtree_medium",
          color: "#2B2D22",
          fontSize: "13px",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontFamily: "figtree_medium",
          fontSize: "16px",
          color: "#2B2D22",
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        root: {
          fontFamily: "figtree_medium",
        },
        option: {
          fontFamily: "figtree_medium",
          fontSize: "16px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#2B2D22",
          fontFamily: "figtree_medium",
        },
        h1: {
          fontFamily: "figtree_bold",
          fontSize: "40px",
        },
        h2: {
          fontFamily: "figtree_bold",
          fontSize: "34px",
        },
        h3: {
          fontFamily: "figtree_bold",
          fontSize: "24px",
        },
        h4: {
          fontFamily: "figtree_semibold",
          fontSize: "20px",
        },
        h5: {
          fontFamily: "figtree_semibold",
          fontSize: "18px",
        },
        h6: {
          fontFamily: "figtree_semibold",
          fontSize: "16px",
        },
        subtitle1: {
          fontFamily: "figtree_medium",
          fontSize: "14px",
          opacity: "85%",
        },
        subtitle2: {
          fontFamily: "figtree_medium",
          fontSize: "16px",
        },
        body1: {
          fontFamily: "figtree_medium",
          fontSize: "16px",
        },
        body2: {
          fontFamily: "figtree_medium",
          opacity: "85%",
          fontSize: "13px",
        },
        caption: {
          fontFamily: "figtree_medium",
          fontSize: "12px",
          opacity: "75%",
        },
      },
    },
  },
});
