import { yupResolver } from "@hookform/resolvers/yup";
import { Add, Close } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  IconButton,
  TextField as MuiTextField,
  Stack,
} from "@mui/material";
import { useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  getAllSubjects,
  postQuestions,
  updateQuestions,
} from "../../../../api/services";
import Quill from "../../../../components/Quill";
import { TextField } from "../../../../components/forms/TextField";
import { Loader } from "../../../../components/loader";
import ComponentGroup from "../../components/ComponentGroup";
import DifficultyLevelField from "../../components/DifficultyLevelField";
import LanguagesField from "../../components/LanguagesField";
import MediaFields from "../../components/MediaFields";
import { QuestionTypeKey } from "../service";

const validationSchema = Yup.object().shape({
  question: Yup.string().max(255).required("The title field is required"),
  marks: Yup.number()
    .moreThan(0, "Marks should be greater than 0")
    .required("This field is required")
    .nullable(),
  negativeMarks: Yup.number().required("This field is required").nullable(),
});

const MCQQuestionForm = ({ data, close, passage }: any) => {
  const queryClient = useQueryClient();
  const params = useParams();
  const [search] = useSearchParams();
  const isMultiple =
    search.get("qnType") === QuestionTypeKey.MCQ_MULTIPLE ||
    data?.questionType === QuestionTypeKey.MCQ_MULTIPLE;

  const { data: subjectData, isLoading: subjectLoading } = useQuery(
    "subjects",
    getAllSubjects
  );

  const { mutate: questionMutate } = useMutation(postQuestions, {
    onSuccess: () => {
      toast.success("Question Added Successfully");
      queryClient.invalidateQueries("questions");
      close();
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message ?? "Error Occurred");
    },
  });

  const { mutate: updateQuestionsMutate } = useMutation(updateQuestions, {
    onSuccess: () => {
      toast.success("Question Updated Successfully");
      queryClient.invalidateQueries("questions");
      close();
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message ?? "Error Occurred");
    },
  });

  const initialState: any = {
    
    question: data ? data.question : "",
    options: data?.options ?? [
      { option: "", isCorrect: false },
      { option: "", isCorrect: false },
      { option: "", isCorrect: false },
      { option: "", isCorrect: false },
    ],
    marks: data ? data.marks : null,
    negativeMarks: data ? data.negativeMarks : null,
    difficultyLevel: data ? data.difficultyLevel : "",
    explanation: data ? data.explanation : "",
    // languageI d: data?.languageId ?? "",
    videoLink: data?.videoLink ?? "",
    audioKey: data?.audioKey ?? "",
    imageKey: data?.imageKey ?? "",
  };

  const { handleSubmit, control, setValue, watch, register, reset } = useForm({
    defaultValues: initialState,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    reset(initialState);
  }, [data]);

  const onSubmit = (formData: any, e: any) => {
    const correctOptions = formData.options
      .filter((item: any) => item.isCorrect)
      .map((item: any) => item.value);

    if (correctOptions.length === 0) {
      toast.warn("Select Atleast One Correct Option");
      return;
    }
    if (!isMultiple && correctOptions.length > 1) {
      toast.warn("Cannot Have Multiple Options");
      return;
    }
    if (isMultiple && correctOptions.length < 2) {
      toast.warn("Must Have Multiple Options");
      return;
    }
    const postBody = {
      ...formData,
      questionBankId: params?.id,
    };
    if (data?._id) {
      updateQuestionsMutate({
        id: data._id,
        body: postBody,
      });
    } else {
      questionMutate(postBody);
    }
  };

  if (subjectLoading) {
    return <Loader />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2}>
        <ComponentGroup title="Question">
          <Quill
            placeholder="Question"
            data={watch("question")}
            handleChange={(value) => setValue("question", value)}
          />
          <MediaFields watch={watch} setValue={setValue} control={control} />
        </ComponentGroup>

        <ComponentGroup title="Options">
          <Options
            register={register}
            watch={watch}
            control={control}
            setValue={setValue}
          />
        </ComponentGroup>

        <ComponentGroup title="Tags">
          {/* <LanguagesField name="languageId" control={control} /> */}
          <DifficultyLevelField name="difficultyLevel" control={control} />
        </ComponentGroup>

        <ComponentGroup title="Marks">
          <TextField
            muiProps={{
              type: "number",
            }}
            label="Marks"
            control={control}
            name="marks"
          />
          <TextField
            muiProps={{
              type: "number",
            }}
            label="Negative marks"
            control={control}
            name="negativeMarks"
          />
        </ComponentGroup>
        <ComponentGroup title="Explanation">
          <Quill
            placeholder="Explanation"
            data={watch("explanation")}
            handleChange={(value) => setValue("explanation", value)}
          />
        </ComponentGroup>
        <Box>
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </Box>
      </Stack>
    </form>
  );
};

export default MCQQuestionForm;

const Options = ({ register, watch, control, setValue }: any) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "options",
  });

  return (
    <Stack gap={2}>
      {fields?.map((item: any, idx) => (
        <Box
          key={item._id}
          sx={{
            position: "relative",
            "&:hover .close-btn": {
              display: "block",
            },
          }}
        >
          <FormLabel sx={{ mb: 1 }}>{`Option ${idx + 1}`}</FormLabel>
          <Quill
            minHeight="60px"
            data={watch(`options.${idx}.option`)}
            handleChange={(value) => setValue(`options.${idx}.option`, value)}
          />
          <Box
            sx={{
              position: "absolute",
              top: 20,
              right: 40,
            }}
          >
            <Controller
              render={({ field: { value, onChange } }) => {
                return (
                  <Checkbox
                    checked={value}
                    onChange={(e) =>
                      setValue(`options.${idx}.isCorrect`, e.target.checked)
                    }
                  />
                );
              }}
              name={`options.${idx}.isCorrect`}
              control={control}
            />
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: 15,
              right: 6,
              transform: "translateY(25%)",
              display: "none",
            }}
            className="close-btn"
          >
            <IconButton
              color="error"
              size="small"
              onClick={() => {
                if (fields.length <= 2) {
                  return alert("You need atleast two options");
                }
                remove(idx);
              }}
            >
              <Close />
            </IconButton>
          </Box>
        </Box>
      ))}
      <Box>
        <Button
          startIcon={<Add />}
          size="small"
          onClick={() => {
            if (watch("options").length >= 6) {
              return alert("You can only add 6 options");
            }
            append({ value: "" });
          }}
        >
          Add Option
        </Button>
      </Box>
    </Stack>
  );
};
