import { DeleteTwoTone, EditTwoTone } from "@mui/icons-material";
import { Box, Button, IconButton, Stack } from "@mui/material";
import { Store } from "pullstate";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
// import DialogWrapper from "src/components/DialogWrapper/DialogWrapper";
// import { NoDataComponent } from "src/components/Illustration/NoDataComponent";
// import Quill from "src/components/Quill";
// import { TextField } from "src/components/forms/TextField";
// import ComponentGroup from "src/content/question-bank/components/ComponentGroup";
// import MediaFields from "src/content/question-bank/components/MediaFields";
// import http from "src/utils/http";
import * as Yup from "yup";
// import QuestionCard from "../../QuestionView/QuestionCard";
import { http } from "../../../../api/http";
import DialogWrapper from "../../../../components/DialogWrapper/DialogWrapper";
import Quill from "../../../../components/Quill";
import { TextField } from "../../../../components/forms/TextField";
import QuestionCard from "../../QuestionView/QuestionCard";
import ComponentGroup from "../../components/ComponentGroup";
import MediaFields from "../../components/MediaFields";
import AddQuestionButton from "../AddQuestionButton";
import QuestionForm from "../QuestionForm";

export const passageStore = new Store({
  passageDetails: null,
  tags: [],
  questions: [],
});

const validationSchema2 = Yup.object().shape({
  passage: Yup.string().required("The title field is required"),
});
interface SomeType {
  // Define the properties of your selected question
  questionType: string;
  // ... other properties
}

const PassageQuestionForm = ({ data, close }: any) => {
  console.log(data);
  const params = useParams();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState<SomeType | null>(
    null
  );
  const passageState = passageStore.useState((s) => s);

  useEffect(() => {
    passageStore.update((s) => {
      s.questions = data?.questions || [];
      s.passageDetails = data;
    });
  }, [data]);
  const initialState2 = {
    id: data?.id ?? null,
    title: data?.title ?? "",
    passage: data?.passage ?? "",
    tags: data?.tags ?? [],
  };

  const {
    handleSubmit: handleSubmit2,
    control: passageControl,
    watch: passageWatch,
    setValue: setPassageValue,
  } = useForm({
    defaultValues: initialState2,
    // resolver: yupResolver(validationSchema2),
  });

  const onSubmit2 = async (formData2: any, e: any) => {
    const postBody = {
      ...formData2,
      createQuestionDtoList: passageState?.questions?.filter(
        (item: any) => !!item?.questionId
      ),
      questionBankId: params?.questionBankId,
    };
    try {
      await http.post("/questions/passage", { ...postBody, id: data?.id });
      queryClient.invalidateQueries([
        "passage-questions",
        params?.questionBankId,
      ]);
      close();
    } catch (err: any) {
      console.log(err);
      toast.error(err?.response?.data?.message ?? "Error Occurred");
    }
  };

  const onError2 = (errors: any, e: any) => {
    // setErrors(Object.values(errors).map((item: any) => item.message));
  };

  const handleDelete = async (item: any, idx: any) => {
    const confirm = window.confirm("Are you sure you want to delete?");
    if (!confirm) return;
    try {
      await http.delete(`/questions/${item.id}`);
      passageStore.update((s) => {
        s.questions.splice(idx, 1);
      });
    } catch (error) {}
  };

  return (
    <Box>
      <Stack gap={2}>
        <ComponentGroup title="Passage">
          <TextField
            label="Passage Title"
            control={passageControl}
            name="title"
          />
          <Quill
            placeholder="Passage"
            data={passageWatch("passage")}
            handleChange={(value: any) => setPassageValue("passage", value)}
          />
          <MediaFields
            watch={passageWatch}
            setValue={setPassageValue}
            control={passageControl}
          />
        </ComponentGroup>

        {/* <TextField
            muiProps={{
              type: 'number',
            }}
            label="Marks"
            control={control}
            name="marks"
          />
          <TextField
            muiProps={{
              type: 'number',
            }}
            label="Negative marks"
            control={control}
            name="negativeMarks"
          />
          <TextField
            muiProps={{
              multiline: true,
              minRows: 5,
            }}
            // minRows={5}
            label="Explanation"
            control={control}
            name="explanation"
          /> */}
        {/* {errors.length ? (
            <Box>
              {errors.map((item, idx) => (
                <Alert sx={{ p: 0, px: 1 }} severity="error" key={idx}>
                  {item}
                </Alert>
              ))}
            </Box>
          ) : null} */}

        <ComponentGroup title="Passage Questions">
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <AddQuestionButton data={null} passage={true} />
          </Box>
          {passageState?.questions.length ? (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: 2,
              }}
            >
              {passageState?.questions?.map((item, idx) => (
                <QuestionCard
                  key={idx}
                  question={item}
                  action={<></>}
                  menuActions={
                    <Box>
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={() => {
                          setOpen(true);
                          setSelectedQuestion(item);
                        }}
                      >
                        <EditTwoTone />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDelete(item, idx)}
                        size="small"
                        color="error"
                      >
                        <DeleteTwoTone />
                      </IconButton>
                    </Box>
                  }
                />
              ))}
            </Box>
          ) : (
            <Box sx={{ pb: 4 }}>
              <h1>No Questions in this passage</h1>
              {/* <NoDataComponent
                label="No Questions in this passage"
                size="small"
              /> */}
            </Box>
          )}
        </ComponentGroup>
        <Box>
          <Button
            onClick={handleSubmit2(onSubmit2, onError2)}
            variant="contained"
          >
            Submit
          </Button>
        </Box>
      </Stack>
      <DialogWrapper
        width="lg"
        title={`Question`}
        open={open}
        setOpen={setOpen}
      >
        <QuestionForm
          data={selectedQuestion}
          questionType={selectedQuestion?.questionType}
          closeModal={() => setOpen(false)}
          passage={false}
        />
      </DialogWrapper>
    </Box>
  );
};

export default PassageQuestionForm;
