import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import DifficultyLevelChip from "../../../components/DifficultyLevelChip";
import LanguageChip from "../../../components/LanguageChip";

export default function QuestionCard({
  question,
  action,
  menuActions,
  otherData,
}: any) {
  console.log("question",question);
  return (
    <Card variant="outlined">
      <CardHeader
        sx={{ pb: 0 }}
        subheader={
          <Stack direction="row" gap={2} my={1} alignItems={"center"}>

            <LanguageChip language={question?.language?.name} />
            <DifficultyLevelChip level={question?.difficultyLevel} />
          </Stack>
        }
        action={menuActions ? menuActions : null}
      />
      <CardContent sx={{ pt: 0 }}>
        <Divider sx={{ my: 1 }} />
        <Box
          sx={{ "& *": { margin: 0, padding: 0, marginTop: "1rem" },fontFamily:"figtree_medium" }}
          dangerouslySetInnerHTML={{ __html: question?.question }}
        ></Box>
      </CardContent>
      <CardActions
        disableSpacing
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Typography variant="subtitle1">
          {question?.createdAt &&
            moment(question?.createdAt).format("DD MMM yyyy")}
        </Typography>
        {action}
      </CardActions>
    </Card>
  );
}
