import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { IconButton, IconButtonProps, styled, Tooltip } from "@mui/material";

interface ButtonProps extends IconButtonProps {
  disabledText?: string;
  actionType?: "delete" | "edit" | "view" | "print" | "viewMore";
  editTitle?: string;
  viewTitle?: string;
  viewMoreTitle?: string;
  deleteTitle?: string;
}

const StyledIconButton = styled(IconButton)<{
  actionType: "delete" | "edit" | "view" | "print" | "viewMore";
}>(({ theme, actionType }) => ({
  padding: 0,
}));

function Button({ ...props }: ButtonProps) {
  const handleClick = (e: any) => {
    e.stopPropagation();
    if (!props.onClick) return;
    props.onClick(e);
  };
  return (
    <StyledIconButton
      {...props}
      onClick={handleClick}
      actionType={props.actionType ? props.actionType : "view"}
    >
      {props.children}
    </StyledIconButton>
  );
}

export function ViewButton({ viewTitle, ...props }: ButtonProps) {
  return (
    <Button {...props} actionType="view">
      <Tooltip title={viewTitle}>
        <RemoveRedEyeIcon />
      </Tooltip>
    </Button>
  );
}

export function EditButton({ editTitle, ...props }: ButtonProps) {
  return (
    <Button {...props} actionType="edit">
      <Tooltip title={editTitle}>
        <EditIcon />
      </Tooltip>
    </Button>
  );
}

export function ViewMore({ viewMoreTitle, ...props }: ButtonProps) {
  return (
    <Button {...props} actionType="viewMore">
      <Tooltip title={viewMoreTitle}>
        <MoreVertIcon />
      </Tooltip>
    </Button>
  );
}

export function Delete({ deleteTitle, ...props }: ButtonProps) {
  return (
    <Button {...props} actionType="delete">
      <Tooltip title={deleteTitle}>
        <DeleteForeverIcon />
      </Tooltip>
    </Button>
  );
}
