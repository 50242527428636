import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";
import DifficultyLevelChip from "../../components/DifficultyLevelChip";
import LanguageChip from "../../components/LanguageChip";
import { LabelCard } from "../exams/components/styles";
import { StyledLabelContainer } from "./styles";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "react-query";
import ConfirmModal from "../../components/Confirm/ConfirmModal";
import { DeleteTwoTone, EditTwoTone, MoreVert } from "@mui/icons-material";
import { ViewButton } from "../../components/IconButtons";
import { theme } from "../../styles/theme";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteQuestionBanks } from "../../api/services";

export default function QuestionBankCard({
  data,
  cardActions,
  otherActions,
  isTable,
}: any) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [readMore, setReadMore] = useState(false);
  const open = Boolean(anchorEl);

  const handleToggle = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {isTable ? (
        <>
          <IconButton
            size="small"
            sx={{
              background: "white",
            }}
            onClick={handleToggle}>
            <MoreVert fontSize="small" />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}>
            <MenuItem onClick={() => navigate(data._id)}>
              <ListItemIcon>
                <ViewButton color="primary" />
              </ListItemIcon>
              <ListItemText>View</ListItemText>
            </MenuItem>

            {otherActions}
          </Menu>
        </>
      ) : (
        <>
          <Card>
            <Stack sx={{ height: "100%" }}>
              <CardHeader
                sx={{ pb: 0 }}
                title={data?.title}
                subheader={
                  <Typography variant="subtitle1" fontSize={12}>
                    {data?.createdAt &&
                      moment(data?.createdAt).format("MMM dd, yyyy")}
                  </Typography>
                }
                action={otherActions}
              />
              <CardContent sx={{ pt: 1, flexGrow: 1 }}>
                <LabelContainer
                  data={[
                    {
                      label: "Language",
                      value: <LanguageChip language={data?.language?.name} />,
                    },
                    {
                      label: "Difficulty",
                      value: (
                        <DifficultyLevelChip level={data?.difficultyLevel} />
                      ),
                    },
                    {
                      label: "Type",
                      value: data?.type,
                    },
                  ]}
                />
              </CardContent>
              <CardActions
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}>
                {cardActions}
              </CardActions>
            </Stack>
          </Card>
        </>
      )}
    </>
  );
}

export const LabelContainer = ({
  data,
}: {
  data: { value: any; label: string }[];
}) => {
  return (
    <StyledLabelContainer>
      {data?.map((item, index) => {
        if (!item?.value) return null;
        return <LabelCard key={index} value={item?.value} label={item.label} />;
      })}
    </StyledLabelContainer>
  );
};
