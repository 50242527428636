import { useQuery } from "react-query";
import { getAllLanguages } from "../../../api/services";
import SingleSelect from "../../../components/forms/SingleSelect";

export default function LanguagesField({ control, name }: any) {
  const { data: languageData } = useQuery("languages", getAllLanguages);

  const languages = languageData?.data?.data?.map((item: any) => ({
    value: item?._id,
    label: item?.name,
  }));

  return (
    <SingleSelect
      name={name}
      control={control}
      label="Language"
      options={languages}
    />
  );
}
