import { EditTwoTone } from "@mui/icons-material";
import {
  Box,
  Card,
  IconButton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import DialogWrapper from "../../../components/DialogWrapper/DialogWrapper";
import DifficultyLevelChip from "../../../components/DifficultyLevelChip";
import LanguageChip from "../../../components/LanguageChip";
import { QuestionType } from "../../../types";
import ComponentGroup from "../components/ComponentGroup";
import QuestionForm from "../QuestionForm/QuestionForm";
import { QuestionTypeKey } from "../QuestionForm/service";

// import { format } from "date-fns";
// import { useState } from "react";
// import DialogWrapper from "src/components/DialogWrapper/DialogWrapper";
// import DifficultyLevelChip from "src/components/DifficultyLevelChip";
// import LanguageChip from "src/components/LanguageChip";
// import TagChips from "src/components/TagChips";
// import { QuestionType, QuestionTypeKey } from "src/types";
// import ComponentGroup from "../../components/ComponentGroup";
// import QuestionForm from "../QuestionForm";

interface Props {
  data: any;
  close?: any;
  menuActions?: any;
}
interface SomeType {
  questionType: string;
}

export default function QuestionPopup({
  data,
  close,
  menuActions,
  otherData,
}: any) {
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedData, setSelectedData] = useState<SomeType | null>(null);

  var doc = new DOMParser().parseFromString(data?.videoLink, "text/html");

  const videoUrl =
    doc.documentElement.getElementsByTagName("iframe")[0]?.src ?? "";

  const isPassage = false;
  return (
    <Stack gap={1}>
      {data?.createdAt && (
        <Typography variant="subtitle1">
          {moment(data?.createdAt).format("DD MMM yyyy")}
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          gap: 0.5,
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        {/* <Chip
          sx={{ background: "#B7078A0f", color: "#B7078A" }}
          variant="filled"
          size="small"
          label={QuestionType[data?.questionType] ?? "Passage"}
        /> */}
        <Stack direction="row" gap={1} alignItems="center">
          {menuActions}
          <LanguageChip language={otherData?.language?.name} />
          <DifficultyLevelChip level={data?.difficultyLevel} />
        </Stack>
      </Box>

      {isPassage && (
        <ComponentGroup title="Passage Title">
          <Typography >{data?.title}</Typography>
        </ComponentGroup>
      )}
      <ComponentGroup title={isPassage ? "Passage" : "Question"}>
        <Box
          sx={{ "& *": { margin: 0, padding: 0, fontWeight: 600 },fontFamily:"figtree_medium" }}
          dangerouslySetInnerHTML={{
            __html: isPassage ? data?.passage : data?.question,
          }}
        ></Box>
      </ComponentGroup>
      {renderQuestionType(data)}
      {!isPassage && (
        <>
          <ComponentGroup title="Details">
            <Stack direction="row" gap={1} alignItems="center">
              <Typography>Marks : </Typography>
              <MarksCard variant="positive">+ {data?.marks}</MarksCard>
              <MarksCard variant="negative">- {data?.negativeMarks}</MarksCard>
            </Stack>
          </ComponentGroup>
          <ComponentGroup title="Explanation">
            <Box
              sx={{ "& *": { margin: 0, padding: 0 },fontFamily:"figtree_medium" }}
              dangerouslySetInnerHTML={{ __html: data?.explanation }}
            ></Box>
          </ComponentGroup>
        </>
      )}
      {data?.audioKey && (
        <ComponentGroup title="Audio">
          <audio controls>
            <source
              src={`${process.env.REACT_APP_FILE_URL}/${data?.audioKey}`}
              type="audio/mp3"
            />
          </audio>
        </ComponentGroup>
      )}
      {data?.imageKey && (
        <ComponentGroup title="Image">
          <img
            src={`${process.env.REACT_APP_FILE_URL}/${data?.imageKey}`}
            alt="image"
            height={200}
            width={200}
            style={{ borderRadius: "10px" }}
          />
        </ComponentGroup>
      )}
      {data?.videoLink && (
        <ComponentGroup title="Video">
          <Box sx={{ padding: 0, height: "500px" }}>
            <iframe
              src={videoUrl}
              style={{
                width: "100%",
                height: "100%",
              }}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Box>
        </ComponentGroup>
      )}
      <DialogWrapper
        width="lg"
        // title={`Edit ${QuestionType[selectedData?.questionType]} Question`}
        title={`Edit "Test" Question`}
        open={openEdit}
        setOpen={setOpenEdit}
      >
        <QuestionForm
          data={selectedData}
          questionType={selectedData?.questionType}
          closeModal={() => setOpenEdit(false)}
          passage={false}
        />
      </DialogWrapper>
    </Stack>
  );
}

const renderQuestionType = (question: any) => {
  switch (question?.questionType) {
    case QuestionTypeKey.MCQ_SINGLE:
    case QuestionTypeKey.MCQ_MULTIPLE:
      return <MCQ data={question} />;
    case QuestionTypeKey.BLANKS_SINGLE:
    case QuestionTypeKey.BLANKS_MULTIPLE:
      return <Blanks data={question} />;
    case QuestionTypeKey.TRUE_FALSE:
      return <Blanks data={question} />;
    case QuestionTypeKey.SUBJECTIVE:
      return <Blanks data={question} />;
    case QuestionTypeKey.PASSAGE:
      return <Blanks data={question} />;
    default:
      return <MCQ data={question} />;
  }
};

const MCQ = ({ data }: any) => {
  const correctOptions = data?.options?.filter(
    (item: any) => item?.isCorrect === true
  );

  return (
    <ComponentGroup title="Options">
      <Stack gap={0.5}>
        {data?.options?.map((item: any, index: number) => (
          <Option
            key={index}
            label={`Option ${index + 1}: `}
            option={item?.option}
          />
        ))}
        <Stack
          sx={{
            flexDirection: "row",
            gap: 1,
            p: 1,
            borderRadius: "0.5rem",
            background: "rgba(0, 0, 0, 0.05)",
          }}
        >
          <Typography variant="subtitle2">Correct Answers: </Typography>
          <Typography variant="h6" fontWeight={500}>
            <Box
              sx={{ "& *": { margin: 0, padding: 0 } }}
              dangerouslySetInnerHTML={{
                __html: correctOptions
                  ?.map((item: any) => item?.option)
                  .join(", "),
              }}
            ></Box>
          </Typography>
        </Stack>
      </Stack>
    </ComponentGroup>
  );
};

const Blanks = ({ data }: any) => {
  const correctAnswers =
    typeof data?.correctAnswers === "string"
      ? data.correctAnswers.split(",")
      : data.correctAnswers;

  if (data?.questionType === QuestionTypeKey.SUBJECTIVE) return null;
  return (
    <ComponentGroup title="Answer">
      {data?.questionType !== QuestionType["DescriptiveType"] && (
        <Stack>
          {correctAnswers?.map((item: any, idx: any) => (
            <Option
              key={idx}
              label={
                data?.questionType === QuestionTypeKey.TRUE_FALSE
                  ? "Answer :"
                  : `Blank ${idx + 1}: `
              }
              option={item}
            />
          ))}
        </Stack>
      )}
    </ComponentGroup>
  );
};

const Passage = ({ passage }: any) => {
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedData, setSelectedData] = useState<SomeType | null>(null);

  return (
    <ComponentGroup title="Passage Questions">
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: 1,
        }}
      >
        {passage?.questions?.map((item: any, index: any) => (
          <Card
            variant="outlined"
            sx={{
              boxShadow: "none",
              p: 2,
            }}
            key={index}
          >
            <QuestionPopup
              data={item}
              close={null}
              menuActions={
                <>
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => {
                      setOpenEdit(true);
                      setSelectedData(item);
                    }}
                  >
                    <EditTwoTone />
                  </IconButton>
                </>
              }
            />
          </Card>
        ))}
      </Box>
      <DialogWrapper
        width="lg"
        // title={`Edit ${QuestionType[selectedData?.questionType]} Question`}
        title={`Edit "Test" Question`}
        open={openEdit}
        setOpen={setOpenEdit}
      >
        <QuestionForm
          data={selectedData}
          questionType={selectedData?.questionType}
          closeModal={() => setOpenEdit(false)}
          passage={false}
        />
      </DialogWrapper>
    </ComponentGroup>
  );
};

type OptionProps = {
  label: string;
  option: string;
};

const Option = ({ label, option }: OptionProps) => {
  if (!option) return null;
  return (
    <Stack p={0.5} direction="row" gap={1} alignItems="center" borderRadius={2}>
      <Box
        sx={{
          position: "relative",
          pl: 1.5,
        }}
      >
        <Typography variant="subtitle2">{label}</Typography>
      </Box>
      <Box
        sx={{ "& *": { margin: 0, padding: 0 },fontFamily:"figtree_medium" }}
        dangerouslySetInnerHTML={{ __html: option }}
      ></Box>
    </Stack>
  );
};

const MarksCard = styled("span")<{ variant: "positive" | "negative" }>(
  ({ theme, variant }) => ({
    padding: "0.5rem 1rem",
    alignSelf: "flex-start",
    background:
      variant === "positive"
        ? theme.palette.success.dark
        : theme.palette.error.light,
    color: "#fff",
    fontWeight: 600,
    borderRadius: "0.5rem",
  })
);
