import React, { useEffect } from 'react'
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllLanguages, getStudent } from '../../api/services';
import { Button, Divider, Stack, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const StudentView = () => {
    const navigate=useNavigate()
  const { id } = useParams();
  const { data: StudentData, isLoading } = useQuery(["individual-student",id], getStudent);

  useEffect(()=>{
    console.log(StudentData);
  },[StudentData])

  const handleBack=()=>{
    navigate(-1)
  }

   
    

  return (
    <Stack padding={3}>
        <Button startIcon={<ArrowBackIcon/>}  sx={{width:"10%"}} onClick={handleBack}>Back</Button>
        <Stack mt={3}>
            <Typography variant='h4'>Student Information</Typography>
            <Stack direction={'row'} mt={3} width={"100%"} justifyContent={'space-between'} paddingRight={"10%"}>
                <Stack mt={1} width={'40%'} justifyContent={'start'}>
                    <Typography sx={{fontWeight:"light",fontStyle:"figtree_medium",opacity:"80%"}}>Name</Typography>
                    <Typography sx={{fontWeight:"light",fontStyle:"figtree_medium"}}>{StudentData?.data?.name}</Typography>
                </Stack>
                <Stack mt={1} width={'40%'} justifyContent={'start'}>
                    <Typography sx={{fontWeight:"light",fontStyle:"figtree_medium",opacity:"80%"}}>Mobile Number</Typography>
                    <Typography sx={{fontWeight:"light",fontStyle:"figtree_medium"}}>{StudentData?.data?.mobile}</Typography>
                </Stack>
                <Stack mt={1} width={'40%'} justifyContent={'start'}>
                    <Typography sx={{fontWeight:"light",fontStyle:"figtree_medium",opacity:"80%"}}>Email</Typography>
                    <Typography sx={{fontWeight:"light",fontStyle:"figtree_medium"}}>{StudentData?.data?.email}</Typography>
                </Stack>
                
            </Stack>
            <Stack direction={'row'} mt={3} width={"100%"} justifyContent={'space-between'} paddingRight={"10%"}>
                <Stack mt={1} width={'40%'} justifyContent={'start'}>
                    <Typography sx={{fontWeight:"light",fontStyle:"figtree_medium",opacity:"80%"}}>Father's Name</Typography>
                    <Typography sx={{fontWeight:"light",fontStyle:"figtree_medium"}}>{StudentData?.data?.fatherName}</Typography>
                </Stack>
                <Stack mt={1} width={'40%'} justifyContent={'start'}>
                    <Typography sx={{fontWeight:"light",fontStyle:"figtree_medium",opacity:"80%"}}>Date of Birth</Typography>
                    <Typography sx={{fontWeight:"light",fontStyle:"figtree_medium"}}>{StudentData?.data?.dateOfBirth}</Typography>
                </Stack>
                <Stack mt={1} width={'40%'} justifyContent={'start'}>
                    <Typography sx={{fontWeight:"light",fontStyle:"figtree_medium",opacity:"80%"}}>Aadhar Number</Typography>
                    <Typography sx={{fontWeight:"light",fontStyle:"figtree_medium"}}>{StudentData?.data?.aadharNumber}</Typography>
                </Stack>
                
            </Stack>
            <Stack direction={'row'} mt={3} width={"85%"}  paddingRight={"10%"}>
                <Stack mt={1} width={'40%'} justifyContent={'start'}>
                    <Typography sx={{fontWeight:"light",fontStyle:"figtree_medium",opacity:"80%"}}>District</Typography>
                    <Typography sx={{fontWeight:"light",fontStyle:"figtree_medium"}}>{StudentData?.data?.district?.name}</Typography>
                </Stack>
                <Stack mt={1} width={'40%'} justifyContent={'start'}>
                    <Typography sx={{fontWeight:"light",fontStyle:"figtree_medium",opacity:"80%"}}>Languages</Typography>
                    {StudentData?.data?.languages.map((language:any) => (
                            <Typography key={language._id} sx={{ fontWeight: "light", fontStyle: "figtree_medium" }}>{language?.name}</Typography>
                        ))}
                </Stack>
                
                
            </Stack>
            
        </Stack>
        <Divider sx={{ mt: 2, mb: 1 }} />
        <Stack mt={3}>
            <Typography variant='h4'>Trade Details</Typography>
            <Stack direction={'row'} mt={3} width={"100%"} justifyContent={'space-between'} paddingRight={"10%"}>
                <Stack mt={1} width={'40%'} justifyContent={'start'}>
                    <Typography sx={{fontWeight:"light",fontStyle:"figtree_medium",opacity:"80%"}}>Trade</Typography>
                    <Typography sx={{fontWeight:"light",fontStyle:"figtree_medium"}}>{StudentData?.data?.trade?.name}</Typography>
                </Stack>
                <Stack mt={1} width={'40%'} justifyContent={'start'}>
                    <Typography sx={{fontWeight:"light",fontStyle:"figtree_medium",opacity:"80%"}}>Practical Center Type</Typography>
                    <Typography sx={{fontWeight:"light",fontStyle:"figtree_medium"}}>{StudentData?.data?.centreType?.name}</Typography>
                </Stack>
                <Stack mt={1} width={'40%'} justifyContent={'start'}>
                    <Typography sx={{fontWeight:"light",fontStyle:"figtree_medium",opacity:"80%"}}>Practical Center</Typography>
                    <Typography sx={{fontWeight:"light",fontStyle:"figtree_medium"}}>{StudentData?.data?.centre?.name}</Typography>
                </Stack>
                
            </Stack>
        </Stack>
        <Divider sx={{ mt: 2, mb: 1 }} />
        <Stack mt={3}>
            <Typography variant='h4'>Documents</Typography>
            <Stack direction={'row'} mt={3} width={"100%"} justifyContent={'space-between'} paddingRight={"10%"}>
                <Stack mt={1} width={'40%'} justifyContent={'start'} gap={1}>
                    <Typography sx={{fontWeight:"light",fontStyle:"figtree_medium",opacity:"80%"}}>Image</Typography>
                    <img src={StudentData?.data?.imageUrl} alt='no-image' width={'60%'} />
                </Stack>
                <Stack mt={1} width={'40%'} justifyContent={'start'} gap={1}>
                    <Typography sx={{fontWeight:"light",fontStyle:"figtree_medium",opacity:"80%"}}>Aadhar </Typography>
                    <img src={StudentData?.data?.aadharUrl} alt='no-image' width={'60%'} />

                </Stack>
                <Stack mt={1} width={'40%'} justifyContent={'start'} gap={1}>
                    <Typography sx={{fontWeight:"light",fontStyle:"figtree_medium",opacity:"80%"}}>10th Marks Sheet</Typography>
                    <img src={StudentData?.data?.sscUrl} alt='no-image' width={'60%'} />
                </Stack>
            </Stack>
            <Stack direction={'row'} mt={3} width={"100%"} justifyContent={'space-between'} paddingRight={"10%"}>
                <Stack mt={1} width={'40%'} justifyContent={'start'} gap={1}>
                    <Typography sx={{fontWeight:"light",fontStyle:"figtree_medium",opacity:"80%"}}>Signature</Typography>
                    <img src={StudentData?.data?.signatureUrl} alt='no-image' width={'60%'} />
                </Stack>
            </Stack>
        </Stack>
   
    </Stack>
  )
}

export default StudentView
